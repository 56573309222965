import * as d3 from "d3";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
    abbreviateNumber, ATTACK_HEAD, calcColor, calcStrokeWidth, DEFAULT_COLOR_PATERN,
    DEFAULT_SEMANTIC, getArrowImg, getBackgroundGradient, getBorderColor, MAX_FILES, saveUserSettings, shadeColor,
    sidebarCollapseClick, signInTooltip,
    STROKE_COLOR_ATTACK,
    STROKE_COLOR_SUPPORT, SUPPORT_HEAD
} from "../functions";
import pinTransparentIcon from "../images/push-pin-transparent.png"
import pinBlackIcon from "../images/push-pin-black.png"
import $ from 'jquery'
import Bootstrap from 'bootstrap';
import trunk8 from "trunk8"
import Plyr from 'plyr';
import "plyr/dist/plyr.css"
import { baseUrl } from "../functions";
import {useDispatch} from "react-redux";
import {changeUserDebateSettings, googleAuthReset} from "../actions/googleActions";

/* CONSTANTS */
const ATTACK_END_URL = "attack";
const SUPPORT_END_URL = "support";
const ARROW_HEADS_PATH = "../images/arrow_heads/arrow_";
const FADE_OUT = 500;
const FADE_IN = 500;


const getLikeContent = (d) => {
    let iconPos;
    let iconNeg;
    if (d.typeOfVote === "positive") {
        iconPos = "fas fa-thumbs-up"
        iconNeg = "far fa-thumbs-down"
    } else if (d.typeOfVote === "negative") {
        iconPos = "far fa-thumbs-up"
        iconNeg = "fas fa-thumbs-down"
    } else {
        iconPos = "far fa-thumbs-up"
        iconNeg = "far fa-thumbs-down"
    }

    let res = "<button id=" + +d.id + " class=\"likeNode\" style=\"font-size:24px\"><i class='" + iconPos + "'></i></button>"
        + "<span id='posVotes" + d.id + "'>" + abbreviateNumber(d.positiveVotes) + "</span>" + " <button id=" + d.id + " class=\"dislikeNode\" style=\"font-size:24px\"><i class='" + iconNeg + "'></i></button>" +
        "<span id='negVotes" + d.id + "'>" + abbreviateNumber(d.negativeVotes) + "</span>";

    if(!d.initial) {
        res = res + " <button class='relationBtn' style='position: absolute; right: 9px; bottom: 5px;' id =\"attack\" style=\"font-size:24px\"><i class=\"fas fa-plus\"></i></button>";
    }

    return  res
}


const changeMarkerEnds = (svg, select, type) => { //type: "Attack" or "Support"
    // remove current heads
    $('#end' + type + ', #fade' + type + ', #highlight' + type ).parent().remove()

    switch ($(select).children("option:selected").val()) {
        case "og":
            //normal heads
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "0 -5 10 10")
                .attr("refX", 7)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M0,-5L10,0L0,5")
                .attr("fill", $('#color' + type + 's').val());

            //faded heads
            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "0 -5 10 10")
                .attr("refX", 7)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M0,-5L10,0L0,5")
                .attr("fill", $('#color' + type + 's').val());

            //highlight heads
            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "0 -5 10 10")
                .attr("refX", 7)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M0,-5L10,0L0,5")
                .attr("fill", "black")
            break;
        //this is the value from the selected color in the color input, either "#colorAttacks" or "#colorSupports"
        case "roundArrow":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-20 -278 975 560")
                .attr("refX", 200)
                .attr("refY", 0)
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 410.413 -56.588 L 46.347 -265.084 c -52.938 -30.318 -95.852 -5.44 -95.852 55.563 v 415.652 c 0 61.004 42.914 85.882 95.852 55.563 l 364.066 -208.49 C 463.351 22.887 463.351 -26.269 410.413 -56.588 z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-20 -278 975 560")
                .attr("refX", 200)
                .attr("refY", 0)
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 410.413 -56.588 L 46.347 -265.084 c -52.938 -30.318 -95.852 -5.44 -95.852 55.563 v 415.652 c 0 61.004 42.914 85.882 95.852 55.563 l 364.066 -208.49 C 463.351 22.887 463.351 -26.269 410.413 -56.588 z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-20 -278 975 560")
                .attr("refX", 200)
                .attr("refY", 0)
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 410.413 -56.588 L 46.347 -265.084 c -52.938 -30.318 -95.852 -5.44 -95.852 55.563 v 415.652 c 0 61.004 42.914 85.882 95.852 55.563 l 364.066 -208.49 C 463.351 22.887 463.351 -26.269 410.413 -56.588 z")
                .attr("fill", "black");
            break;
        case "unevenArrow":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-80 -213 760 440")
                .attr("refX", 230)
                .attr("refY", 0)
                .attr("x", "0")
                .attr("y", "0")
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 438.731 -14.537 l -416 -192 c -6.624 -3.008 -14.528 -1.216 -19.136 4.48 c -4.64 5.696 -4.8 13.792 -0.384 19.648 l 136.8 182.4 l -136.8 182.4 c -4.416 5.856 -4.256 13.984 0.352 19.648 c 3.104 3.872 7.744 5.952 12.448 5.952 c 2.272 0 4.544 -0.48 6.688 -1.472 l 416 -192 c 5.696 -2.624 9.312 -8.288 9.312 -14.528 S 444.395 -11.913 438.731 -14.537 z")
                .attr("fill", $('#color' + type + 's').val())

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-80 -213 760 440")
                .attr("refX", 230)
                .attr("refY", 0)
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 438.731 -14.537 l -416 -192 c -6.624 -3.008 -14.528 -1.216 -19.136 4.48 c -4.64 5.696 -4.8 13.792 -0.384 19.648 l 136.8 182.4 l -136.8 182.4 c -4.416 5.856 -4.256 13.984 0.352 19.648 c 3.104 3.872 7.744 5.952 12.448 5.952 c 2.272 0 4.544 -0.48 6.688 -1.472 l 416 -192 c 5.696 -2.624 9.312 -8.288 9.312 -14.528 S 444.395 -11.913 438.731 -14.537 z")
                .attr("fill", $('#color' + type + 's').val())


            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-80 -213 760 440")
                .attr("refX", 230)
                .attr("refY", 0)
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 438.731 -14.537 l -416 -192 c -6.624 -3.008 -14.528 -1.216 -19.136 4.48 c -4.64 5.696 -4.8 13.792 -0.384 19.648 l 136.8 182.4 l -136.8 182.4 c -4.416 5.856 -4.256 13.984 0.352 19.648 c 3.104 3.872 7.744 5.952 12.448 5.952 c 2.272 0 4.544 -0.48 6.688 -1.472 l 416 -192 c 5.696 -2.624 9.312 -8.288 9.312 -14.528 S 444.395 -11.913 438.731 -14.537 z")
                .attr("fill", "black")
            break;
        case "hexagon":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -169 605 335")
                .attr("refX", 230)
                .attr("refY", 0)
                .attr("x", "0")
                .attr("y", "0")
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 288 166.28 C 288 166.28 96 166.28 96 166.28 C 96 166.28 0 0 0 0 C 0 0 96 -166.28 96 -166.28 C 96 -166.28 288 -166.28 288 -166.28 C 288 -166.28 384 0 384 0 C 384 0 288 166.28 288 166.28 Z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -169 605 335")
                .attr("refX", 230)
                .attr("refY", 0)
                .attr("x", "0")
                .attr("y", "0")
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 288 166.28 C 288 166.28 96 166.28 96 166.28 C 96 166.28 0 0 0 0 C 0 0 96 -166.28 96 -166.28 C 96 -166.28 288 -166.28 288 -166.28 C 288 -166.28 384 0 384 0 C 384 0 288 166.28 288 166.28 Z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -169 605 335")
                .attr("refX", 230)
                .attr("refY", 0)
                .attr("x", "0")
                .attr("y", "0")
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 288 166.28 C 288 166.28 96 166.28 96 166.28 C 96 166.28 0 0 0 0 C 0 0 96 -166.28 96 -166.28 C 96 -166.28 288 -166.28 288 -166.28 C 288 -166.28 384 0 384 0 C 384 0 288 166.28 288 166.28 Z")
                .attr("fill", "black");
            break;
        case "square":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-5 -5 10 10")
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 3.5)
                .attr("markerHeight", 3.5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 0,0 m -5,-5 L 5,-5 L 5,5 L -5,5 Z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-5 -5 10 10")
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 3.5)
                .attr("markerHeight", 3.5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 0,0 m -5,-5 L 5,-5 L 5,5 L -5,5 Z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-5 -5 10 10")
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 3.5)
                .attr("markerHeight", 3.5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 0,0 m -5,-5 L 5,-5 L 5,5 L -5,5 Z")
                .attr("fill", "black");
            break;
        case "abs_square":
            var lel = svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -251 851 500")
                .attr("refX", 100)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")

            lel.append("svg:path")
                .attr("d", "M 77.38 -170.81 C 77.38 -170.81 412.61 -170.81 412.61 -170.81 C 412.61 -170.81 412.62 160.81 412.62 160.81 C 412.62 160.81 77.38 160.81 77.38 160.81 C 77.38 160.81 0 240 0 240 C 0 240 490 240 490 240 C 490 240 490 -250 490 -250 C 490 -250 0 -250 0 -250 C 0 -250 77.38 -170.81 77.38 -170.81 Z")
                .attr("fill", $('#color' + type + 's').val())

            lel.append("svg:path")
                .attr("d", "M 152.54 87.46 C 152.54 87.46 152.54 -97.46 152.54 -97.46 C 152.54 -97.46 337.46 -97.46 337.46 -97.46 C 337.46 -97.46 337.46 87.46 337.46 87.46 C 337.46 87.46 152.54 87.46 152.54 87.46 Z")
                .attr("fill", $('#color' + type + 's').val());

            var fade = svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -251 851 500")
                .attr("refX", 100)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")

            fade.append("svg:path")
                .attr("d", "M 77.38 -170.81 C 77.38 -170.81 412.61 -170.81 412.61 -170.81 C 412.61 -170.81 412.62 160.81 412.62 160.81 C 412.62 160.81 77.38 160.81 77.38 160.81 C 77.38 160.81 0 240 0 240 C 0 240 490 240 490 240 C 490 240 490 -250 490 -250 C 490 -250 0 -250 0 -250 C 0 -250 77.38 -170.81 77.38 -170.81 Z")
                .attr("fill", $('#color' + type + 's').val())

            fade.append("svg:path")
                .attr("d", "M 152.54 87.46 C 152.54 87.46 152.54 -97.46 152.54 -97.46 C 152.54 -97.46 337.46 -97.46 337.46 -97.46 C 337.46 -97.46 337.46 87.46 337.46 87.46 C 337.46 87.46 152.54 87.46 152.54 87.46 Z")
                .attr("fill", $('#color' + type + 's').val());

            var highlight = svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -251 851 500")
                .attr("refX", 100)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")

            highlight.append("svg:path")
                .attr("d", "M 77.38 -170.81 C 77.38 -170.81 412.61 -170.81 412.61 -170.81 C 412.61 -170.81 412.62 160.81 412.62 160.81 C 412.62 160.81 77.38 160.81 77.38 160.81 C 77.38 160.81 0 240 0 240 C 0 240 490 240 490 240 C 490 240 490 -250 490 -250 C 490 -250 0 -250 0 -250 C 0 -250 77.38 -170.81 77.38 -170.81 Z")
                .attr("fill", "black")

            highlight.append("svg:path")
                .attr("d", "M 152.54 87.46 C 152.54 87.46 152.54 -97.46 152.54 -97.46 C 152.54 -97.46 337.46 -97.46 337.46 -97.46 C 337.46 -97.46 337.46 87.46 337.46 87.46 C 337.46 87.46 152.54 87.46 152.54 87.46 Z")
                .attr("fill", "black");
            break;
        case "square_hole":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -246 851 492")
                .attr("refX", 110)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 490 -245 H 0 v 490 h 490 V -245 z M 245 130.156 c -71.883 0 -130.156 -58.273 -130.156 -130.156 c 0 -71.884 58.273 -130.156 130.156 -130.156 S 375.156 -71.884 375.156 0 C 375.156 71.883 316.883 130.156 245 130.156 z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -246 851 492")
                .attr("refX", 110)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 490 -245 H 0 v 490 h 490 V -245 z M 245 130.156 c -71.883 0 -130.156 -58.273 -130.156 -130.156 c 0 -71.884 58.273 -130.156 130.156 -130.156 S 375.156 -71.884 375.156 0 C 375.156 71.883 316.883 130.156 245 130.156 z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -246 851 492")
                .attr("refX", 110)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 490 -245 H 0 v 490 h 490 V -245 z M 245 130.156 c -71.883 0 -130.156 -58.273 -130.156 -130.156 c 0 -71.884 58.273 -130.156 130.156 -130.156 S 375.156 -71.884 375.156 0 C 375.156 71.883 316.883 130.156 245 130.156 z")
                .attr("fill", "black");
            break;
        case "circle":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", '-6 -6 12 12')
                .attr("refX", 1)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", 'M 0, 0  m -5, 0  a 5,5 0 1,0 10,0  a 5,5 0 1,0 -10,0')
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", '-6 -6 12 12')
                .attr("refX", 1)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", 'M 0, 0  m -5, 0  a 5,5 0 1,0 10,0  a 5,5 0 1,0 -10,0')
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", '-6 -6 12 12')
                .attr("refX", 1)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", 'M 0, 0  m -5, 0  a 5,5 0 1,0 10,0  a 5,5 0 1,0 -10,0')
                .attr("fill", "black");
            break;
        case "slim_arrow":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", '-12 -13 43 25')
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 8)
                .attr("markerHeight", 8)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", 'M 6.291 -1.724 L -3.295 -11.31 c -0.878 -0.878 -2.317 -0.878 -3.195 0 l -0.8 0.8 c -0.878 0.877 -0.878 2.316 0 3.194 L 0.024 0 l -7.315 7.315 c -0.878 0.878 -0.878 2.317 0 3.194 l 0.8 0.8 c 0.878 0.879 2.317 0.879 3.195 0 l 9.586 -9.587 c 0.472 -0.471 0.682 -1.103 0.647 -1.723 C 6.973 -0.62 6.763 -1.252 6.291 -1.724 z')
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", '-12 -13 43 25')
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 8)
                .attr("markerHeight", 8)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", 'M 6.291 -1.724 L -3.295 -11.31 c -0.878 -0.878 -2.317 -0.878 -3.195 0 l -0.8 0.8 c -0.878 0.877 -0.878 2.316 0 3.194 L 0.024 0 l -7.315 7.315 c -0.878 0.878 -0.878 2.317 0 3.194 l 0.8 0.8 c 0.878 0.879 2.317 0.879 3.195 0 l 9.586 -9.587 c 0.472 -0.471 0.682 -1.103 0.647 -1.723 C 6.973 -0.62 6.763 -1.252 6.291 -1.724 z')
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", '-12 -13 43 25')
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 8)
                .attr("markerHeight", 8)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", 'M 6.291 -1.724 L -3.295 -11.31 c -0.878 -0.878 -2.317 -0.878 -3.195 0 l -0.8 0.8 c -0.878 0.877 -0.878 2.316 0 3.194 L 0.024 0 l -7.315 7.315 c -0.878 0.878 -0.878 2.317 0 3.194 l 0.8 0.8 c 0.878 0.879 2.317 0.879 3.195 0 l 9.586 -9.587 c 0.472 -0.471 0.682 -1.103 0.647 -1.723 C 6.973 -0.62 6.763 -1.252 6.291 -1.724 z')
                .attr("fill", "black");
            break;
        default: break;
    }
}

export function runForceGraph(
    container,
    graphId,
    currentUser,
    debateType,
    dispatch
) {
    let graphData;
    let nodesData = []
    let linksData = []
    let nodes = []
    let initialNodes = [];
    let links = []
    let linkedByIndex = {}
    let attack_color = STROKE_COLOR_ATTACK
    let support_color = STROKE_COLOR_SUPPORT

    const ALPHA_TARGET = 0.009;
    const ALPHA_MIN = 0.01;

    d3.select(container).select('svg').remove()

    const svg = d3
        .select(container)
        .append("svg")
        .attr("id", "svgId")
        .attr("height",$('.wrapper').height())
        .attr("width", $('.wrapper').width());


    let width = +svg.attr("width"),
        height = +svg.attr("height");

    let simulation = debateType !== "free" ? d3.forceSimulation(nodes)
        .force("link", d3.forceLink(links).id(d => "link" + d.lid).distance(600))
        .force("charge", d3.forceManyBody().strength(d => {
            if(d.initial)
                return -2000
            else
                return -2000

        }).distanceMax(d => {
            if(d.initial)
                return 6000
            else
                return 6000
        }))
        .force("radial", d3.forceRadial(1400, width/2, height/2).strength(0.1))
        .alphaTarget(ALPHA_TARGET).alphaMin(ALPHA_MIN)
        .on("tick", ticked) :
        d3.forceSimulation(nodes)
        .force("link", d3.forceLink(links).id(d => "link" + d.lid).distance(500))
        .force("charge", d3.forceManyBody().strength(-2000).distanceMax(5000))
        .force("center", d3.forceCenter(width/2,height/2).strength(0.05))
        .alphaTarget(ALPHA_TARGET).alphaMin(ALPHA_MIN)
        .on("tick", ticked)

    const g = svg.append("g")
        .attr("id", "rootG")
        .attr("class", "everything")

    const object0 = [];
    const object1 = [];

    let linkTemplateAttack = {
        "source": {},
        "target": {},
        "type": "ATTACKS",
        "stroke_width": 5.5
    };
    let linkTemplateSupport = {
        "source": {},
        "target": {},
        "type": "SUPPORTS",
        "stroke_width": 5.5
    };

    object0.push(linkTemplateAttack);
    object1.push(linkTemplateSupport);

    let previewLink = g.append("g")
        .attr("class", "previewLink")
        .selectAll("path")
        .data(object0);

    let previewLinkSupport = g.append("g")
        .attr("class", "previewLinkSupport")
        .selectAll("path")
        .data(object1);

    previewLink = drawPreviewLink(previewLink);
    previewLinkSupport = drawPreviewLink(previewLinkSupport);

    let link = g
        .append("g")
        .attr("class", "links")
        .selectAll("path")

    let fatLink = g
        .append("g")
        .attr("class", "fatlinks")
        .selectAll("path")

    let node = g
        .append("g")
        .attr("class", "nodes")
        .selectAll("foreignObject")

    const zoom = d3
        .zoom()
        .scaleExtent([1 / 12, 4])
        .on('zoom', function (event) {
            zoom_actions(event);
        })

    let k = 1;
    function zoom_actions(event) {
        k = event.transform.k;
        g.attr("transform", event.transform)
    }

    svg.call(zoom)

    function updateNodes() {
        node = node.data(nodes.filter(function(n) {
            return !n.filtered
        }), function(d) { return d.id;});
        node.exit().remove()
        let newNode = drawNodes(node)
        node = node.merge(newNode)

        simulation.nodes(nodes)
    }

    function updateLinks() {
        const filteredLinks = links.filter(function(l) {
            return !l.filtered
        })

        link = link.data(filteredLinks, function(d) { return d.lid})
        link.exit().remove()
        let newLink = drawLinks(link)
        link = link.merge(newLink)

        fatLink = fatLink.data(filteredLinks, function(d) { return d.lid})
        fatLink.exit().remove()
        let newFatLink = drawFatLinks(fatLink)
        fatLink = fatLink.merge(newFatLink)

        simulation.force("link").links(filteredLinks)
    }

    function update() {
        updateNodes()
        updateLinks()

        $(".trunkate").trunk8(trunk8_settings);

        simulation.restart()
    }

    function drawGraph() {
        updateSettings(svg)
        update()

        enableDrag()

        updateCollision()
    }

    let isFirst = true;

    function getLinks()  {
        $.ajax({
            'async': true,
            'global': false,
            'url': baseUrl + "/links/all/" + graphId,
            'dataType': "json",
            'success': function (data) {
                linksData = data;
                //filter self attacks and self supports
                linksData = linksData.filter((link) => {
                    return link.source != link.target;
                })
                links = linksData.map((d) => Object.assign({}, {
                    ...d,
                    filtered: false,
                    source: getNode(d.source),
                    target: getNode(d.target)
                }));
                links.forEach(function (d) {
                    linkedByIndex["" + d.source.id + "," + d.target.id] = 1;
                    linkedByIndex["" + d.source.id + d.linkType + d.target.id] = 1;
                });
                if(currentUser != undefined)
                    votedByUser();
                else {
                    drawGraph()
                    if(isFirst && debateType !== "free") {
                        zoomFitInitial(0, 0)
                        isFirst = false;
                    }
                }
            }
        });
    }

    function getGraph() {
        let semantic;
        if(currentUser != undefined && currentUser.semantic != undefined &&
            currentUser.semantic != "default")
            semantic = currentUser.semantic
        else
            semantic = DEFAULT_SEMANTIC

        let url = baseUrl + "/graph/" + graphId + "/" + semantic;

        $.ajax({
            'async': true,
            'global': false,
            'url': url,
            'dataType': "json",
            'success': function (data) {
                graphData = data;
                nodesData = data.nodes
                nodes = nodesData.map((d) => Object.assign({}, {
                    ...d,
                    typeOfVote: "",
                    filtered: false,
                    fixed: false,
                    width: 250,
                    height: 170
                }));

                if(data.initialArguments.length > 0) {
                    initialNodes = data.initialArguments;

                    setAndFixPosition()
                }

                getLinks();
            }
        });
    }

    getGraph()

    function reloadGraph() {
        let semantic;
        if(currentUser != undefined && currentUser.semantic != undefined &&
            currentUser.semantic != "default")
            semantic = currentUser.semantic
        else
            semantic = DEFAULT_SEMANTIC

        let url = baseUrl + "/graph/" + graphId + "/" + semantic;

        let json_nodes;
        let json_links;
        $.ajax({
            'async': true,
            'global': false,
            'url': url,
            'dataType': "json",
            'success': function (data) {
                json_nodes = data.nodes;
                //check if any new arguments have been pushed
                let pushed = false;
                json_nodes.forEach(function (d) {
                    let exists = false;
                    nodes.forEach(function (f) {
                        if(f.id === d.id){
                            exists = true;
                            if(f.positiveVotes != d.positiveVotes) {
                                f.positiveVOtes = d.positiveVotes;
                                $('#posVotes' + d.id).fadeOut(400, function() {
                                    $(this).html(d.positiveVotes).fadeIn(400);
                                });
                            }
                            if(f.negativeVotes != d.negativeVotes) {
                                f.negativeVotes = d.negativeVotes;
                                $('#negVotes' + d.id).fadeOut(400, function() {
                                    $(this).html(d.negativeVotes).fadeIn(400);
                                });
                            }
                            if(f.weight != d.weight) {
                                f.weight = d.weight;
                                //update background-color
                                $('#'+d.id+'.foreignObject').find('#contentDiv').css("background-color", calcColor(colorPattern, d))
                            }
                        }
                    })
                    if(!exists) {
                        console.log("pushed node")
                        pushed = true;
                        d.typeOfVote = "";
                        d.fixed = false;
                        d.filtered = false;
                        d.width = 250;
                        d.height = 170
                        nodes.push(d);
                    }
                })

                $.ajax({
                    'async': true,
                    'global': false,
                    'url': baseUrl + "/links/all/" + graphId,
                    'dataType': "json",
                    'success': function (data) {
                        json_links = data;
                        //filter self attacks and self supports
                        json_links = json_links.filter((link) => {
                            return link.source != link.target;
                        })

                        json_links.forEach(function (d) {
                            let exists = false
                            links.forEach(function (f) {
                                if (f.lid == d.lid) {
                                    exists = true
                                    if (f.score != d.score) {
                                        f.score = d.score;
                                        $('#link' + d.lid).css("stroke-width", calcStrokeWidth(d.score))
                                    }
                                    if (f.posVotes != d.posVotes)
                                        f["posVotes"] = d.posVotes
                                    if (f.negVotes != d.negVotes)
                                        f["negVotes"] = d.negVotes
                                }
                            })
                            if (!exists) {
                                console.log("pushed link")
                                pushed = true;
                                d.source = getNode(d.source)
                                d.target = getNode(d.target)
                                d.filtered = false;
                                links.push(d);
                            }
                        })

                        //if(currentUser != undefined) votedByUser()

                        if(pushed) {
                            drawGraph()}
                            //simulation.alpha(1)
                    }
                });
            }
        });
    }

    let reloadInterval = setInterval( function() {
        console.log("reloading graph")
        reloadGraph()
    }, 5000)

    let colorPattern = DEFAULT_COLOR_PATERN;

    const updateSettings = (svg) => {
        if(currentUser != undefined && currentUser.color_pattern) {
            let color = currentUser.color_pattern;
            if(color === "default") {
                colorPattern = DEFAULT_COLOR_PATERN;
            }
            else {
                colorPattern = color;
            }
        }

        $('[name=colorPatterns]').val(colorPattern)
        $('#colorPattern-picker').css(getBackgroundGradient(colorPattern))
        $('#slider-background').css(getBackgroundGradient(colorPattern))

        $('#pattern-0').css(getBackgroundGradient("0"))
        $('#pattern-1').css(getBackgroundGradient("1"))
        $('#pattern-2').css(getBackgroundGradient("2"))
        $('#pattern-3').css(getBackgroundGradient("3"))
        $('#pattern-4').css(getBackgroundGradient("4"))
        $('#pattern-5').css(getBackgroundGradient("5"))
        $('#pattern-6').css(getBackgroundGradient("6"))
        $('#pattern-7').css(getBackgroundGradient("7"))
        $('#pattern-8').css(getBackgroundGradient("8"))

        nodes.forEach(function(d) {
            $('#'+d.id+'.foreignObject').find('#contentDiv')
                .css("background-color", calcColor(colorPattern, d))
                .css("border", getBorderColor(d, colorPattern))
        })

        if(currentUser != undefined && currentUser.attack_color) {
            let color = currentUser.attack_color;
            if(color == "default") {
                attack_color = STROKE_COLOR_ATTACK;
            }
            else {
                attack_color = color;
            }
        }
        else {
            attack_color = STROKE_COLOR_ATTACK;
        }

        $('#colorAttacks').attr("value", attack_color);
        $('#color-picker-attacks').css("background-color", attack_color);
        $('.path.links.attack').attr("stroke", attack_color)
        $('#endAttack').find("path").attr("fill", attack_color);
        $('#previewAttack').attr("stroke", attack_color)

        if(currentUser != undefined && currentUser.support_color) {
            let color = currentUser.support_color;
            if(color == "default") {
                support_color = STROKE_COLOR_SUPPORT;
            }
            else {
                support_color = color;
            }
        }
        else {
            support_color = STROKE_COLOR_SUPPORT;
        }

        $('#colorSupports').attr("value", support_color);
        $('#color-picker-supports').css("background-color", support_color);
        $('.path.links.support').attr("stroke", support_color)
        $('#endSupport').find("path").attr("fill", support_color);
        $('#previewSupport').attr("stroke", support_color)

        let attack_head;
        if(currentUser != undefined && currentUser.attack_head) {
            let head = currentUser.attack_head;
            if(head == "default") {
                attack_head = ATTACK_HEAD;
            }
            else {
                attack_head = head;
            }
        }
        else {
            attack_head = ATTACK_HEAD;
        }

        $('[name=Attack]').val(attack_head);
        $('#styles_attacks_button').find('img').attr("src", getArrowImg(attack_head))
        $('.style_div.attack.selected').removeClass('selected')
        $('[name=' + attack_head + '].style_div.attack').addClass('selected')
        changeMarkerEnds(svg, $('[name=Attack]'), $('[name=Attack]').attr("name"))

        let support_head;
        if(currentUser != undefined && currentUser.support_head) {
            let head = currentUser.support_head;
            if(head == "default") {
                support_head = SUPPORT_HEAD;
            }
            else {
                support_head = head;
            }
        }
        else {
            support_head = SUPPORT_HEAD;
        }

        $('[name=Support]').val(support_head);
        $('.style_div.support.selected').removeClass('selected')
        $('[name=' + support_head + '].style_div.support').addClass('selected')
        $('#styles_supports_button').find('img').attr("src", getArrowImg(support_head))
        changeMarkerEnds(svg, $('[name=Support]'), $('[name=Support]').attr("name"))

        let semantic;
        if(currentUser != undefined && currentUser.semantic) {
            let s = currentUser.semantic;
            if(s == "default") {
                semantic = DEFAULT_SEMANTIC;
            }
            else {
                semantic = s;
            }
        }
        else {
            semantic = DEFAULT_SEMANTIC;
        }

        $('[name=semantic]').val(semantic)

    }
    function votedByUser() {
        if(currentUser.token != undefined){
            console.log("getting user votes")
            $.ajax({
                'async': false,
                'global': false,
                "headers": {
                    "authorization": "Bearer "+ currentUser.token},
                'url': baseUrl + "/getuservotes/"+ currentUser.token +"/g/"+ graphId,
                'dataType': "json",
                'success': function (data) {
                    let json = data;
                    nodes.forEach(function (d) {
                        json.votedNodes.forEach(function (f) {
                            if(d.id == f.nid){
                                d.typeOfVote = f.typeOfVote;
                            }
                        });
                    });
                    console.log("json")
                    console.log(json)

                    links.forEach(function (d) {
                        d.typeOfVote = "";
                    });

                    links.forEach(function (d) {
                        json.posEdges.forEach(function (f) {
                            if(d.lid == f)
                                d.typeOfVote = "positive";
                        });
                    });

                    links.forEach(function (d) {
                        json.negEdges.forEach(function (f) {
                            if(d.lid == f)
                                d.typeOfVote = "negative";
                        });
                    });
                    drawGraph()
                    if(isFirst && debateType !== "free") {
                        zoomFitInitial(0.9, 0)
                        isFirst = false;
                    }
                }
            });
        }
    }

    function getNode(nodeId) {
        let res = undefined;
        nodes.map( (node) => {
            if(node.id == nodeId)
                res = node;
        })
        return res;
    }
    function getLink(linkId) {
        let res = undefined;
        links.map( (link) => {
            if(link.lid == linkId)
                res = link;
        })

        return res;
    }

    function isConnected(a, b) {
        return  linkedByIndex["" + a + "," + b] || a == b;
    }

    function isConnectedType(a, b, type) {
        return linkedByIndex["" + a + type + b] || a == b;
    }

    function isConnectedFourWay(a, b) {
        return linkedByIndex["" + a + "ATTACKS" + b]
            && linkedByIndex["" + b + "ATTACKS" + a]
            && linkedByIndex["" + a + "SUPPORTS" + b]
            && linkedByIndex["" + b + "SUPPORTS" + a]
    }

    function isConnectedWithBoth(a, b) {
        return linkedByIndex["" + a + "ATTACKS" + b]
            && linkedByIndex["" + a + "SUPPORTS" + b]
    }

    function connect(a, b, type) {
        linkedByIndex["" + a + "," + b] = 1;
        linkedByIndex["" + a + type + b] = 1;
    }


    function clickLikeNode(event) {
        if (event.defaultPrevented) return;
        event.stopPropagation();

        $('.svg-tooltip').remove()

        if(/*checkValidToken()*/ currentUser != undefined) {
            let clickedNode = getNode(event.currentTarget.parentNode.previousSibling.getAttribute("name"));
            likePos(clickedNode);
        }
        else {
            signInTooltip($(this), "You must sign in to vote");
        }

    }

    function clickDislikeNode(event) {
        if (event.defaultPrevented) return;
        event.stopPropagation();

        $('.svg-tooltip').remove()

        if(/*checkValidToken()*/ currentUser != undefined) {
            let clickedNode = getNode(event.currentTarget.parentNode.previousSibling.getAttribute("name"));
            likeNeg(clickedNode);
        }
        else {
            signInTooltip($(this), "You must sign in to vote");
        }
    }

    function clickModalLikeNode(event) {
        event.stopPropagation();

        if(/*checkValidToken()*/ currentUser != undefined) {
            let clickedNode = getNode(event.currentTarget.getAttribute("id"));
            likePos(clickedNode);
        }
        else {
            signInTooltip($(this), "You must sign in to vote");
        }

    }

    function clickModalDislikeNode(event) {
        event.stopPropagation();

        if(/*checkValidToken()*/ currentUser != undefined) {
            let clickedNode = getNode(event.currentTarget.getAttribute("id"));
            likeNeg(clickedNode);
        }
        else {
            signInTooltip($(this), "You must sign in to vote");
        }
    }

    function likePos(clickedNode){
        console.log("clicked node")
        console.log(clickedNode);
        if(clickedNode.typeOfVote === "positive"){
            clickedNode.positiveVotes--;
            clickedNode.typeOfVote = "";
        }
        else if(clickedNode.typeOfVote === "negative"){
            clickedNode.positiveVotes++;
            clickedNode.negativeVotes--;
            clickedNode.typeOfVote = "positive"
        }
        else{
            clickedNode.positiveVotes++;
            console.log("positive votes:" + clickedNode.positiveVotes)
            clickedNode.typeOfVote = "positive";
        }

        $('#' + clickedNode.id + ".foreignObject").children(".likeDiv").html(function () {
            return getLikeContent(clickedNode);
        })

        let iconPos;
        let iconNeg;
        if (clickedNode.typeOfVote === "positive") {
            iconPos = "fas fa-thumbs-up"
            iconNeg = "far fa-thumbs-down"
        } else if (clickedNode.typeOfVote === "negative") {
            iconPos = "far fa-thumbs-up"
            iconNeg = "fas fa-thumbs-down"
        } else {
            iconPos = "far fa-thumbs-up"
            iconNeg = "far fa-thumbs-down"
        }


        $("#modal_like" + clickedNode.id).removeClass().attr("class", iconPos)
        $("#modal_posVotes" + clickedNode.id).html(clickedNode.positiveVotes)

        $("#modal_dislike" + clickedNode.id).removeClass().attr("class", iconNeg)
        $("#modal_negVotes" + clickedNode.id).html(clickedNode.negativeVotes)

        $.ajax({
            'async': true,
            'type' : "POST",
            'global': false,
            "headers": {
                "authorization": "Bearer "+ currentUser.token},
            'url': baseUrl + "/votepos/g/"+graphId + "/" + $('#semanticSelect').children("option:selected").val() +
                "/node/"+clickedNode.id+"/token/" + currentUser.token,
            'dataType': "json",
            'success': function (data) {
                reloadGraph()
            }
        });
    }

    function likeNeg(clickedNode){
        if(clickedNode.typeOfVote === "positive"){
            clickedNode.positiveVotes--;
            clickedNode.negativeVotes++;
            clickedNode.typeOfVote = "negative";
        }
        else if(clickedNode.typeOfVote === "negative"){
            clickedNode.negativeVotes--;
            clickedNode.typeOfVote = ""
        }
        else{
            clickedNode.negativeVotes++;
            clickedNode.typeOfVote = "negative";
        }

        d3.selectAll(".likeDiv").html(function (d) {
            return getLikeContent(d);
        })

        let iconPos;
        let iconNeg;
        if (clickedNode.typeOfVote === "positive") {
            iconPos = "fas fa-thumbs-up"
            iconNeg = "far fa-thumbs-down"
        } else if (clickedNode.typeOfVote === "negative") {
            iconPos = "far fa-thumbs-up"
            iconNeg = "fas fa-thumbs-down"
        } else {
            iconPos = "far fa-thumbs-up"
            iconNeg = "far fa-thumbs-down"
        }

        $("#modal_like" + clickedNode.id).removeClass().attr("class", iconPos)
        $("#modal_posVotes" + clickedNode.id).html(clickedNode.positiveVotes)

        $("#modal_dislike" + clickedNode.id).removeClass().attr("class", iconNeg)
        $("#modal_negVotes" + clickedNode.id).html(clickedNode.negativeVotes)

        $.ajax({
            'async': true,
            'type' : "POST",
            'global': false,
            "headers": {
                "authorization": "Bearer "+ currentUser.token},
            'url': baseUrl + "/voteneg/g/"+graphId + "/" + $('#semanticSelect').children("option:selected").val() +
                "/node/"+clickedNode.id+"/token/"+ currentUser.token,
            'dataType': "json",
            'success': function (data) {
                reloadGraph()
            }
        });
    }

    function clickLikeAttack(e) {
        e.stopPropagation();
        if(/*checkValidToken()*/ currentUser && currentUser.token != undefined) {
            let selectedLink = getLink(e.currentTarget.getAttribute("id"));

            if(selectedLink.typeOfVote === "positive"){
                selectedLink.posVotes--;
                selectedLink.typeOfVote = "";
            }
            else if(selectedLink.typeOfVote === "negative"){
                selectedLink.posVotes++;
                selectedLink.negVotes--;
                selectedLink.typeOfVote = "positive"
            }
            else{
                selectedLink.posVotes++;
                selectedLink.typeOfVote = "positive";
            }


            //votePosE(selectedLink);

            $(e.currentTarget).parent().html(function () {
                let iconPos;
                let iconNeg;
                if(selectedLink.typeOfVote === "positive"){
                    iconPos = "fas fa-thumbs-up"
                    iconNeg = "far fa-thumbs-down"
                }
                else if(selectedLink.typeOfVote === "negative"){
                    iconPos = "far fa-thumbs-up"
                    iconNeg = "fas fa-thumbs-down"
                }
                else{
                    iconPos = "far fa-thumbs-up"
                    iconNeg = "far fa-thumbs-down"
                }
                return "<button id= " + selectedLink.lid + " class=\"likeFatAttack\" style=\"font-size:24px\"><i class='" + iconPos + "'></i></button>"
                    + abbreviateNumber(selectedLink.posVotes) + " <button id = " + selectedLink.lid + " class=\"dislikeFatAttack\" style=\"font-size:24px\"><i class='" + iconNeg + "'></i></button>" +
                    abbreviateNumber(selectedLink.negVotes) + " ";
            })

            $.ajax({
                'async': true,
                'type' : "POST",
                'global': false,
                "headers": {
                    "authorization": "Bearer "+ currentUser.token},
                'url': baseUrl + "/voteposE/g/"+graphId + "/" + $('#semanticSelect').children("option:selected").val() +
                    "/node/" + selectedLink.lid + "/token/" + currentUser.token,
                'dataType': "json",
                'success': function () {
                    reloadGraph()
                }
            });


        }
        else {
            signInTooltip($(e.currentTarget), "You must sign in to vote");
        }
    }

    function clickDislikeAttack(e) {
        e.stopPropagation();
        if(/*checkValidToken() */ currentUser && currentUser.token != undefined) {
            let selectedLink = getLink(e.currentTarget.getAttribute("id"));

            if(selectedLink.typeOfVote === "positive"){
                selectedLink.posVotes--;
                selectedLink.negVotes++;
                selectedLink.typeOfVote = "negative";
            }
            else if(selectedLink.typeOfVote === "negative"){
                selectedLink.negVotes--;
                selectedLink.typeOfVote = ""
            }
            else{
                selectedLink.negVotes++;
                selectedLink.typeOfVote = "negative";
            }

            $(e.currentTarget).parent().html(function () {
                let iconPos;
                let iconNeg;
                if(selectedLink.typeOfVote === "positive"){
                    iconPos = "fas fa-thumbs-up"
                    iconNeg = "far fa-thumbs-down"
                }
                else if(selectedLink.typeOfVote === "negative"){
                    iconPos = "far fa-thumbs-up"
                    iconNeg = "fas fa-thumbs-down"
                }
                else{
                    iconPos = "far fa-thumbs-up"
                    iconNeg = "far fa-thumbs-down"
                }
                return "<button id= " + selectedLink.lid + " class=\"likeFatAttack\" style=\"font-size:24px\"><i class='" + iconPos + "'></i></button>"
                    + abbreviateNumber(selectedLink.posVotes) + " <button id = " + selectedLink.lid + " class=\"dislikeFatAttack\" style=\"font-size:24px\"><i class='" + iconNeg + "'></i></button>" +
                    abbreviateNumber(selectedLink.negVotes) + " ";
            })

            $.ajax({
                'async': true,
                'type' : "POST",
                'global': false,
                "headers": {
                    "authorization": "Bearer "+ currentUser.token},
                'url': baseUrl + "/votenegE/g/" + graphId + "/" + $('#semanticSelect').children("option:selected").val() +
                    "/node/" + selectedLink.lid + "/token/" + currentUser.token,
                'dataType': "json",
                'success': function () {
                    reloadGraph()
                }
            });
        }
        else {
            signInTooltip($(e.currentTarget), "You must sign in to vote");
        }
    }

    function clickRelationBtn(event) {
        if (event.defaultPrevented) return;
        event.stopPropagation();

        svg.selectAll(".svg-tooltip").remove();

        clickedNode = event.currentTarget.parentNode.previousSibling

        showActionsList(event);
    }

    // changes confirm_connect user attribute in db so it doesnt confirm new links
    function changeConfirmConnect(dontAsk) {
        if(dontAsk == true) {
            $.ajax({
                'async': true,
                'type': "POST",
                'global': false,
                "headers": {
                    "authorization": "Bearer " + currentUser.token
                },
                'url': baseUrl + "/changeConfirmConnect/false/" + currentUser.token,
                'contentType': "application/json",
                'success': function () {
                    currentUser.confirm_connect = "false";
                }
            });
        }
    }

    function showActionsList(e) {
        const isHidden = $('.sidebar_container').hasClass('hidden');
        let top = e.pageY;
        let left = isHidden ? e.pageX : e.pageX - 90;
        // Show contextmenu
        $("#argument_options").finish().toggle(100).// In the right position (the mouse)
        css({
            top: top + "px",
            left: left + "px"
        });

        fixAllNodes();
    }

    function fixAllNodes() {
        node.nodes().forEach(function(d,i) {
            const nodee = d3.select(d).data()[0]
            nodee.fx = nodee.x;
            nodee.fy = nodee.y;
        })
    }

    function releaseAllNodes(){
        node.nodes()
            .filter(function(d) {
                var nodee = d3.select(d).data()[0]
                return !(nodee.fixed === true)
            })
            .forEach(function(d,i) {
                var nodee = d3.select(d).data()[0]
                nodee.fx = null;
                nodee.fy = null;
            })
    }

    function drawFatLinks(fatLink) {
        return fatLink
            .enter().append("path")
            .attr("id", function (d) {
                return d.lid;
            })
            .attr("class", function(d) {
                let res = "fatPaths"

                if(d.source.initial && d.target.initial)
                    res += " hidden"

                return res;
            })
            .attr("stroke-width", 20)
            .attr("stroke", "transparent")
            .attr("fill", "none");
    }
    function drawLinks(link) {
        return link
            .enter().append("path")
            .attr("id", function (d) {
                return "link" + d.lid;
            })
            .attr("class", function (d) {
                let res;
                if (d.linkType === "SUPPORTS")
                    res = "path links support";
                else
                    res = "path links attack";

                if(d.source.initial && d.target.initial)
                    res += " hidden"

                return res;
            })
            .attr("source", function (d) {
                return d.source;
            })
            .attr("target", function (d) {
                return d.target;
            })
            .attr("sourceId", function (d) {
                return d.source.id;
            })
            .attr("targetId", function (d) {
                return d.target.id;
            })
            .attr("type", function (d) {
                return d.linkType;
            })
            .attr("score", function (d) {
                return d.score;
            })
            .attr("stroke-width", function (d) {
                return calcStrokeWidth(d.score);
            })
            .attr("stroke", function (d) {
                if (d.linkType === "SUPPORTS")
                    return support_color;
                else
                    return attack_color;
            })
            .attr("fill", "none");
    }


    function drawNodes(node) {
        console.log("node")
        console.log(node)
        let res = node
            .enter().append("foreignObject")
            .attr("width", function(d) {
                return d.width;
            })
            .attr("height", function(d) {
                return d.height;
            })
            .attr("class", "foreignObjectNew")
            .attr("id", function (d) {
                return d.id;
            })

        let contentDiv = res.append("xhtml:div")
            .attr("id", "contentDiv")
            .attr("class", function(d) {
                return d.initial ? "contentDiv initial" : "contentDiv"
            })
            .attr("name", function (d) {
                return d.id;
            })
            .style("background-color", d => calcColor(colorPattern, d))
            .style("border", d=> getBorderColor(d, colorPattern))


        contentDiv.append("xhtml:div")
            .attr("class", function(d) {
                return d.initial ? "pin_div hidden" : "pin_div"
            })
            .append("img")
            .attr("id", "pinDivImg")
            .attr("src", pinTransparentIcon)

        contentDiv.append(function (d) {
            let element = document.createElement("div");
            element.className = "nodeText";
            let span = document.createElement("span")
            span.className = "trunkate"
            span.innerHTML = d.text;
            element.appendChild(span)

            let images = [];
            d.upload.forEach((file) => {
                images.push(file);
            })

            if(images.length > 0) {
                let element1 = document.createElement("div");
                element1.className = "nodeImageDiv";
                element1.setAttribute("nodeId", d.id);

                let icon = document.createElement("i");
                icon.className = "fas fa-images";

                let span = document.createElement("span");
                span.innerHTML = "View files";

                element1.appendChild(icon)
                element1.appendChild(span)

                element.appendChild(element1);
            }

            return element;
        })

        res.append("xhtml:div")
            .attr("id", "likeDiv")
            .attr("class", "likeDiv")
            .style("font", "20px 'Helvetica'")
            .html(function (d) {
                return getLikeContent(d);
            })

        d3.selectAll(".relationBtn").on("click", clickRelationBtn);
        d3.selectAll('.foreignObjectNew').attr("class", function(d) {
            return d.initial ? "foreignObject clicked" : "foreignObject"
        })

        return res;
    }
    function drawPreviewLink(previewLinks) {
        return previewLinks.enter().append("path")
            .attr('marker-end', function (d) {
                var res;
                if(d.type == "ATTACKS")
                    res = "url(#endAttack)";
                else
                    res = "url(#endSupport)";

                return res;
            })
            .attr("class", "path")
            .attr("id", function (d) {
                if(d.type == "ATTACKS")
                    return "previewAttack";
                else
                    return "previewSupport";
            })
            .attr("source", function (d) {
                return d.source;
            })
            .attr("target", function (d) {
                return d.target;
            })
            .attr("sourceId", function (d) {
                return d.source.id;
            })
            .attr("targetId", function (d) {
                return d.target.id;
            })
            .attr("stroke-width", function (d) {
                return d.stroke_width;
            })
            .attr("stroke", function (d) {
                var res;
                if(d.type == "ATTACKS")
                    res = attack_color;
                else
                    res = support_color;

                return res;
            })
            .attr("fill", "none")
            .attr("visibility", "hidden")
    }

    function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();

        d.fx = d.x;
        d.fy = d.y;
    }
    function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;
    }
    function dragended(event, d) {
        if(d.fixed) {
            fixNode(d);
        }
        else {
            d.fx = null;
            d.fy = null;
        }

        if (!event.active) simulation.alphaTarget(0);
    }

    function updateCollision() {
        simulation.force('collision', d3.forceCollide().radius(function(d) {
            return d.height-25;
        }))
    }

    const trunk8_settings =  {
        fill: '&hellip;<span class="trunkateFill">Show more</span>',
        lines: 4
    };

    let isPreview = false;
    let addLinkOverNode = undefined;
    let clickedNode = undefined;
    function addLink(clickedNode, url_type) {
        $("#svgId").off("mouseleave", ".foreignObject", scrollOnNode_end);
        function linkToNodeEnter(e) {
            addLinkOverNode = getNode($(e.currentTarget).attr("id"));
        }
        function linkToNodeLeave() {
            addLinkOverNode = undefined;
        }
        function clickedToLink(e) {
            e.stopImmediatePropagation();


             $("#svgId").off("click", ".foreignObject", clickedToLink);
             simulation.alpha(0);
             $("#svgId").off("mouseleave", ".foreignObject", linkToNodeLeave)

             let type;
             if (url_type == ATTACK_END_URL) {
                 type = "ATTACKS";
                 $('#are_you_sure').html("Are you sure you want to attack this argument?")
                 $('#check-arg-connect li[data-action="first"]').html("ATTACK")
             } else if (url_type == SUPPORT_END_URL) {
                 type = "SUPPORTS";
                 $('#are_you_sure').html("Are you sure you want to support this argument?")
                 $('#check-arg-connect li[data-action="first"]').html("SUPPORT")
             }

             if(currentUser.confirm_connect == "true" || true /*TODO: REMOVE TRUE */) {
                 disableDrag();
                 //disable zoom
                 svg.on('.zoom', null);
                 $("#check-arg-connect").finish().toggle(100).// In the right position (the mouse)
                 css({
                     top: e.pageY + "px",
                     left: e.pageX + "px"
                 });

                 $("#check-arg-connect li").on("click", confirmFunction);
             }
             else {
                 connect_back_end();
             }

             function confirmFunction(event) {
                 event.stopPropagation()
                 linkToNodeLeave()


                 let dontAsk = $('#arg-connect-ask-again').is(':checked');

                 // This is the triggered action name
                 switch (event.currentTarget.getAttribute("data-action")) {

                     // A case for each action. Your actions here
                     case "first":
                         connect_back_end();
                         break;
                     case "second":
                         changeConfirmConnect(dontAsk)
                         $("#check-arg-connect li").off("click", confirmFunction);
                         $("#check-arg-connect").hide(100);
                         cancelAddLinkView()
                         break;
                 }

             }

             function connect_back_end() {
                 let dontAsk = $('#arg-connect-ask-again').is(':checked');
                 let s = clickedNode.getAttribute("name");
                 let t = e.currentTarget.getAttribute("id");

                 let isConnected = isConnectedType(s, t, type);

                 if(isConnected != 1 && s != t) {
                     $.ajax({
                         'async': false,
                         'type': "POST",
                         'global': false,
                         "headers": {
                             "authorization": "Bearer " + currentUser.token
                         },
                         'url': baseUrl + "/connectnodes/g/" + graphId  + "/" + $('#semanticSelect').children("option:selected").val() +
                             "/s/" + s + "/t/" + t + "/" + currentUser.token,
                         'dataType': "json",
                         'data': url_type,
                         'contentType': "application/json",
                         'success': function (data) {
                             let createdLink = data;
                             connect(s, t, type);
                             createdLink.source = getNode(createdLink.source);
                             createdLink.target = getNode(createdLink.target);
                             links.push(createdLink)
                             update()
                             updateSettings(svg)
                         }
                     });
                     cancelAddLinkView()
                     $("#check-arg-connect li").off("click", confirmFunction);
                     $("#check-arg-connect").hide(100);
                     changeConfirmConnect(dontAsk)
                 }
             }
        }
        function cancelAddLinkView() {
            svg.call(zoom)
            $('html').css("cursor", "auto");
            linkToNodeLeave()
            svg.selectAll(".svg-tooltip").remove();
            $(".highlighted").removeClass("highlighted")

            previewLink.attr("visibility", "hidden")
            previewLinkSupport.attr("visibility", "hidden")
            isPreview = false;

            releaseAllNodes()
            enableDrag()

            $(".wrapper").off("click", "#svgId", cancelAddLinkView);
            $("#svgId").off("click", ".foreignObject", clickedToLink);
            $("#svgId").off("mouseenter", ".foreignObject", linkToNodeEnter)
            $("#svgId").off("mouseleave", ".foreignObject", linkToNodeLeave)


            //remove any events to prevent them from firing more than once
            $("#svgId").off("mouseenter", ".fatPaths", linkMouseEnter);
            $("#svgId").off("mouseleave", ".fatPaths", linkMouseLeave);
            $("#svgId").off("mouseenter", ".foreignObject.clicked", scrollOnNode);
            $("#svgId").off("mouseleave", ".foreignObject.clicked", scrollOnNode_end);
            $("#svgId").off("mouseleave", ".foreignObject", nodeMouseLeave);
            $("#svgId").off("mouseenter", ".foreignObject", nodeMouseEnter);
            d3.selectAll(".relationBtn").on("click", null);
            $("#svgId").off("click", ".likeNode", clickLikeNode);
            $("#svgId").off("click", ".dislikeNode", clickDislikeNode);

            $("#svgId").off("click", ".likeFatAttack", clickLikeAttack);
            $("#svgId").off("click", ".dislikeFatAttack", clickDislikeAttack);
            $("#svgId").off("click", ".fatPaths", linkClick);

            $("#svgId").on("mouseenter", ".fatPaths", linkMouseEnter);
            $("#svgId").on("mouseleave", ".fatPaths", linkMouseLeave);
            $("#svgId").on("mouseenter", ".foreignObject.clicked", scrollOnNode);
            $("#svgId").on("mouseleave", ".foreignObject.clicked", scrollOnNode_end);
            $("#svgId").on("mouseleave", ".foreignObject", nodeMouseLeave);
            $("#svgId").on("mouseenter", ".foreignObject", nodeMouseEnter);

            $("#svgId").on("click", ".likeFatAttack", clickLikeAttack);
            $("#svgId").on("click", ".dislikeFatAttack", clickDislikeAttack);
            $("#svgId").on("click", ".fatPaths", linkClick);

            d3.selectAll(".relationBtn").on("click", clickRelationBtn);
            $("#svgId").on("click", ".likeNode", clickLikeNode);
            $("#svgId").on("click", ".dislikeNode", clickDislikeNode);

            $("#svgId").on("click", ".trunkateFill", seeMoreClick);

            simulation.alphaTarget(ALPHA_TARGET);
            simulation.alpha(0.5)
        }

        fixAllNodes()
        disableDrag()
        isPreview = true;

        simulation.alpha(1).restart();
        simulation.alphaTarget(ALPHA_MIN + 0.001);

        $('html').css("cursor", "pointer");
        $(clickedNode).addClass("highlighted")

        $("#svgId").off("mouseenter", ".fatPaths", linkMouseEnter);
        $("#svgId").off("mouseleave", ".fatPaths", linkMouseLeave);
        $("#svgId").off("mouseenter", ".foreignObject.clicked", scrollOnNode);
        $("#svgId").off("mouseleave", ".foreignObject.clicked", scrollOnNode_end);
        $("#svgId").off("mouseleave", ".foreignObject", nodeMouseLeave);
        $("#svgId").off("mouseenter", ".foreignObject", nodeMouseEnter);
        $("#svgId").off("click", ".trunkateFill", seeMoreClick);

        $("#svgId").off("click", ".likeFatAttack", clickLikeAttack);
        $("#svgId").off("click", ".dislikeFatAttack", clickDislikeAttack);
        $("#svgId").off("click", ".fatPaths", linkClick);

        d3.selectAll(".relationBtn").on("click", null);
        $("#svgId").off("click", ".likeNode", clickLikeNode);
        $("#svgId").off("click", ".dislikeNode", clickDislikeNode);


        mouseoutHighlight();

        $(".wrapper").on("click", "#svgId", cancelAddLinkView);
        $("#svgId").on("click", ".foreignObject", clickedToLink);

        $("#svgId").on("mouseenter", ".foreignObject", linkToNodeEnter)
        $("#svgId").on("mouseleave", ".foreignObject", linkToNodeLeave)

        previewLink.each(function(l) {
            l.source = getNode(clickedNode.getAttribute("name"))
        })
        previewLinkSupport.each(function(l) {
            l.source = getNode(clickedNode.getAttribute("name"))
        })

        if(url_type == ATTACK_END_URL) {
            previewLink.attr("visibility", "visible")
        }
        else {
            previewLinkSupport.attr("visibility", "visible")
        }
    }

    function chooseLinkType(e) {
        // This is the triggered action name
        switch (e.currentTarget.getAttribute("data-action")) {

            // A case for each action. Your actions here
            case "first":
                if(/*checkValidToken()*/ currentUser && currentUser.token != undefined) {
                    addLink(clickedNode, ATTACK_END_URL);
                }
                else {
                    signInTooltip($(e.currentTarget), "You must sign in to add an attack")
                }
                break;
            case "second":
                if(/*checkValidToken()*/ currentUser && currentUser.token != undefined) {
                    addLink(clickedNode, SUPPORT_END_URL);
                }
                else {
                    signInTooltip($(e.currentTarget), "You must sign in to add a support")
                }
                break;
            case "third": fixOrReleaseNode(clickedNode); break
        }
        // Hide it AFTER the action was triggered
        $("#argument_options").hide(100);
        releaseAllNodes()
    }

    function svgClick() {
        if($('#settingsBox').is(':visible')) {
            if($('#styles_attacks').is(':visible') || $('#styles_supports').is(':visible')) {
                $('.styles_box').css("display", "none")
            }
            else {
                $('#settingsBox').css("display", "none");
            }
        }
        if($('#sliderFilterBox').hasClass("open")) {
            $('#sliderFilterBox').removeClass("open")
        }
        $(".custom-menu").hide(100);
        isPreview = false;

        releaseAllNodes()
    }

    function disableDrag() {
        node.call(d3.drag()
            .on("start", null)
            .on("drag", null)
            .on("end", null));
    }
    function enableDrag() {
        node.filter((node) => {
            return !node.initial
        })
            .call(d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended))
    }

    function nodeClick(node) {
        svg.selectAll(".svg-tooltip").remove();

        //nodeMouseLeave();

        let clicked_foreignObject = node;

        $(clicked_foreignObject).addClass("clicked");

        //handle arrows adjusting to bigger node
        let graph_node = getNode($(clicked_foreignObject).attr("id"))
        graph_node.width = 345
        graph_node.height = 270
        $(clicked_foreignObject).attr("width", 345)
        $(clicked_foreignObject).attr("height", 270)

        updateCollision()

        //untrunkate
        $(clicked_foreignObject).find(".trunkate").trunk8('revert')

        let nodeText = $(clicked_foreignObject).find('.nodeText')

        $(nodeText)
            .append($('<span>')
                .attr("class", "showLess")
                .html("Show less"))

        $(nodeText).find(".showLess").on("click", function (e) {
            e.stopPropagation()
            showLessClick(e, clicked_foreignObject)
        })
    }

    let timer;

    function nodeMouseEnter(e) {
        let node = getNode(e.currentTarget.getAttribute("id"))
        let contentDiv = $(e.currentTarget).find(".contentDiv")
        let newBorderColor = shadeColor(calcColor(colorPattern, node), -35)

        $(contentDiv).css("border", "solid 2px " + newBorderColor)

        let selectedNode = findNode(e.currentTarget.getAttribute("id"));

        //bring node to front
        d3.select(selectedNode).raise();

        timer = setTimeout(function () {
            mouseoverHighlight(selectedNode)
            //showPreview(selectedNode)
            //openedNode = this;
            //console.log(openedNode);
        }, 500);
    }
    function nodeMouseLeave(e) {
        let node = getNode(e.currentTarget.getAttribute("id"))
        let contentDiv = $(e.currentTarget).find(".contentDiv")
        let newBorderColor = shadeColor(calcColor(colorPattern, node), -15)

        $(contentDiv).css("border", "solid 2px " + newBorderColor)

         clearTimeout(timer);
         mouseoutHighlight();
     }

    function mouseoverHighlight(node) {
        // check all other nodes to see if they're connected
        // to this one. if so, keep the opacity at 1, otherwise
        // fade

        let id = node.getAttribute("id")
        //remove all faded classes for safety
        $('.faded').removeClass("faded");
        $(".path.links").addClass("faded")

        var nodesDiv = document.getElementsByClassName("foreignObject")
        for (let i = 0; i < nodesDiv.length; i++) {
            if(//(isConnectedType(node.id, nodesDiv[i].id, "ATTACKS") || isConnectedType(node.id, nodesDiv[i].id, "SUPPORT"))) {
                !isConnected(id, nodesDiv[i].id) && !isConnected(nodesDiv[i].id, id)) {
                //nodesDiv[i].style.WebkitFilter= "blur(10px) saturate(60%) sepia(30%)";  <--- old version
                $(nodesDiv[i]).addClass("faded");
            }
        }

        $(".path.links").filter(function() {
            return ($(this).attr("sourceId") == node.id) || ($(this).attr("targetId") == node.id);
        }).removeClass("faded")


        d3.selectAll(".faded").lower();
    }
    function mouseoutHighlight() {
        $(".faded").removeClass("faded");
    }

    function scrollOnNode(e) {
        let text_div = $(e.currentTarget).find(".nodeText")

        if(text_div.prop('scrollHeight') > text_div.height()) {
            svg.on('.zoom', null);
        }
    }
    function scrollOnNode_end() {
        svg.call(zoom)
    }

    function linkClick(e) {
        e.stopPropagation();

        svg.selectAll(".svg-tooltip").remove();
        disableDrag();
        //disable zoom
        svg.on('.zoom', null);

        $("#svgId").off("mouseenter", ".foreignObject", nodeMouseEnter);
        $("#svgId").off("mouseleave", ".foreignObject", nodeMouseLeave);
        $("#svgId").off("mouseenter", ".fatPaths", linkMouseEnter);
        $("#svgId").off("mouseleave", ".fatPaths", linkMouseLeave);
        $("#svgId").off("click", ".fatPaths", linkClick);


        $(".wrapper").on("click", "#svgId", linkClickOut);
        let coordinates= d3.pointer(e, svg.node());
        let x = coordinates[0];
        let y = coordinates[1];

        let clickedFatPath = e.currentTarget;
        let d = getLink(clickedFatPath.getAttribute("id"));

        let fo = svg.append("foreignObject")
            .attr("width", 700)
            .attr("height", 200)
            .attr("x", x)
            .attr("y", y)
            .attr("class", "svg-tooltip")
            .attr("id", "likeAttackObject")
        fo.append("xhtml:div")
            .attr("id", "likeAttackDiv")
            .style("font", "20px 'Helvetica'")
            .html(function () {
                var iconPos;
                var iconNeg;
                if(d.typeOfVote === "positive"){
                    iconPos = "fas fa-thumbs-up"
                    iconNeg = "far fa-thumbs-down"
                }
                else if(d.typeOfVote === "negative"){
                    iconPos = "far fa-thumbs-up"
                    iconNeg = "fas fa-thumbs-down"
                }
                else{
                    iconPos = "far fa-thumbs-up"
                    iconNeg = "far fa-thumbs-down"
                }
                return "<button id= " + d.lid + " class=\"likeFatAttack\" style=\"font-size:24px\"><i class='" + iconPos + "'></i></button>"
                    + abbreviateNumber(d.posVotes) + " <button id = " + d.lid + " class=\"dislikeFatAttack\" style=\"font-size:24px\"><i class='" + iconNeg + "'></i></button>" +
                    abbreviateNumber(d.negVotes) + " ";
            });
    }
    function linkClickOut(e) {
        e.stopPropagation()
        linkMouseLeave();
        $(".wrapper").off("click", "#svgId", linkClickOut);


        $("#svgId").on("mouseenter", ".foreignObject", nodeMouseEnter);
        $("#svgId").on("mouseleave", ".foreignObject", nodeMouseLeave);
        $("#svgId").on("mouseenter", ".fatPaths", linkMouseEnter);
        $("#svgId").on("mouseleave", ".fatPaths", linkMouseLeave);
        $("#svgId").on("click", ".fatPaths", linkClick);

        enableDrag()
        svg.call(zoom)
    }

    function findPath1(id) {
        let selectedPath;
        for (let e = 0; e < link._groups[0].length; e++) {
            if ((link._groups[0])[e].id == id) {
                selectedPath = (link._groups[0])[e];
                break;
            }
        }
        return selectedPath
    }
    function findNode(id) {
        let selectedNode;
        for (let i = 0; i < node._groups[0].length; i++) {
            if((node._groups[0])[i].id == id) {
                selectedNode = (node._groups[0])[i];
                break;
            }
        }
        return selectedNode;
    }

    function linkMouseEnter(e) {
        svg.selectAll(".svg-tooltip").remove()
        disableDrag();
        //disable zoom
        svg.on('.zoom', null);

        let id = e.currentTarget.getAttribute("id")
        let selected_link = findPath1(id);
        $('#link'+ id).addClass("hovered");
        d3.select(selected_link).raise()

        let coordinates= d3.pointer(e, svg.node());
        let x = coordinates[0];
        let y = coordinates[1];

        let d = getLink(id);
        let fo = svg.append("foreignObject")
            .attr("width", 700)
            .attr("height", 200)
            .attr("x", x)
            .attr("y", y)
            .attr("class", "svg-tooltip")
            .attr("id", "showLinkScore")
        fo.append("xhtml:div")
            .attr("id", "scoreAttackDiv")
            .html("Score: <b> " + Number((Math.round(d.score + "e+4") + "e-2"  )).toFixed(0) + "/100</b> <p> " +
                "Positive: <b>" + abbreviateNumber(d.posVotes) + "</b> Negative: <b>" + abbreviateNumber(d.negVotes) + "</b></p><p><i> Click on the arrow to vote</i></p>");
    };

    function linkMouseLeave() {
        $(".hovered").removeClass("hovered");
        $(".svg-tooltip").remove();
        enableDrag()
        svg.call(zoom)
    };

    function showLessClick(e, foreignObject) {
        $(e.target).off("click")

        $(foreignObject).find('.nodeText').scrollTop(0)
        $(foreignObject).removeClass("clicked")
        $(foreignObject).find(".trunkate").trunk8({
            fill: trunk8_settings.fill,
            lines: trunk8_settings.lines
        });

        //handle arrows adjusting to smaller node
        let graph_node = getNode($(foreignObject).attr("id"))
        graph_node.width = 250
        graph_node.height = 170
        $(foreignObject).attr("width", 250)
        $(foreignObject).attr("height", 170)

        updateCollision()

        $(foreignObject).find('.showLess').remove()
    }

    function seeMoreClick(e) {
        e.stopPropagation()

        let foreignObject = $(e.currentTarget).parent().parent().parent().parent()[0];
        nodeClick(foreignObject)
    }


    let borderX;
    let borderY;
    let borderW;
    let borderH;
    const spacing = 400;
    const padding = 50;
    const yPosition = height/2;
    const contentDivMargin = 10;
    //assigns the fixed position for each initial node(alternatives)
    function setAndFixPosition() {

        let length = initialNodes.length
        let firstX;
        let borderWidth;

        if(length%2 == 0) { //length is even
            firstX = (length/2)*(-spacing) + spacing/2;
        }
        else { //length is odd
            firstX = Math.floor(length/2) * (-spacing);
        }

        initialNodes.forEach(function(d, index) {
            let node = getNode(d.id)

            node.fixed = true;
            node.x = width/2 + (firstX + spacing*index);
            node.y = yPosition
            node.fx = node.x
            node.fy = yPosition
            node.width = 345
            node.height = 270;
        })

        let firstNode = getNode(initialNodes[0].id)
        let lastNode = getNode(initialNodes[length-1].id)
        borderWidth = (lastNode.x + lastNode.width - contentDivMargin*2) - firstNode.x

        borderX = (width/2 + firstX + contentDivMargin) - padding /*10 is the margin between the foreignObject x and where the visible argument begins */
        borderY = (yPosition + contentDivMargin) - padding
        borderW = borderWidth + padding*2
        borderH = (firstNode.height - contentDivMargin*2) + padding*2

        g.append('rect')
            .attr("rx", 25)
            .attr("ry", 25)
            .attr('x',borderX)
            .attr('y', borderY)
            .attr('width', borderW)
            .attr('height', borderH)
            .attr('stroke', '#4e7eb0')
            //.style("stroke-dasharray", ("10,4"))
            .attr('stroke-width', "4px")
            .attr('fill', 'none')

    }

    function fixNode(d) {
        $('#'+d.id).addClass("fixed");
        d.fixed = true;
        d.fx = d.x;
        d.fy = d.y;
    }
    function releaseNode(d) {
        if($('#'+d.id).hasClass("fixed")) {
            $('#'+d.id).removeClass("fixed");
            d.fixed = false;
            d.fx = null;
            d.fy = null;
            simulation.alpha(ALPHA_MIN + 0.5).restart();
        }
    }
    function fixOrReleaseNode(clickedNode) {
        let id = clickedNode.id || clickedNode.attr("id")
        let nodee = getNode(id)

        if(nodee.fixed) {
            releaseNode(nodee)
        }
        else {
            fixNode(nodee)
        }
    }

    function get_d_attribute(d) {
        let x1 = movetoX(d),
            y1 = movetoY(d),
            // x2 = posX,
            x2 = linetoX(d),
            y2 = linetoY(d),
            //y2 =  posY,
            // Defaults for normal edge.
            drx = 0,
            dry = 0,
            xRotation = 0, // degrees
            largeArc = 0, // 1 or 0
            sweep = 1; // 1 or 0

        // Self edge.
        if (x1 === x2 && y1 === y2) {
            x1 = undefined
            x2 = undefined
            /*x1 = d.source.x;
        y1 = d.source.y;
        x2 = d.target.x;
        y2 = d.target.y;
        // Fiddle with this angle to get loop oriented.
        xRotation = -160;

        // Needs to be 1.
        largeArc = 1;

        // Change sweep to change orientation of loop.
        //  sweep = 0;

        // Make drx and dry different to get an ellipse
        // instead of a circle.
        drx = 30;
        dry = 35;

        // For whatever reason the arc collapses to a point if the beginning
        // and ending points of the arc are the same, so kludge it.
        x1 = x1 - 29
        y1 = y1 - 0
        x2 = x2 + 9;
        y2 = y2 - 30;*/
        }
        /* //adjust the beginning points so the arcs don't overlap
     else if(isConnectedType(d.source.id, d.target.id, "ATTACKS") == 1 &&
         isConnectedType(d.source.id, d.target.id, "SUPPORTS") == 1) {
        if(d.linkType == "SUPPORTS") {
            x1 += 30;
            y1 += 30;
            x2 += 15;
            y2 += 15;
         }
         else {
            x1 -= 30;
            y1 -= 30;
            x2 -= 15;
            y2 -= 15;
         }
     }*/

        return "M" + x1 + "," + y1 + "A" + drx + "," + dry + " " + xRotation + "," + largeArc + "," + sweep + " " + x2 + "," + y2;
    }
    function movetoX(d) {
        //where path will start
        // Retrieve the width and height attributes...
        let w = parseFloat(d.source.width);
        let centerOffset = 0;
        // let isMutuallyConnected = isConnected(d.target.id, d.source.id);

        //if(isMutuallyConnected) {
        //      centerOffset = getCenterOffset(d)
        // }
        // ...so we can change the x,y coordinates of the node to be
        // at its center rather than the top-left corner
        d.source.newX = d.source.x + (w / 2) + centerOffset;
        return d.source.newX;
    }
    function movetoY(d) {
        //where path will start
        // Retrieve the width and height attributes...
        let h = parseFloat(d.source.height);

        let centerOffset = 0;
        /*var isMutuallyConnected = isConnected(d.target.id, d.source.id);

        if(isMutuallyConnected) {
            centerOffset = getCenterOffset(d)
        }*/
        // ...so we can change the x,y coordinates of the node to be
        // at its center rather than the top-left corner
        d.source.newY = d.source.y + (h / 2) + centerOffset;
        return d.source.newY;
    }

    function linetoX(d) {
        let x = d.target.id == 5 || d.target.id == 11 ? 0 : 0

        //where path will end
        // Retrieve the width and height attributes...
        let w = parseFloat(d.target.width) + x;
        let h = parseFloat(d.target.height) + x;

        // ...so we can locate the x,y coordinates of the center of
        // the node...
        d.target.centerX = d.target.x + ((w-x) / 2);
        d.target.centerY = d.target.y + ((h-x) / 2);

        // ...which we will use to calculate the x,y coordinates of
        // the point on the perimeter of the node where the path will
        // end -- the idea is that the arrowhead at the end of the
        // path is "smart" enough to move around the perimeter of the
        // rectangular node as the node moves around the screen.
        smartPathEndX(d, w, h);
        return d.target.newX;
    };
    function linetoY(d) {
        let x = d.target.id == 5 || d.target.id == 11 ? 0 : 0
        //where path will end
        // Retrieve the width and height attributes...
        let w = parseFloat(d.target.width) + x;
        let h = parseFloat(d.target.height) + x;

        // ...so we can locate the x,y coordinates of the center of
        // the node...
        d.target.centerX = d.target.x + ((w-x) / 2);
        d.target.centerY = d.target.y + ((h-x) / 2);

        // ...which we will use to calculate the x,y coordinates of
        // the point on the perimeter of the node where the path will
        // end -- the idea is that the arrowhead at the end of the
        // path is "smart" enough to move around the perimeter of the
        // rectangular node as the node moves around the screen.
        smartPathEndY(d, w, h);
        return d.target.newY;
    };
    function smartPathEndX(d, w, h) {
        let centerOffset = 0;

        let isMutuallyConnected = isConnected(d.target.id, d.source.id);

        if(isMutuallyConnected == false) {
            if(isConnectedType(d.source.id, d.target.id, "ATTACKS") == 1 &&
                isConnectedType(d.source.id, d.target.id, "SUPPORTS") == 1) {
                if(d.linkType == "SUPPORTS")
                    centerOffset = 30;
                else
                    centerOffset = -30;
            }
        }
        else {
            centerOffset = getCenterOffset(d)
        }


        let adjustment = d.target.id == 5 || d.target.id == 11 ? 0 : 0;
        // We need to work out the (tan of the) angle between the
        // imaginary horizontal line running through the center of the
        // target node and the imaginary line connecting the center of
        // the target node with the top-left corner of the same
        // node. Of course, this angle is fixed.
        let tanRatioFixed =
            (d.target.centerY - (d.target.y + adjustment))
            /
            (d.target.centerX - (d.target.x + adjustment));

        // We also need to work out the (tan of the) angle between the
        // imaginary horizontal line running through the center of the
        // target node and the imaginary line connecting the center of
        // the target node with the center of the source node. This
        // angle changes as the nodes move around the screen.
        let tanRatioMoveable =
            Math.abs(d.target.centerY - d.source.newY)
            /
            Math.abs(d.target.centerX - d.source.newX); // Note,
        // JavaScript handles division-by-zero by returning
        // Infinity, which in this case is useful, especially
        // since it handles the subsequent Infinity arithmetic
        // correctly.

        // Now work out the intersection point

        if (tanRatioMoveable == tanRatioFixed) {
            // Then path is intersecting at corner of textbox so draw
            // path to that point

            // By default assume path intersects a left-side corner
            d.target.newX = d.target.x + adjustment + centerOffset;

            // But...
            if (d.target.centerX < d.source.newX) {
                // i.e. if target node is to left of the source node
                // then path intersects a right-side corner
                d.target.newX = d.target.x + adjustment + centerOffset + w;
            }

        }

        if (tanRatioMoveable < tanRatioFixed) {
            // Then path is intersecting on a vertical side of the
            // textbox, which means we know the x-coordinate of the
            // path endpoint but we need to work out the y-coordinate

            // By default assume path intersects left vertical side
            d.target.newX = d.target.x + adjustment;

            // But...
            if (d.target.centerX < d.source.newX) {
                // i.e. if target node is to left of the source node
                // then path intersects right vertical side
                d.target.newX = d.target.x + adjustment + w;
            }

        }

        if (tanRatioMoveable > tanRatioFixed) {
            // Then path is intersecting on a horizontal side of the
            // textbox, which means we know the y-coordinate of the
            // path endpoint but we need to work out the x-coordinate

            // By default assume path intersects top horizontal side

            d.target.newX =
                d.target.centerX + centerOffset +  - ((d.target.centerY - (d.target.y + adjustment))
                /
                tanRatioMoveable);

            // But...
            if (d.target.centerX < d.source.newX) {
                // i.e. if target node is to left of the source node
                // then path intersects towards the righthand side
                d.target.newX = (2 * (d.target.x + adjustment)) - d.target.newX + w + (centerOffset)*2;

            }
        }
    }
    function smartPathEndY(d, w, h) {
        let centerOffset = 0;

        let isMutuallyConnected = isConnected(d.target.id, d.source.id);

        if(isMutuallyConnected == false) {
            if(isConnectedType(d.source.id, d.target.id, "ATTACKS") == 1 &&
                isConnectedType(d.source.id, d.target.id, "SUPPORTS") == 1) {
                if(d.linkType == "SUPPORTS")
                    centerOffset = 30;
                else
                    centerOffset = -30;
            }
        }
        else {
            centerOffset = getCenterOffset(d)
        }


        let adjustment = d.target.id == 5 || d.target.id == 11 ? 0 : 0;
        // We need to work out the (tan of the) angle between the
        // imaginary horizontal line running through the center of the
        // target node and the imaginary line connecting the center of
        // the target node with the top-left corner of the same
        // node. Of course, this angle is fixed.
        let tanRatioFixed =
            (d.target.centerY - (d.target.y + adjustment))
            /
            (d.target.centerX - (d.target.x + adjustment));

        // We also need to work out the (tan of the) angle between the
        // imaginary horizontal line running through the center of the
        // target node and the imaginary line connecting the center of
        // the target node with the center of the source node. This
        // angle changes as the nodes move around the screen.
        let tanRatioMoveable =
            Math.abs(d.target.centerY - d.source.newY)
            /
            Math.abs(d.target.centerX - d.source.newX); // Note,
        // JavaScript handles division-by-zero by returning
        // Infinity, which in this case is useful, especially
        // since it handles the subsequent Infinity arithmetic
        // correctly.

        // Now work out the intersection point

        if (tanRatioMoveable == tanRatioFixed) {
            // Then path is intersecting at corner of textbox so draw
            // path to that point


            // By default assume path intersects a top corner
            d.target.newY = d.target.y + adjustment + centerOffset;

            // But...
            if (d.target.centerY < d.source.newY) {
                // i.e. if target node is above the source node
                // then path intersects a bottom corner
                d.target.newY = d.target.y + adjustment + centerOffset +  + h;
            }
        }

        if (tanRatioMoveable < tanRatioFixed) {
            // Then path is intersecting on a vertical side of the
            // textbox, which means we know the x-coordinate of the
            // path endpoint but we need to work out the y-coordinate


            // Now use a bit of trigonometry to work out the y-coord.

            // By default assume path intersects towards top of node
            d.target.newY =
                d.target.centerY + centerOffset +  - ((d.target.centerX - (d.target.x + adjustment))
                *
                tanRatioMoveable);

            // But...
            if (d.target.centerY < d.source.newY) {
                // i.e. if target node is above the source node
                // then path intersects towards bottom of the node
                d.target.newY = (2 * (d.target.y + adjustment)) - d.target.newY + h + 2*(centerOffset);
            }
        }

        if (tanRatioMoveable > tanRatioFixed) {
            // Then path is intersecting on a horizontal side of the
            // textbox, which means we know the y-coordinate of the
            // path endpoint but we need to work out the x-coordinate

            // By default assume path intersects top horizontal side
            d.target.newY = d.target.y + adjustment;

            // But...
            if (d.target.centerY < d.source.newY) {
                // i.e. if target node is above the source node
                // then path intersects bottom horizontal side
                d.target.newY = d.target.y + adjustment + h;
            }

            // Now use a bit of trigonometry to work out the x-coord.

        }
    }
    function getCenterOffset(d) {
        let centerOffset = 0;
        if(isConnectedFourWay(d.source.id, d.target.id)) {
            if(d.source.id < d.target.id) {
                if(d.linkType == "ATTACKS")
                    centerOffset = 10
                else
                    centerOffset = -10
            }
            else {
                if(d.linkType == "ATTACKS")
                    centerOffset = -25;
                else
                    centerOffset = 25;
            }
        }
        else if(isConnectedWithBoth(d.source.id, d.target.id)) {
            if(d.linkType == "ATTACKS")
                centerOffset = -25
            else
                centerOffset = -5
        }
        else if(isConnectedWithBoth(d.target.id, d.source.id)) {
            centerOffset = 15
        }
        else {
            //mutual attack or support
            if(d.source.id < d.target.id)
                centerOffset = 15;
            else
                centerOffset = -15
        }

        return centerOffset;
    }

    let posX;
    let posY;
    function getTransformation(transform) {
        // Create a dummy g for calculation purposes only. This will never
        // be appended to the DOM and will be discarded once this function
        // returns.
        let g = document.createElementNS("http://www.w3.org/2000/svg", "g");

        // Set the transform attribute to the provided string value.
        g.setAttributeNS(null, "transform", transform);

        // consolidate the SVGTransformList containing all transformations
        // to a single SVGTransform of type SVG_TRANSFORM_MATRIX and get
        // its SVGMatrix.
        let matrix = g.transform.baseVal.consolidate().matrix;

        // Below calculations are taken and adapted from the private function
        // transform/decompose.js of D3's module d3-interpolate.
        let {a, b, c, d, e, f} = matrix;   // ES6, if this doesn't work, use below assignment
        // var a=matrix.a, b=matrix.b, c=matrix.c, d=matrix.d, e=matrix.e, f=matrix.f; // ES5
        let scaleX =  Math.sqrt(a * a + b * b);
        let scaleY = Math.sqrt(c * c + d * d);
        let skewX = a * c + b * d;
        if (scaleX){
            a /= scaleX;
            b /= scaleX;
        }
        if (skewX) {
            c -= a * skewX;
            d -= b * skewX;
        }
        if (scaleY) {
            c /= scaleY;
            d /= scaleY;
            skewX /= scaleY;
        }
        if (a * d < b * c) {
            a = -a;
            b = -b;
            skewX = -skewX;
            scaleX = -scaleX;
        }

        return {
            translateX: e,
            translateY: f,
            rotate: Math.atan2(b, a) * 180 / Math.PI,
            skewX: Math.atan(skewX) * 180 / Math.PI,
            scaleX: scaleX,
            scaleY: scaleY
        };
    }
    function svgMouseMove(event, svg, adjustment) {
        let coordinates = d3.pointer(event, event.currentTarget);
        let pt = event.currentTarget.createSVGPoint();
        pt.x = coordinates[0];
        pt.y = coordinates[1];

        let transformString = $(svg).attr("transform");
        let x = 0,
            y = 0,
            scaleX = 1,
            scaleY = 1;

        if(transformString != undefined) {
            let transform = getTransformation(transformString);
            x = transform["translateX"];
            y = transform["translateY"];
            scaleX = transform["scaleX"];
            scaleY = transform["scaleY"];
        }

        posX = (pt.x - x )/scaleX - adjustment;
        posY = (pt.y - y )/scaleY - adjustment;
    }


    const radius = 400;
    function ticked() {
        if(isPreview) {
            fixAllNodes()

            previewLink.attr("d", function (d) {
                let x1 = movetoX(d),
                    y1 = movetoY(d);

                let x2, y2;
                if(addLinkOverNode == undefined) {
                    x2 = posX;
                    y2 = posY;
                }
                else {
                    d.target = addLinkOverNode
                    x2 = linetoX(d);
                    y2 = linetoY(d);
                }

                // Defaults for normal edge.
                let drx = 0,
                    dry = 0,
                    xRotation = 0, // degrees
                    largeArc = 0, // 1 or 0
                    sweep = 1; // 1 or 0

                return "M" + x1 + "," + y1 + "A" + drx + "," + dry + " " + xRotation + "," + largeArc + "," + sweep + " " + x2 + "," + y2;
            })
            previewLinkSupport.attr("d", function (d) {
                let x1 = movetoX(d),
                    y1 = movetoY(d);

                let x2, y2;
                if(addLinkOverNode == undefined) {
                    x2 = posX;
                    y2 = posY;
                }
                else {
                    d.target = addLinkOverNode
                    x2 = linetoX(d);
                    y2 = linetoY(d);
                }

                // Defaults for normal edge.
                var drx = 0,
                    dry = 0,
                    xRotation = 0, // degrees
                    largeArc = 0, // 1 or 0
                    sweep = 1; // 1 or 0

                return "M" + x1 + "," + y1 + "A" + drx + "," + dry + " " + xRotation + "," + largeArc + "," + sweep + " " + x2 + "," + y2;
            })
        } else {
            node.attr("transform", function (d) {
                if(!d.initial) {
                   /* if((d.x + d.width >= borderX && d.x <= borderX + borderW) && d.y + d.height >= borderY && d.y <= borderY + borderH) {
                        if(d.y + d.height < borderY + borderH/2) {
                            d.y = Math.min(d.y, borderY - d.height)
                        }
                        else {
                            d.y = Math.max(d.y, borderY + borderH)
                        }
                    }*/


                    //d.x = Math.max(borderX, Math.min(borderX + borderW - d.width - contentDivMargin, d.x));
                    //d.y = Math.max(borderY, Math.min(borderY + borderH - d.height - contentDivMargin, d.y));

                }
                return "translate(" + d.x + "," + d.y + ")";
            });
            //update link positions
            fatLink.attr("d", get_d_attribute);
            link.attr("d", get_d_attribute);
            node.attr("transform", function (d) {
                if(!d.initial) {
                /*    if((d.x + d.width >= borderX && d.x <= borderX + borderW) && d.y + d.height >= borderY && d.y <= borderY + borderH) {
                        if(d.y + d.height < borderY + borderH/2) {
                            d.y = Math.min(d.y, borderY - d.height)
                        }
                        else {
                            d.y = Math.max(d.y, borderY + borderH)
                        }
                    }*/


                    //d.x = Math.max(borderX, Math.min(borderX + borderW - d.width - contentDivMargin, d.x));
                    //d.y = Math.max(borderY, Math.min(borderY + borderH - d.height - contentDivMargin, d.y));

                }
                return "translate(" + d.x + "," + d.y + ")";
            });

        }
    }

    let currentFileIdx = 0;
    let totalFiles = 0;
    let currentFiles;
    let currentNodeId;

    const controls = [
        'play-large', // The large play button in the center
        'restart', // Restart playback
        //'rewind', // Rewind by the seek time (default 10 seconds)
        'play', // Play/pause playback
        //'fast-forward', // Fast forward by the seek time (default 10 seconds)
        'progress', // The progress bar and scrubber for playback and buffering
        'current-time', // The current time of playback
        'duration', // The full duration of the media
        'mute', // Toggle mute
        'volume', // Volume control
        'captions', // Toggle captions
        'settings', // Settings menu
        //'pip', // Picture-in-picture (currently Safari only)
        //'airplay', // Airplay (currently Safari only)
        //'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
        'fullscreen', // Toggle fullscreen
    ];

    const player = new Plyr('#player', { controls: controls });

    function imageViewDotClick() {
        $('.fileProgress').find(".dot.selected").removeClass("selected");
        currentFileIdx = $(this).index();

        let cur = currentFileIdx + 1;
        $('.fileProgress').find(".dot:nth-child(" + cur + ")").addClass("selected");
        changeFile();
    }

    function openFileViewModal(e) {
        e.stopPropagation();
        e.stopImmediatePropagation();

        let nodeId = $(this).attr("nodeId");
        let node = getNode(nodeId);
        let images = node.upload

        currentFileIdx = 0;
        totalFiles = images.length;
        currentFiles = images;
        currentNodeId = nodeId;

        $('.fileProgress').empty();
        for(let i=0; i<totalFiles; i++) {
            if(i == 0) {
                $('.fileProgress').append($('<span>')
                    .attr("class", "dot selected"));
            }
            else {
                $('.fileProgress').append($('<span>')
                    .attr("class", "dot"));
            }
        }

        $('#argumentFilesViewModal').modal('show');

        changeFile();
        openImageModal(node)
    }

    $(".previousFile").on("click", function () {
        $('.fileProgress').find(".dot.selected").removeClass("selected");

        if(currentFileIdx == 0)
            currentFileIdx = totalFiles-1;
        else
            currentFileIdx--;

        let cur = currentFileIdx + 1;
        $('.fileProgress').find(".dot:nth-child(" + cur + ")").addClass("selected");
        changeFile();
    })

    $(".nextFile").on("click", function () {
        $('.fileProgress').find(".dot.selected").removeClass("selected");
        if(currentFileIdx == totalFiles-1)
            currentFileIdx = 0;
        else
            currentFileIdx++;

        let cur = currentFileIdx + 1;

        $('.fileProgress').find(".dot:nth-child(" + cur + ")").addClass("selected");

        console.log("current: " + currentFileIdx)
        changeFile();
    })

    const trunk8_settings_pdf =  {
        width: 200
    };

    function changeFile() {
        player.stop();
        $("#argumentFilesViewModal").find(".plyr").hide()
        $("#argumentFilesViewModal").find(".imageFile").hide();
        $("#argumentFilesViewModal").find(".pdfPreview").hide();

        let extension = currentFiles[currentFileIdx].split(".").pop().toLowerCase();
        console.log(extension)
        if(extension == "jpeg"
            || extension == "jpg"
            || extension == "png") {
            console.log(currentFiles[currentFileIdx] + " is an image!")
            var img = $("#argumentFilesViewModal").find(".imageFile") //img src has to have localhost or swarg.di... apparently
            img.attr("src", baseUrl + "/getFile/" + currentNodeId + "/" + currentFiles[currentFileIdx])
            img.show();
        }
        else if(extension == "mp4") {
            console.log(currentFiles[currentFileIdx] + " is a video!")
            var video =  $("#argumentFilesViewModal").find("video");
            video.attr("src", baseUrl + "/stream/" + currentNodeId + "/" + currentFiles[currentFileIdx]);
            $("#argumentFilesViewModal").find(".plyr").show()
        }
        else if(extension == "pdf") {
            console.log(currentFiles[currentFileIdx] + " is a pdf file!")
            let pdfDiv = $("#argumentFilesViewModal").find(".pdfPreview");
            $(".trunkatePDF").html(currentFiles[currentFileIdx]);

            if(currentFiles[currentFileIdx].length > trunk8_settings_pdf.width)
                $(".trunkatePDF").trunk8(trunk8_settings_pdf)

            $(".downloadBtn").on("click", function () {
                window.open(baseUrl + "/getFile/" + currentNodeId + "/" + currentFiles[currentFileIdx]);
            })

            pdfDiv.show();
            pdfDiv.find("img").show();
        }
        else {
            console.log(currentFiles[currentFileIdx] + " is an unexpected type of file: " + extension)
        }
    }

    function openImageModal(d) {
        console.log(d)
        let iconPos;
        let iconNeg;
        if (d.typeOfVote === "positive") {
            iconPos = "fas fa-thumbs-up"
            iconNeg = "far fa-thumbs-down"
        } else if (d.typeOfVote === "negative") {
            iconPos = "far fa-thumbs-up"
            iconNeg = "fas fa-thumbs-down"
        } else {
            iconPos = "far fa-thumbs-up"
            iconNeg = "far fa-thumbs-down"
        }

        $("body").append($("<div>")
            .attr("class", "argImageView")
            .append($("<span>")
                .attr("class", "arg")
                .html(function () {
                    return d.text
                }))
            .append($("<hr>"))
            .append($("<div>")
                .attr("class", "likeDivExpanded")
                .append($("<button>")
                    .attr("id", d.id)
                    .attr("class", "likeNode modal_like")
                    .append($("<i>")
                        .attr("id", "modal_like" + d.id)
                        .attr("class", iconPos)))
                .append($("<span>")
                    .attr("id", "modal_posVotes" + d.id)
                    .html(abbreviateNumber(d.positiveVotes)))
                .append($("<button>")
                    .attr("id", d.id)
                    .attr("class", "dislikeNode modal_dislike")
                    .append($("<i>")
                        .attr("id", "modal_dislike" + d.id)
                        .attr("class", iconNeg)))
                .append($("<span>")
                    .attr("id", "modal_negVotes" + d.id)
                    .html(abbreviateNumber(d.negativeVotes))))
            .append($("<hr>"))
        )

        $(".modal_like").on("click", clickModalLikeNode)
        $(".modal_dislike").on("click", clickModalDislikeNode)
    }

    function closeImageModal(e) {
        e.stopPropagation()
        e.stopImmediatePropagation()
        console.log("closing stuff")
        player.stop();
        $(".argImageView").remove();
        $('#argumentFilesViewModal').modal('hide');
    }


    /* event handlers */
    let fileName = [];
    let file_num = 0;
    let file_id = 0;
    let data;
    const trunk8_settings_file =  {
        lines: 1
    };

    $('body').on("click", ".dot:not(.selected)", imageViewDotClick)
    $("#svgId").on("click", ".nodeImageDiv", openFileViewModal)
    $("body").on("click", ".closeButtonUpload", closeImageModal)


    $('.help-button-exit').on("click", function () {
        $("#tutorialModal").modal('hide')

        //reset tutorial
        $("#help-svg").empty()
        $('#help-divTextarea').val("")

        $('.help-tooltip:not(.help-header)').remove()
        help_simulation.stop()

        $('.help-bottom-buttons *').off()
        $("#help-svg").off()
        $("#help-svg *").off()

        $('#helpModal-createArgument *').animate({
            opacity: 1
        }, FADE_IN)

        update()
        $('svg').on("click", svgClick)
    })

    $("body").on("click", ".file-name2.uploaded", getFile);

    $('#tutorialButton').on("click", function (e) {
        e.stopPropagation()
        $('#settingsBox').css("display", "none");

        openTutorial()
    })

    $('#closeStartTutorial').on("click", function () {
        console.log("aqui")
        $("#startTutorialModal").modal('hide')
    })


    $('#saveButton').on("click", function() {
        saveUserSettings(currentUser, dispatch)
    });

    function getFile() {
        const file_name = $(this).parent().parent().attr("file_name");
        let index = -1;

        window.open(baseUrl + "/getTempFile/" + graphId + "/" + currentUser.token + "/" + file_name + "/" + index);
    }
    function removeFile() {
        console.log("removing temp file")

        let file = $(this).parent();
        let file_name = file.attr("file_name");
        const index = fileName.indexOf(file_name);
        if (index > -1) {
            fileName.splice(index, 1);
        }
        let ind = -1;
        $.ajax({
            'type' : "DELETE",
            'async': true,
            'global': true,
            'url': baseUrl + "/deleteFile/" + graphId + "/" + currentUser.token + "/" + file_name + "/" + ind + "/false",
            'dataType': "json",
            'success' : function(data) {
                file.remove();
                file_num--;
            },
            'error' :function(xhr, status, error) {
                let errorMessage = xhr.status + ': ' + xhr.statusText;
                alert('Error - ' + errorMessage);
            }
        })
    }


    function preUploadFile(progressDiv) {
        progressDiv.css("width", "75%");
        let xhr = new XMLHttpRequest();
        let index = -1;

        xhr.open("post", baseUrl + "/uploadFiles/" + graphId + "/" + currentUser.token + "/" + index, true);

        xhr.onload = function () {
            progressDiv.css("width", "100%");
            progressDiv.parent().next().off("click");
            progressDiv.parent().next().on("click", removeFile);
            setTimeout(function () {
                progressDiv.parent().css("visibility", "hidden");
                progressDiv.parent().parent().find(".file-name2").addClass("uploaded");
            }, 1500);
        };

        progressDiv.parent().next().on("click", function () {
            xhr.abort();
            progressDiv.parent().parent().remove();
            file_num--;
        })

        xhr.send(data);
    }

    function fileInputChange() {
        const files = this.files;
        data = new FormData()
        if (files.length) {
            for (let i = 0; i < files.length; i++) {
                if(files[i].size/1024/1024 > 10) {   // 10MB
                    alert("Your file must not exceed 10MB");
                }
                else if(file_num === MAX_FILES) {
                    alert("You can only upload " + MAX_FILES + " files per argument")
                    return;
                }
                else {
                    const name = files[i].name;
                    if(fileName.includes(name)) {
                        alert("Duplicate file!")
                    }
                    else {
                        $('.image-upload.change').append($('<div>')
                            .attr("class", "file-preview")
                            .attr("id", "file" + file_id)
                            .attr("file_name", name)
                            .append($('<div>')
                                .attr("class", "file-name")
                                .append($('<div>')
                                    .attr("class", "file-name2")
                                    .append($('<span>')
                                        .attr("class", "file-title")
                                        .html(name))
                                )
                                .append($('<span>')
                                    .attr("class", "file-size")
                                    .html(function () {
                                        var size = files[i].size / 1024 / 1024;
                                        return " (" + size.toFixed(2) + "MB)";
                                    })))
                            .append($('<div>')
                                .attr("class", "progressbar")
                                .append($('<div>')))
                            .append($('<button>')
                                .attr("type", "button")
                                .attr("class", "close removeFile")
                                .html("&times;"))
                        )

                        $(".file-title").trunk8(trunk8_settings_file);



                        let progressDiv = $("#file" + file_id).find(".progressbar").children();
                        progressDiv.css("width", "20%");

                        data.append("files", files[i], name);
                        fileName.push(name);
                        preUploadFile(progressDiv);
                        file_num++;
                        file_id++;
                    }
                }

            }
            $('#file-input').val('');
        }
    }

    function submitArg() {
        let content = $("#divTextarea").val().trim();

        //check if argument is empty
        if(content === "") {
            return
        }

        //check if any image is still being uploaded - all .file-name2 must be .uploaded
        if($('.file-name2:not(.uploaded)').length > 0) {

            if ($('#alertUploading').data("active")) {
            }
            else {
                $('#alertUploading').show().data("active", true);
                setTimeout(function() {
                    $('#alertUploading').hide().data("active", false);
                }, 3000);
            }

            return;
        }

        let temp = JSON.stringify({
            "id": 0,
            "posVotes": 1,
            "negVotes": 0,
            "userId": currentUser.userId,
            "weight": 0,
            "arg": "",
            "text": content,
            "upload": fileName,
            "icon": ""
        });

        let index = -1;
        let url = baseUrl + "/addnode/" + graphId + "/not/" + currentUser.token + "/" + index;
        $.ajax({
            'async': true,
            'type' : "POST",
            'global': false,
            "headers": {
                "authorization": "Bearer " + currentUser.token },
            'url': url,
            'dataType': "json",
            'data': temp,
            'contentType': "application/json",
            'success': function (data) {
                $("#submitArgModal").find(".close").click()
                let newArg = data
                newArg = Object.assign({}, {
                    ...newArg,
                    typeOfVote: "",
                    filtered: false,
                    fixed: false,
                    width: 250,
                    height: 170
                })
                likePos(newArg);
                nodes.push(newArg)
                drawGraph()
            }
        });
    }

    $('body').on("change", "#file-input", fileInputChange)
    $('body').on("click", "#submitArg", submitArg)

    $('#addButton').on("click", function () {
        if(currentUser != undefined) {
            fileName = [];
            $('.file-preview').remove();
            file_num = 0;
            file_id = 0;
        }
    })

    function pinDivClick(e) {
        e.stopPropagation()
        fixOrReleaseNode($(e.currentTarget).parent().parent())

        let div = $(e.currentTarget);
        if(div.hasClass("pinned")) {
            div.find('img').attr("src", pinTransparentIcon)
            div.removeClass("pinned");
        }
        else {
            div.find('img').attr("src", pinBlackIcon)
            div.addClass("pinned");
        }
    }
    $("#svgId").on("click", '.pin_div', pinDivClick)

    function nodeContextMenu(e) {
        e.stopPropagation();
        e.preventDefault();

        clickedNode = $(e.currentTarget).children(".contentDiv")[0]
        showActionsList(e)
    }


    $("#svgId").on("contextmenu", ".foreignObject", nodeContextMenu);
    $("svg").on("click", svgClick);

    $("#svgId").on("click", ".trunkateFill", seeMoreClick);
    $("#svgId").on("mouseenter", ".foreignObject.clicked", scrollOnNode);
    $("#svgId").on("mouseleave", ".foreignObject", scrollOnNode_end);
    $("#svgId").on("mouseenter", ".foreignObject", nodeMouseEnter);
    $("#svgId").on("mouseleave", ".foreignObject", nodeMouseLeave);
    $("#svgId").on("click", ".fatPaths", linkClick);
    $("#svgId").on("mouseenter", ".fatPaths", linkMouseEnter);
    $("#svgId").on("mouseleave", ".fatPaths", linkMouseLeave);
    $("#root").on("click", "#argument_options li", chooseLinkType);
    $("#svgId").on("click", ".likeNode", clickLikeNode);
    $("#svgId").on("click", ".dislikeNode", clickDislikeNode);
    $("#svgId").on("click", ".likeFatAttack", clickLikeAttack);
    $("#svgId").on("click", ".dislikeFatAttack", clickDislikeAttack);

    svg.on("mousemove", function (e) {
        svgMouseMove( e, $('#rootG'), 0)
    });
    $('#sidebarCollapse').on('click', sidebarCollapseClick);
    $('#settingsButton').on("click", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation()
        if($('#sliderFilterBox').hasClass("open")) {
            $('#sliderFilterBox').removeClass("open")
        }
        if($('#settingsBox').is(':visible')) {
            $('#settingsBox').css("display", "none");
        }
        else {
            $('#settingsBox').css("display", "flex");
            $('.styles_box').css("display", "none");
        }
    })
    
    $('#dropdownFilters').on("click", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        if($('#settingsBox').is(':visible')) {
            if($('#styles_attacks').is(':visible') || $('#styles_supports').is(':visible')) {
                $('.styles_box').css("display", "none")
            }
            else {
                $('#settingsBox').css("display", "none");
            }
        }
        $('#sliderFilterBox').toggleClass('open')
    })

    let locked = false;
    let overlapping = false;
    $('.slider-draggable').on('mousedown', function (e) {
        e.stopPropagation()

        let posX = e.pageX;
        let rangeDiv = $(this).parent();
        $('#sliderFilterBox').on('mousemove', function (e) {
            e.stopPropagation()

            //if both sliders are on top of each other
            if(overlapping) {
                var diffX = e.pageX - posX;
                var rangeDivMax = $('.range-div.max')
                var rangeDivMin = $('.range-div.min')
                if(rangeDivMax.width() == 0) { //if max slider is in max position, can only move min slider when they're overlapping
                    moveMinSlider(e, posX)
                    rangeDiv = rangeDivMin
                }
                else if(rangeDivMin.width() == 0) { //vice versa
                    moveMaxSlider(e, posX)
                    rangeDiv = rangeDivMax
                }
                else if(diffX > 0) { //moving to the right
                    moveMaxSlider(e, posX)
                    rangeDiv = rangeDivMax
                }
                else if(diffX < 0) { //moving to left
                    moveMinSlider(e, posX)
                    rangeDiv = rangeDivMin
                }
            }
            else { //behave normally
                if(rangeDiv.hasClass("min"))
                    moveMinSlider(e, posX)
                else
                    moveMaxSlider(e, posX)
            }
            filterByRangeSlider()
            posX = e.pageX;
        })

        $('#root').on('mouseup', rangeSliderLeave)
    })

    /* here 14 is the 22px(slider marker width) - 4px - 4px (4px border on each side) */
    const markerVisibleColorWidth = 14;

    function moveMinSlider(e, posX) {
        overlapping = false;

        var rangeDiv = $('.range-div.min')
        var diffX = e.pageX - posX;

        var width = rangeDiv.width();
        var newWidth = width + diffX;


        if(newWidth + $('.range-div.max').width() + markerVisibleColorWidth >= $('#slider-background').width()) {
            newWidth = $('#slider-background').width() - $('.range-div.max').width() - markerVisibleColorWidth
            locked = true;
        }
        else {
            locked = false;
        }

        rangeDiv.css("width", Math.max(0, newWidth))
    }

    function moveMaxSlider(e, posX) {
        overlapping = false;

        var rangeDiv = $('.range-div.max')
        var diffX = posX - e.pageX;

        var width = rangeDiv.width();
        var newWidth = width + diffX;

        if(newWidth + $('.range-div.min').width() + markerVisibleColorWidth >= $('#slider-background').width()) {
            newWidth = $('#slider-background').width() - $('.range-div.min').width() - markerVisibleColorWidth
            locked = true;
        }
        else {
            locked = false;
        }

        rangeDiv.css("width", Math.max(0, newWidth))
    }

    function filterByRangeSlider() {
        let min = ( $('.range-div.min').width() / ( $('#slider-background').width() - 14.0 ) ) * 100;
        let max = 100 - ( ( $('.range-div.max').width() / ( $('#slider-background').width() - 14.0 ) ) * 100 );

        filterByCat(min, max)
    }

    function rangeSliderLeave() {
        console.log("mouseup")
        $('#sliderFilterBox').off('mousemove')
        if(locked)
            overlapping = true;
        else
            overlapping = false;
    }

    function filterByCat(min, max) {
        let changed = false;

        nodes.forEach(function (n) {
            let score = n.weight * 100;
            if(score >= min && score <= max) {//inside filter range
                const oldFiltered = n.filtered
                n.filtered = false;
                if(oldFiltered !== n.filtered) changed = true
                //$("foreignObject#" + n.id).show();
                //$("foreignObject#" + n.id).removeClass("hidden");
            }
            else {
                const oldFiltered = n.filtered;
                n.filtered = true;
                if(oldFiltered !== n.filtered) changed = true
                //$("foreignObject#" + n.id).hide();
                //$("foreignObject#" + n.id).addClass("hidden");
            }
        })

        links.forEach(function (l) {
            let sourceArray = nodes.filter(function (n) {
                return n.id == l.source.id
            });
            let targetArray = nodes.filter(function (n) {
                return n.id == l.target.id
            });
            let source = sourceArray[0];
            let target = targetArray[0];
            if (!(source.filtered || target.filtered) && l.filtered) {
                l.filtered = false;
                //$("#link" + l.lid).show();
                //$("#fatLink" + l.lid + ".fatPaths").show();
                //console.log("pushed link")
            }
            else if ((source.filtered || target.filtered) && !l.filtered) {
                l.filtered = true;
                //$("#link" + l.lid).hide();
                //$("#" + l.lid + ".fatPaths").hide();
            }
        })
        if(changed) {
            drawGraph()
            simulation.alpha(0.5)
        }
    }

    function zoomFitInitial(paddingPercent, transitionDuration) {
        //debugger;
        let firstChild = getNode(initialNodes[0].id)
        let lastChild = getNode(initialNodes[initialNodes.length-1].id)

        //debugger;
        var nodeArea = document.getElementsByClassName("nodes")[0];
        var bounds = nodeArea.getBBox();
        var parent = nodeArea.parentElement.parentElement;
        var fullWidth = parent.clientWidth,
            fullHeight = parent.clientHeight;
        /*var width = bounds.width,
            height = bounds.height;*/
        var width = lastChild.x - firstChild.x + firstChild.width;
        var height = firstChild.y + 800;
        if(height == 0)
            height = 170
        var x = firstChild.x//d.y
        var y = firstChild.y - 600

        var midX = x + width / 2,
            midY = y + height / 2;
        if (width == 0 || height == 0) return; // nothing to fit
        var scale = Math.min((paddingPercent || 0.75) / Math.max(width / fullWidth, height / fullHeight), 1.1);
        var translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY];

        console.trace("zoomFit", translate, scale);
        svg
            .transition()
            .duration(transitionDuration || 0) // milliseconds
            .call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
    }

    function zoomFit(paddingPercent, transitionDuration) {
        var nodeArea = document.getElementsByClassName("nodes")[0];
        var bounds = nodeArea.getBBox();
        var parent = nodeArea.parentElement.parentElement;
        var fullWidth = parent.clientWidth,
            fullHeight = parent.clientHeight;
        var width = bounds.width,
            height = bounds.height;
        var midX = bounds.x + width / 2,
            midY = bounds.y + height / 2;
        if (width == 0 || height == 0) return; // nothing to fit
        var scale = (paddingPercent || 0.75) / Math.max(width / fullWidth, height / fullHeight);
        var translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY];

        console.trace("zoomFit", translate, scale);
        svg
            .transition()
            .duration(transitionDuration || 0) // milliseconds
            .call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
    }

    $('#focusButton').on("click", function () {
        zoomFit(0.70, 500)
        //zoomFitInitial(0, 500)
    });
    $("#releaseAllNodesButton").on("click", function () {
        node.nodes().forEach(function (d) {
            const nodee = d3.select(d).data()[0]
            releaseNode(nodee);
        })

        let pinDivs = $('.pin_div')
        pinDivs.removeClass("pinned")
        pinDivs.find('img').attr("src", pinTransparentIcon)
    })
    $('#settingsBox').on("click", function (e) {
        e.stopPropagation(); //avoid settings box to close when you interact with it

        $('.styles_box').css("display", "none");
    })
    $('.styles_box').on("click", function(e) {
        e.stopPropagation();
    })

    $('.colorPattern-picker2').on("click", function(e) {
        colorPattern = $(this).attr("name");
        var select = $('[name=colorPatterns]');
        select.val(colorPattern);

        $('#colorPattern-picker').css(getBackgroundGradient(colorPattern))
        $('#slider-background').css(getBackgroundGradient(colorPattern))

        nodes.forEach(function(d) {
            $('#'+d.id+'.foreignObject').find('#contentDiv')
                .css("background-color", calcColor(colorPattern, d))
                .css("border", getBorderColor(d, colorPattern))
        })

        $('#colorPatterns').css("display", "none");
    })

    $('#colorPattern-picker').on("click", function(e) {
        e.stopPropagation();
        if($('#colorPatterns').is(':visible')) {
            $('#colorPatterns').css("display", "none");
        }
        else {
            $('#colorPatterns').css("display", "flex");
            $('#styles_supports').css("display", "none");
            $('#styles_attacks').css("display", "none");
        }
    })

    $('#styles_attacks_button').on("click", function (e) {
        e.stopPropagation()
        if($('#styles_attacks').is(':visible')) {
            $('#styles_attacks').css("display", "none");
        }
        else {
            $('#styles_attacks').css("display", "flex");
            $('#colorPatterns').css("display", "none");
            $('#styles_supports').css("display", "none");
        }
    })
    $('#styles_supports_button').on("click", function (e) {
        e.stopPropagation()
        if($('#styles_supports').is(':visible')) {
            $('#styles_supports').css("display", "none");
        }
        else {
            $('#styles_supports').css("display", "flex");
            $('#colorPatterns').css("display", "none");
            $('#styles_attacks').css("display", "none");
        }
    })
    $('.advanced_settings_click').on("click", function () {
        if($('.advanced_settings_click i').hasClass('rotate')) {
            $('.advanced_hidden').removeClass("showing")
            $('.advanced_settings_click i').removeClass("rotate");
        }
        else {
            $('.advanced_hidden').addClass("showing")
            $('.advanced_settings_click i').addClass("rotate");
        }
    })
    $('#color-picker-attacks').on('click', function (e) {
        $('#colorAttacks').click();
    })
    $('#color-picker-supports').on('click', function () {
        $('#colorSupports').click();
    })
    $('#colorAttacks').on('input change', function () {
        $(this).prev().css('background-color', $(this).val());
        $('.path.links.attack').attr("stroke", $(this).val())
        $('#endAttack').find("path").attr("fill", $(this).val());
    })
    $('#colorSupports').on('input change', function () {
        $(this).prev().css('background-color', $(this).val());
        $('.path.links.support').attr("stroke", $(this).val())
        $('#endSupport').find("path").attr("fill", $(this).val());
    })
    $('.style_div.attack').on("click", function() {
        var head = $(this).attr("name");
        var select = $('[name=Attack]');
        select.val(head);

        changeMarkerEnds(svg, select, select.attr("name"))

        $('#styles_attacks_button').find('img').attr("src", getArrowImg(head))
        $('.style_div.attack.selected').removeClass('selected')
        $(this).addClass('selected')
    })
    $('.style_div.support').on("click", function () {
        var head = $(this).attr("name");
        var select = $('[name=Support]');
        select.val(head);

        changeMarkerEnds(svg, select, select.attr("name"))

        $('#styles_supports_button').find('img').attr("src", getArrowImg(head))
        $('.style_div.support.selected').removeClass('selected')
        $(this).addClass('selected')
    })

    /* TUTORIAL */
    function checkTutorial() {
        if(currentUser && currentUser.showTutorial) {
            let setting = currentUser.show_tutorial == "true"
            let showTutorial = $('#check_tutorial-checkbox').is(':checked')

            if (setting != showTutorial) {
                $.ajax({
                    'async': true,
                    'type': "POST",
                    'global': false,
                    "headers": {
                        "authorization": "Bearer " + currentUser.token
                    },
                    'url': baseUrl + "/changeShowTutorial/" + showTutorial + "/" + currentUser.token,
                    'contentType': "application/json",
                    'success': function () {
                        currentUser.show_tutorial = "" + showTutorial
                    }
                });
            }
        }

    }

    function openTutorial() {
        if(currentUser == undefined) {
            $('.check_tutorial').hide();
        }
        else {
            $('.check_tutorial').show();
            let checked = currentUser.show_tutorial == "true"
            $("#check_tutorial-checkbox" ).prop("checked", checked);
        }

        $("#startTutorialModal").modal('show')
    }

    let help_simulation;

    $("#startTutorial").click(function () {
        clearInterval(reloadInterval)
        simulation.stop();

        $('svg').off("click", svgClick)
        $("#startTutorialModal").modal('hide')
        checkTutorial()
        $('.help-header').hide()
        $("#tutorialModal").modal('show')

        let width = $("#tutorialModal").width()
        let height = $("#tutorialModal").height()

        let object = [];

        help_simulation = d3.forceSimulation(object)
            .force("previewLink", d3.forceLink().id(function (d) {
                return d.lid;
            }).distance(400))
            .force("charge", d3.forceManyBody().strength(-2000).distanceMax(350).distanceMin(250))
            .force("center", d3.forceCenter(width/2,height/2))
            .alphaTarget(ALPHA_TARGET).alphaMin(ALPHA_MIN)
            .on("tick", help_ticked)

        const initialScale = Math.min(0.9, Math.min(window.outerWidth/1920, window.outerHeight/1080)*1.3)
        const initialTranslate = [ 0, 0 ];
        let adjustment;
        if(initialScale < 1) {
            adjustment = 200 * (1-(initialScale-0.5)) - 100;
        }
        else {
            adjustment = -100;
        }

        let zoom = d3
            .zoom()
            .scaleExtent([1 / 4, 4])
            .on('zoom', function (event) {
                help_zoom_actions(event);
            })


        let svg = d3.select('#help-svg')


        let g = svg.append("g")
            .attr("id", "root_tutorial")
            .attr("class", "everything")
            .attr(
                'transform',
                `translate(0, 0)scale(${initialScale})`
            );

        svg.on('mousemove', function (e) {
            svgMouseMove( e, $('#root_tutorial'), 0)
        });

        let nodeTemplate0 = {
            "id": 0,
            "positiveVotes": 10,
            "negativeVotes": 1,
            "weight": 0.999,
            "text": "I'm ready to debate.",
            "typeOfVote": "",
            "width": 250,
            "height": 170,
            "offset": 0
        }
        let nodeTemplate1 = {
            "id": 1,
            "positiveVotes": 0,
            "negativeVotes": 0,
            "weight": 0,
            "text": "I don't like debating.",
            "typeOfVote": "",
            "width": 250,
            "height": 170,
            "offset": 0
        }

        object.push(nodeTemplate0)

        let object0 = [];

        let linkTemplateAttack = {
            "lid": 0,
            "source": nodeTemplate1,
            "target": {},
            "type": "ATTACKS",
            "stroke_width": 5.5
        }

        object0.push(linkTemplateAttack)

        let previewLink = g.append("g")
            .attr("class", "previewLink")
            .selectAll("path")

        let fatLink = g.append("g")
            .attr("class", "fatlinks")
            .selectAll("path")

        let node = g
            .append("g")
            .attr("class", "nodes")
            .selectAll("foreignObject")

        let zoomed = false
        function help_zoom_actions(event) {
            if(!zoomed) {
                zoomed = true;
                $('.zoom_tooltip').removeClass("expandingAnimation")
                $('.ok.center.zoom').fadeIn(FADE_IN)
            }

            const transformation = event.transform
            let {x, y, k} = transformation;
            x += initialTranslate[0];
            y += initialTranslate[1];
            k *= initialScale;


            g.attr("transform", `translate(${x}, ${y})scale(${k})`)
        }

        $('.closefirstmodal').on("click", function() {
            $("#help-addArgument").modal('hide');
            $('.help-header').fadeIn(FADE_IN)
            $('.help-addButton').on("click", addButtonClick)
        })


        //draw nodes
        function drawHelpNodes(node) {
            let res = node.enter().append("foreignObject")
                .attr("width", 250)
                .attr("height", 170)
                .attr("class", "help-foreignObjectNew")
                .attr("id", function (d) {
                    return d.id;
                })

            let contentDiv = res.append("xhtml:div")
                .attr("id", "contentDiv")
                .attr("class", "contentDiv")
                .attr("name", function (d) {
                    return d.id;
                })
                .style("background-color", d => calcColor(colorPattern, d));

            contentDiv.append("xhtml:div")
                .attr("class", "pin_div")
                .append("img")
                .attr("id", "pinDivImg")
                .attr("src", pinTransparentIcon)

            contentDiv.append(function (d) {
                let element = document.createElement("div");
                element.className = "nodeText";
                let span = document.createElement("span")
                span.className = "trunkatee"
                span.innerHTML = d.text;

                element.appendChild(span)

                return element;
            });

            res.append("xhtml:div")
                .attr("id", "likeDiv")
                .attr("class", "likeDiv")
                .style("font", "20px 'Helvetica'")
                .html(function (d) {
                    return getLikeContent(d);
                })
            
            d3.selectAll('.help-foreignObjectNew').attr("class", "foreignObject help-foreign")

            return res;
        }

        help_update()

        setTimeout(function () {
            $('.help-header').fadeIn(FADE_IN)
        }, 500)

        // disableDrag()
         //enableDrag()
        // svg.call(zoom)
        // $('#help-focusButton').on("click", focusButtonClick);

        /* event handlers */
        $('.help-addButton').on("click", addButtonClick)


        /*functions*/

        function boxingForce() {
            const radius = 500;

            object0.forEach(function(node) {
                // Of the positions exceed the box, set them to the boundary position.
                // You may want to include your nodes width to not overlap with the box.
                node.x = Math.max(-radius, Math.min(radius, node.x));
                node.y = Math.max(-radius, Math.min(radius, node.y));
            })
        }

        function disableDrag() {
            node.call(d3.drag()
                .on("start", null)
                .on("drag", null)
                .on("end", null));
        }

        function enableDrag() {
            node.call(d3.drag()
                .on("start", help_dragstarted)
                .on("drag", help_dragged)
                .on("end", help_dragended))
        }

        /*   function addButtonClick() {
        $(this).off("click");

        $('.help-header').fadeOut(FADE_OUT, function () {
            object.push(nodeTemplate1)
            help_update()


            var likeTooltip = $('<div>')
                .attr("class", 'help-tooltip likeTooltip tooltipArrow noHover')
                .append($('<span>')
                    .html("Vote on arguments to change their strength"));


            $('#1.likeNode').append(likeTooltip)
            likeTooltip.hide()


            $('.likeTooltip').fadeIn('300')
            // change opacity of all argument buttons except the one like button you want the user to click
            $('#1.dislikeNode, #negVotes1, #0.help-foreign .likeDiv, #1.help-foreign .relationBtn').animate({
                opacity: 0.5
            }, FADE_OUT)



            $('.likeTooltip').on("click mouseenter mouseover", function (e) {
                e.stopPropagation()
                e.stopImmediatePropagation()
            })

            // event handler on that like button
            $('#1.likeNode').on("click", likeNodeClick)
        })
    }
*/
        function addButtonClick() {
            $(this).off("click");

            $('.help-header').fadeOut(FADE_OUT)
            $("#help-addArgument").modal('show');
            $('.modal-backdrop.fade.in').css({
                'z-index': '1050'
            })

            $('#help-submitArg').on("click", function () {
                $(this).off("click")
                var content = $("#help-divTextarea").val().trim();

                if(content === "") {
                    $("#help-divTextarea").val("");
                    if ($('#emptyArgument').data("active")) {
                    }
                    else {
                        $('#help-emptyArgument').fadeIn(FADE_IN).data("active", true);
                        $("#help-divTextarea").addClass("error");
                        setTimeout(function() {
                            $('#help-emptyArgument').fadeOut(FADE_OUT).data("active", false);
                            $("#help-divTextarea").removeClass("error");
                        }, 3000);
                    }

                    return;
                }

                nodeTemplate1.text = content;


                $("#help-addArgument").modal('hide');
                $('.modal-backdrop.fade.in').css({
                    'z-index': '1040'
                })

                setTimeout(function () {
                    object.push(nodeTemplate1)
                    help_update()
                    setTimeout(function() {
                        var likeTooltip = $('<div>')
                            .attr("class", 'help-tooltip likeTooltip tooltipArrow noHover rotatingAnimation')
                            .append($('<span>')
                                .html("Vote on arguments to change their strength"));


                        $('#1.likeNode').append(likeTooltip)
                        likeTooltip.hide()


                        $('.likeTooltip').fadeIn('300')
                        // change opacity of all argument buttons except the one like button you want the user to click
                        $('#1.dislikeNode, #negVotes1, #0.help-foreign .likeDiv, #1.help-foreign .relationBtn').animate({
                            opacity: 0.5
                        }, FADE_OUT)



                        $('.likeTooltip').on("click mouseenter mouseover", function (e) {
                            e.stopPropagation()
                            e.stopImmediatePropagation()
                        })

                        // event handler on that like button
                        $('#1.likeNode').on("click", likeNodeClick)
                    },500)

                }, 500)
            })
        }

        function likeNodeClick() {
            $(this).off("click")
            $('.likeTooltip').off("click mouseenter mouseover")

            $(this).find('i').attr("class", "fas fa-thumbs-up")
            $('#posVotes1').html("1")


            $('#1.dislikeNode, #negVotes1, #0.help-foreign .likeDiv, #1.help-foreign .relationBtn').animate({
                opacity: 1
            }, FADE_IN)

            nodeTemplate1.positiveVotes = 1;
            nodeTemplate1.weight = 1;
            $('#1.help-foreign').find('.contentDiv').css("background-color", calcColor(colorPattern, nodeTemplate1))

            $('.likeTooltip').fadeOut('FADE_OUT', function () {
                var explain_likeTooltip = $('<div>')
                    .attr("class", 'help-tooltip explain_likeTooltip noHover')
                    .append($('<span>')
                        .html("Arguments\' colors change based on their strength. <span class='darker'>Darker</span> arguments are <span class='darker'>stronger</span> while <span class='lighter'>lighter</span> arguments are <span class='lighter'>weaker</span>"));

                var got_it_div = $('<div>')
                    .attr("class", "got-it")
                    .append($('<span>')
                        .html("Got it!"))

                $('#1.help-foreign').append(explain_likeTooltip)

                explain_likeTooltip.hide()

                explain_likeTooltip.fadeIn(FADE_IN, function () {
                    $('#1.help-foreign').append(got_it_div);
                    got_it_div.hide()

                    setTimeout(function () {
                        $('.got-it').on("mouseenter mouseover", function(e) {
                            e.stopPropagation()
                            e.stopImmediatePropagation()
                            $(this).parent().addClass("help")
                        })

                        $('.got-it').on("mouseleave mouseenter", function(e) {
                            e.stopPropagation()
                            e.stopImmediatePropagation()
                            $(this).parent().removeClass("help")
                        })
                        got_it_div.fadeIn('100', function () {
                            $('.got-it').on("click", explain_click);
                        })
                    }, 700)
                })
            })
        }

        function explain_click() {
            $(this).off("click")
            $('.got-it').off("mouseenter mouseover mouseleave mouseenter")
            $('.explain_likeTooltip').fadeOut(FADE_OUT)

            $(this).fadeOut(FADE_OUT, function () {
                $('#1.dislikeNode, #negVotes1, #1.likeNode, #posVotes1, #0.help-foreign .likeDiv').animate({
                    opacity: 0.5
                }, FADE_OUT)

                var add_attack_tooltip = $('<div>')
                    .attr("class", 'help-tooltip add_attack_tooltip tooltipArrow noHover rotatingAnimation')
                    .append($('<span>')
                        .html("Press <i id=\"plus_icon\" class=\"fas fa-plus\"></i> to add an attack"));

                $('#1.help-foreign').append(add_attack_tooltip)
                add_attack_tooltip.hide()
                add_attack_tooltip.fadeIn(FADE_IN)

            });

            /* event handlers */
            $('#1.help-foreign .relationBtn').on("click", relation_click)
        }

        function relation_click(e) {
            e.stopPropagation()
            e.stopImmediatePropagation()
            $(this).off("click")

            $('.add_attack_tooltip').fadeOut(FADE_OUT)

            let custom_menu = $("#help_argument_options").clone()
            $('#1.help-foreign').append(custom_menu)

            custom_menu.finish().toggle(100);

            custom_menu.on("mouseover mouseenter", function (e) {
                e.stopPropagation()
                e.stopImmediatePropagation()
                $(this).parent().addClass("help")
            })

            custom_menu.on("mouseleave mouseenter", function(e) {
                e.stopPropagation()
                e.stopImmediatePropagation()
                $(this).parent().removeClass("help")
            })

            $('.help-add-attack').on("click", add_attack_click)

        }

        function add_attack_click() {
            $(this).off("click")
            fixNode(nodeTemplate0)
            fixNode(nodeTemplate1)


            $('#0.help-foreign').on('mouseenter', function () {
                addLinkOverNode = nodeTemplate0;
            })
            $('#0.help-foreign').on('mouseleave', function () {
                addLinkOverNode = undefined;
            })

            $('.custom-menu').parent().removeClass("help")
            $('.custom-menu').off('mouseover mouseenter mouseleave mouseenter')
            $('.custom-menu').hide(100);

            var help_attackTooltip = $('<div>')
                .attr("class", 'help-tooltip help_attackTooltip tooltipArrow noHover rotatingAnimation')
                .append($('<span>')
                    .html("Click on an argument to attack it"));

            $('#0.help-foreign').append(help_attackTooltip)
            help_attackTooltip.hide()
            help_attackTooltip.fadeIn(FADE_IN)

            help_simulation.alpha(1).restart();
            help_simulation.alphaTarget(ALPHA_MIN + 0.001);
            isPreview = true;
            previewLink.attr("visibility", "visible")

            $('#0.help-foreign').on("click", attack_node)
        }

        var isConnected = false;
        function attack_node() {
            $(this).off("click")
            $('#0.help-foreign').off('mouseenter')
            $('#0.help-foreign').off('mouseleave')

            releaseNode(nodeTemplate0)
            releaseNode(nodeTemplate1)


            isPreview = false;
            previewLink.attr("visibility", "visible")
            linkTemplateAttack.target = addLinkOverNode
            addLinkOverNode = undefined;

            $('#1.dislikeNode, #negVotes1, #1.likeNode, #posVotes1, #0.help-foreign .likeDiv').animate({
                opacity: 1
            }, FADE_IN)
            $('.help_attackTooltip').fadeOut(FADE_OUT)

            isConnected = true;
            zoomfit(0.6, 500)
            help_simulation.alphaTarget(ALPHA_TARGET);
            help_update()

            var explain_attackTooltip = $('<div>')
                .attr("class", 'help-tooltip explain_attackTooltip noHover')
                .append($('<span>')
                    .html("Attacked arguments will lose strength. Supported arguments will get stronger."));

            var ok_div = $('<div>')
                .attr("class", "ok attacked_ok")
                .append($('<span>')
                    .html("Got it!"))

            $('#0.help-foreign').append(explain_attackTooltip)
            explain_attackTooltip.hide()

            nodeTemplate0.weight = 0.65;
            $('#0.help-foreign').find('.contentDiv').css("background-color", calcColor(colorPattern, nodeTemplate0))

            explain_attackTooltip.fadeIn(FADE_IN, function () {
                $('#0.help-foreign').append(ok_div)
                ok_div.hide()

                setTimeout(function () {
                    $('.ok').on("mouseenter mouseover", function (e) {
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                        $(this).parent().addClass("help")
                    })

                    $('.ok').on("mouseleave mouseout", function (e) {
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                        $(this).parent().removeClass("help")
                    })
                    ok_div.fadeIn('100', function () {
                        $('.ok').on("click", like_relationTooltip);
                    })
                }, 700)
            })
        }

        function like_relationTooltip(e) {
            $(this).off("click");
            $('.ok').on("mouseenter mouseover mouseleave mouseout")
            $('#help-svg .fatPaths').on("mouseenter", help_linkMouseEnter)
            $('#help-svg .fatPaths').on("mouseleave", linkMouseLeave)
            $('#help-svg .fatPaths').on("click", help_linkClick)

            $('.explain_attackTooltip').fadeOut(FADE_OUT)
            $(this).fadeOut(FADE_OUT, function () {
                var position = $(this).position()
                var like_relation_tooltip = $('<div>')
                    .attr("class", 'help-tooltip like_relation_tooltip noHover rotatingAnimation')
                    .append($('<span>')
                        .html("Place your mouse on top of an attack/support to check its votes. Click to vote on it."));

                $('#0.help-foreign').append(like_relation_tooltip)
                like_relation_tooltip.hide()
                like_relation_tooltip.fadeIn(FADE_IN)
            })
        }


        function help_linkMouseEnter(event) {
            svg.selectAll(".svg-tooltip").remove();

            $('#previewAttack').addClass("hovered links attack");
            d3.select('#previewAttack').raise()

            var offset = $('#help-svg').offset()
            var x = event.pageX- offset.left;
            var y = event.pageY- offset.top;

            var fo = svg.append("foreignObject")
                .attr("width", 700)
                .attr("height", 200)
                .attr("x", x)
                .attr("y", y)
                .attr("class", "svg-tooltip")
                .attr("id", "showLinkScore")
            var linkScoreDiv = fo.append("xhtml:div")
                .attr("id", "scoreAttackDiv")
                .html("Score: <b>100/100</b><p>Positive: <b>1</b> Negative: <b>0</b></p><p><i> Click on the arrow to vote</i></p>")
        }

        function help_linkClick(event) {
            $('#help-svg .fatPaths').off("mouseenter", help_linkMouseEnter)
            $('#help-svg .fatPaths').off("mouseleave", linkMouseLeave)
            $('#help-svg .fatPaths').off("click", help_linkClick)

            svg.selectAll(".svg-tooltip").remove();


            var offset = $('#help-svg').offset()
            var x = event.pageX- offset.left;
            var y = event.pageY- offset.top;

            var fo = svg.append("foreignObject")
                .attr("width", 700)
                .attr("height", 200)
                .attr("x", x)
                .attr("y", y)
                .attr("class", "svg-tooltip")
            var linkLikeDiv = fo.append("xhtml:div")
                .attr("id", "likeAttackDiv")
                .style("font", "20px 'Helvetica'")
                .html(function () {
                    return "<button id='0' class='likeFatAttack' style='font-size:24px'><i class='fas fa-thumbs-up'></i></button>"
                        + "<span id='help_like_attack'>1</span><button id ='0' class='dislikeFatAttack' style='font-size:24px'><i class='far fa-thumbs-down'></i></button><span id='help_dislike_attack'>0</span>";

                })

            $('#help-svg .likeFatAttack, #help-svg .fas.fa-thumb-up, #help_like_attack').css("opacity", "0.5")

            $('#1.help-foreign .likeDiv, #0.help-foreign .likeDiv').animate({
                opacity: 0.5
            }, FADE_IN)

            $('#help-svg .dislikeFatAttack').on("click", help_dislike_attack)

        }

        function help_dislike_attack() {
            $(this).off("click")
            $('.dislikeFatAttack').find("i").removeClass().addClass("fas fa-thumbs-down")
            $('#help_dislike_attack').html("1")
            $('#help-svg .likeFatAttack').find("i").removeClass().addClass("far fa-thumbs-up")
            $('#help_like_attack').html(0)
            $('#1.help-foreign .likeDiv, #0.help-foreign .likeDiv').animate({
                opacity: 1
            }, FADE_IN)

            object0[0].stroke_width = 2

            console.log("objecttttttttttttttt")
            console.log(object0[0])
            setTimeout(function () {
                $('.svg-tooltip').fadeOut(FADE_OUT, function() {
                    $('.svg-tooltip').remove()
                })

                $(".hovered").removeClass("hovered");
                $('#previewAttack').attr("stroke-width", 2)
                nodeTemplate0.weight = 0.95;
                $('#0.help-foreign .contentDiv').css("background-color", calcColor(colorPattern, nodeTemplate0))


                $('.like_relation_tooltip').fadeOut(FADE_OUT, function () {
                    var explain_attack_weight_tooltip = $('<div>')
                        .attr("class", 'help-tooltip explain_attack_weight_tooltip noHover')
                        .append($('<span>')
                            .html("Better rated attacks (or supports) have a stronger influence on arguments"));

                    var ok_div = $('<div>')
                        .attr("class", "ok center")
                        .append($('<span>')
                            .html("Got it!"))

                    explain_attack_weight_tooltip.append(ok_div)
                    ok_div.hide()
                    $('#0.help-foreign').append(explain_attack_weight_tooltip)
                    explain_attack_weight_tooltip.hide()
                    explain_attack_weight_tooltip.fadeIn(FADE_IN, function () {
                        ok_div.fadeIn(FADE_IN)
                        ok_div.on("click", attackWeightClick)
                        explain_attack_weight_tooltip.on("mouseenter mouseover", function (e) {
                            e.stopPropagation()
                            e.stopImmediatePropagation()
                            $(this).parent().addClass("help")
                        })

                        explain_attack_weight_tooltip.on("mouseleave mouseout", function (e) {
                            e.stopPropagation()
                            e.stopImmediatePropagation()
                            $(this).parent().removeClass("help")
                        })
                    })

                })
            }, 100)
        }

        function attackWeightClick() {
            $(this).off("click")
            $('.explain_attack_weight_tooltip').off("mouseenter mouseover mouseleave mouseout")
            $('.explain_attack_weight_tooltip').fadeOut(FADE_OUT)

            $(this).fadeOut(FADE_OUT, function () {
                var drag_tooltip = $('<div>')
                    .attr("class", 'help-tooltip drag_tooltip noHover expandingAnimation')
                    .append($('<span>')
                        .html("Press and hold an argument to drag it"));

                $('.tutorialModalContent').append(drag_tooltip)
                drag_tooltip.hide()
                drag_tooltip.fadeIn(FADE_IN)
                enableDrag()
            })
        }



        function explainDrag() {
            console.log("blablalbalbablabla")
            $('.drag_tooltip').fadeOut(FADE_OUT, function () {
                var explain_drag_tooltip = $('<div>')
                    .attr("class", 'help-tooltip explain_drag_tooltip noHover')
                    .append($('<span>')
                        .html("Arguments will automatically float around the debate to adjust to drags"));
                var ok_div = $('<div>')
                    .attr("class", "ok center explainDrag")
                    .append($('<span>')
                        .html("Got it!"))

                explain_drag_tooltip.append(ok_div)
                ok_div.hide()
                $('.tutorialModalContent').append(explain_drag_tooltip)
                explain_drag_tooltip.hide()

                ok_div.fadeIn(FADE_IN)
                explain_drag_tooltip.fadeIn(FADE_IN, function () {
                    ok_div.on("click", explainDragOk)
                    explain_drag_tooltip.on("mouseenter mouseover", function (e) {
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                        $(this).parent().addClass("help")
                    })

                    explain_drag_tooltip.on("mouseleave mouseout", function (e) {
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                        $(this).parent().removeClass("help")
                    })
                })

            })
        }

        function explainDragOk() {
            $(this).off("click")
            $('.explain_drag_tooltip').off("mouseenter mouseover mouseleave mouseout")
            $('.explain_drag_tooltip').fadeOut(FADE_OUT)
            $(this).fadeOut(FADE_OUT, function () {

                var zoom_tooltip = $('<div>')
                    .attr("class", 'help-tooltip zoom_tooltip noHover expandingAnimation')
                    .append($('<span>')
                        .html("Adjust the view by dragging the background. Use the mouse wheel to zoom in and out."));

                var ok_div = $('<div>')
                    .attr("class", "ok center zoom")
                    .append($('<span>')
                        .html("Got it!"))

                zoom_tooltip.append(ok_div)
                ok_div.hide()

                zoom_tooltip.hide()
                $('.tutorialModalContent').append(zoom_tooltip)
                zoom_tooltip.fadeIn(FADE_IN)

                ok_div.on("click", zoomOkClick)
                zoom_tooltip.on("mouseenter mouseover", function (e) {
                    e.stopPropagation()
                    e.stopImmediatePropagation()
                    $(this).parent().addClass("help")
                })

                zoom_tooltip.on("mouseleave mouseout", function (e) {
                    e.stopPropagation()
                    e.stopImmediatePropagation()
                    $(this).parent().removeClass("help")
                })

                svg.call(zoom)
                //reset zoom from other tutorial attempts
                zoomed = true;
                zoom.transform(svg, d3.zoomIdentity);
                zoomed = false;
            })
        }


        function zoomOkClick() {
            $(this).off("click")
            $('.zoom_tooltip').off("mouseenter mouseover mouseleave mouseout")
            $('.zoom_tooltip').fadeOut(FADE_OUT)
            $(this).fadeOut(FADE_OUT, function () {
                var fitToScreen_tooltip = $('<div>')
                    .attr("class", 'help-tooltip fitToScreen_tooltip tooltipArrow noHover rotatingAnimation')
                    .append($('<span>')
                        .html("Press the 'Fit to screen' button to bring all the arguments back to your screen."));

                fitToScreen_tooltip.hide()
                $('.tutorialModalContent').append(fitToScreen_tooltip)
                fitToScreen_tooltip.fadeIn(FADE_IN)

                $('#help-addButton, #help-releaseAllNodesButton').animate({
                    opacity: 0.5
                }, FADE_OUT)

                $('#help-focusButton').on("click", focusButtonClick);
            })
        }


        var pinned = false;
        function focusButtonClick() {
            $(this).off("click")

            zoomfit(0.5, 500)


            $('#help-addButton, #help-releaseAllNodesButton').animate({
                opacity: 1
            }, FADE_OUT)

            $('.fitToScreen_tooltip').fadeOut(FADE_OUT, function () {
                var pin_tooltip = $('<div>')
                    .attr("class", 'help-tooltip pin_tooltip tooltipArrow noHover expandingAnimation')
                    .append($('<span>')
                        .html(`Press <img src=${pinTransparentIcon}> to pin an argument.` +
                            ` Pinned arguments won't float around the debate.`));

                var ok_div = $('<div>')
                    .attr("class", "ok center pin")
                    .append($('<span>')
                        .html("Got it!"))

                pin_tooltip.append(ok_div)
                ok_div.hide()
                pin_tooltip.hide()

                $('#0.help-foreign').append(pin_tooltip)
                pin_tooltip.fadeIn(FADE_IN)

                $('.help-foreign.likeDiv').animate({
                    opacity: 0.5
                }, FADE_OUT)

                help_isDragged = true;
                pinned = true;

                ok_div.on("click", pinOkClick)
                pin_tooltip.on("mouseenter mouseover", function (e) {
                    e.stopPropagation()
                    e.stopImmediatePropagation()
                    $(this).parent().addClass("help")
                })

                pin_tooltip.on("mouseleave mouseout", function (e) {
                    e.stopPropagation()
                    e.stopImmediatePropagation()
                    $(this).parent().removeClass("help")
                })


                $('#tutorialModal .pin_div').on("click", pinDivClick)
            })

            $(this).on("click", focusButtonClickNormal)
        }

        function focusButtonClickNormal() {
            zoomfit(0.5, 500)
        }

        function fixOrRelease(node) {
            if(node.fixed == true) { //already fixed, unfix
                releaseNode(node)
            }
            else { //not fixed yet, fix
                fixNode(node)
            }
        }

        function fixNode(node) {
            node.fixed = true;
            node.fx = node.x;
            node.fy = node.y;
        }

        function releaseNode(node) {
            node.fixed = false;
            node.fx = null;
            node.fy = null;
        }

        function pinDivClick(e) {
            e.stopPropagation()

            $('.help-foreign.likeDiv').animate({
                opacity: 1
            }, FADE_IN)

            if($(this).parent().attr("name") == 0)
                fixOrRelease(nodeTemplate0)
            else
                fixOrRelease(nodeTemplate1)

            var div = $(this);
            if(div.hasClass("pinned")) {
                $(this).find('img').attr("src", pinTransparentIcon)
                $(this).removeClass("pinned");
            }
            else {
                $(this).find('img').attr("src", pinBlackIcon)
                $(this).addClass("pinned");
            }

            $('.ok.center.pin').fadeIn(FADE_IN)
        }

        function pinOkClick() {
            $(this).off("click")

            $('.pin_tooltip').fadeOut(FADE_OUT)
            $(this).fadeOut(FADE_OUT, function () {
                var unpinAll_tooltip = $('<div>')
                    .attr("class", 'help-tooltip unpinAll_tooltip tooltipArrow noHover rotatingAnimation')
                    .append($('<span>')
                        .html("Press the 'Unpin all nodes' button to unpin all arguments"));

                unpinAll_tooltip.hide()
                $('.tutorialModalContent').append(unpinAll_tooltip)
                unpinAll_tooltip.fadeIn(FADE_IN)

                $('#help-addButton, #help-focusButton').animate({
                    opacity: 0.5
                }, FADE_OUT)

                $('#help-releaseAllNodesButton').on("click", releaseButtonClick);
            })
        }

        function releaseButtonClick() {
            $('#help-addButton, #help-focusButton').animate({
                opacity: 1
            }, FADE_IN)

            releaseNode(nodeTemplate0)
            releaseNode(nodeTemplate1)

            help_simulation.alpha(1).restart()

            $('.pinned').removeClass("pinned").find('img').attr("src", pinTransparentIcon)

            $('.unpinAll_tooltip').fadeOut(FADE_OUT)
            $('#help-addButton, #help-focusButton, .likeDiv').animate({
                opacity: 1
            }, FADE_IN)
        }

        function zoomfit(paddingPercent, transitionDuration) {
            var nodeArea = document.getElementsByClassName("nodes")[0];
            var bounds = nodeArea.getBBox();
            var parent = nodeArea.parentElement.parentElement;
            var fullWidth = parent.clientWidth;
            var fullHeight = parent.clientHeight;
            var width = bounds.width;
            var height = bounds.height;
            var midX = bounds.x + width / 2;
            var midY = bounds.y + height / 2;
            if (width == 0 || height == 0) return;
            var scale = (paddingPercent || 0.75) / Math.max(width / fullWidth, height / fullHeight);
            var translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY];
            svg
                .transition()
                .duration(transitionDuration || 0)
                .call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
        }

        function help_update() {
            node = node.data(object, function(d) {
                return d.id;
            })
            node.exit().remove();
            let newNode = drawHelpNodes(node);
            node = node.merge(newNode)

            help_simulation.nodes(object)

            previewLink = svg.selectAll("g.previewLink")
                .selectAll("path")
                .data(object0);

            previewLink.exit().remove();
            let newPreviewLink = drawPreviewLink(previewLink)
            previewLink = previewLink.merge(newPreviewLink)

            fatLink = fatLink.data(object0)
            fatLink.exit().remove();
            let newFatLink = drawFatLinks(fatLink);
            fatLink = fatLink.merge(newFatLink);

            if(isConnected) {
                help_simulation.force("previewLink")
                    .links(object0)
            }

            help_simulation.force('collision', d3.forceCollide().radius(function(d) {
                return d.height-25;
            }))


            $(".trunkatee").trunk8({
                fill: trunk8_settings.fill,
                lines: trunk8_settings.lines
            });

            help_simulation.restart();
        }

        var isPreview = false;
        let heightDelta = 0;
        function help_ticked() {
            if(isPreview) {
                previewLink.attr("d", function () {
                    var x1 = movetoX(linkTemplateAttack),
                        y1 = movetoY(linkTemplateAttack);

                    var x2, y2;
                    if(addLinkOverNode == undefined) {
                        x2 = posX;
                        y2 = posY;
                    }
                    else {
                        linkTemplateAttack.target = addLinkOverNode
                        x2 = linetoX(linkTemplateAttack);
                        y2 = linetoY(linkTemplateAttack);
                    }

                    // Defaults for normal edge.
                    var drx = 0,
                        dry = 0,
                        xRotation = 0, // degrees
                        largeArc = 0, // 1 or 0
                        sweep = 1; // 1 or 0

                    return "M" + x1 + "," + y1 + "A" + drx + "," + dry + " " + xRotation + "," + largeArc + "," + sweep + " " + x2 + "," + y2;
                })

            }
            else {

                previewLink.attr("d", get_d_attribute);
                fatLink.attr("d", get_d_attribute);

                node.attr("transform", function (d) {
                    d.x = Math.max(0, Math.min(width - d.offset, d.x));
                    //d.y = Math.max(d.height, Math.min(height - heightDelta - d.height, d.y))
                    return "translate(" + d.x + "," + d.y + ")";
                });
            }

        }

        let help_isDragged = false;
        let isExplained = false;
        function help_dragstarted(event, d) {
            //isPreview = true;

            help_isDragged = false;

            console.log("dragstarted")
            console.log(d)

            //hidePreview(d);
            if (!event.active) help_simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        function help_dragged(event, d) {
            //hidePreview(d);

            help_simulation.alphaTarget(0.3).restart()

            var changeX = Math.abs(d.fx -  event.x);
            var changeY = Math.abs(d.fy - event.y);

            if(changeX + changeY > 2) {
                if(!help_isDragged)
                    if(pinned) {
                        $('ok.center.pin').fadeIn(FADE_IN)
                    }
                    else if(!isExplained){
                        isExplained = true;
                        explainDrag();
                    }

                help_isDragged = true;
            }

            d.fx = event.x;
            d.fy = event.y;
        }

        function help_dragended(event, d) {
            //isPreview = false;

            if(/* isDragged || */ d.fixed) { //fixing node after drag is commented out. Also, the isDragged acts like a tolerance so that the node isn't fixed when just cliked
                fixNode(d);
            }
            else {
                d.fx = null;
                d.fy = null;
            }


            console.log("dragended")


            if (!event.active) help_simulation.alphaTarget(0)
            //d.fx = null;
            //d.fy = null;
        }
    })

    return {
         destroy: () => {
             // remove event handlers so they don't get called twice when
             // opening new debates
             $("body").off("click", ".file-name2.uploaded", getFile);
             $("#svgId").off("click", ".nodeImageDiv", openFileViewModal)
             $("body").off("click", ".closeButtonUpload", closeImageModal)
             $('body').off("change", "#file-input", fileInputChange)
             $('body').off("click", "#submitArg", submitArg)
             $("#svgId").off("click", '.pin_div', pinDivClick)
             $("#svgId").off("contextmenu", ".foreignObject", nodeContextMenu);
             $("#svgId").off("click", ".trunkateFill", seeMoreClick);
             $("#svgId").off("mouseenter", ".foreignObject.clicked", scrollOnNode);
             $("#svgId").off("mouseleave", ".foreignObject", scrollOnNode_end);
             $("#svgId").off("mouseenter", ".foreignObject", nodeMouseEnter);
             $("#svgId").off("mouseleave", ".foreignObject", nodeMouseLeave);
             $("#svgId").off("click", ".fatPaths", linkClick);
             $("#svgId").off("mouseenter", ".fatPaths", linkMouseEnter);
             $("#svgId").off("mouseleave", ".fatPaths", linkMouseLeave);
             $("#root").off("click", "#argument_options li", chooseLinkType);
             $("#svgId").off("click", ".likeNode", clickLikeNode);
             $("#svgId").off("click", ".dislikeNode", clickDislikeNode);
             $("#svgId").off("click", ".likeFatAttack", clickLikeAttack);
             $("#svgId").off("click", ".dislikeFatAttack", clickDislikeAttack);
             $('body').off("click", ".dot:not(.selected)", imageViewDotClick)
             $('#sidebarCollapse').off('click', sidebarCollapseClick);

             if(currentUser && currentUser.token != undefined) {
                 let color_pattern = currentUser.color_pattern
                 let attack_color = currentUser.attack_color
                 let support_color = currentUser.support_color
                 let attack_head = currentUser.attack_head
                 let support_head = currentUser.support_head
                 let semantic = currentUser.semantic

                 dispatch(changeUserDebateSettings({
                     color_pattern: color_pattern,
                     attack_color: attack_color,
                     support_color: support_color,
                     attack_head: attack_head,
                     support_head: support_head,
                     semantic: semantic
                 }))
             }

             clearInterval(reloadInterval)
             simulation.stop();
         },
         nodes: () => {
             return svg.node();
         }
    }
}