/* DEBATES */
export const RESET_HOME_COUNT = "RESET_HOME_COUNT"
export const RESET_LOADED = "RESET_LOADED"
export const FETCH_DEBATES = "FETCH_DEBATES"
export const FETCH_HOME_NEWEST_DEBATES = "FETCH_HOME_NEWEST_DEBATES"
export const FETCH_HOME_SUBSCRIBED_DEBATES = "FETCH_HOME_SUBSCRIBED_DEBATES"
export const FETCH_HOME_PARTIN_DEBATES = "FETCH_HOME_PARTIN_DEBATES"
export const FETCH_HOME_MY_DEBATES = "FETCH_HOME_MY_DEBATES"
export const CREATE_DEBATE = "CREATE_DEBATE"
export const CHANGE_ACTIVE = "CHANGE_ACTIVE"
export const RESET_ITEMS = "RESET_ITEMS"

/* FILTERS */
export const CHANGE_LIMIT = "CHANGE_LIMIT"
export const INCREASE_SKIP = "INCREASE_SKIP"
export const CHANGE_SKIP = "CHANGE_SKIP"
export const CHANGE_TYPE = "CHANGE_TYPE"
export const CHANGE_DATE = "CHANGE_DATE"
export const CHANGE_SORT = "CHANGE_SORT"
export const CHANGE_INPUT = "CHANGE_INPUT"
export const CHANGE_MODE = "CHANGE_MODE"
export const CHANGE_TAGS = "CHANGE_TAGS"
export const CHANGE_DEBATE_TYPE = "CHANGE_DEBATE_TYPE"
export const SET_ONLY_SORT = "SET_ONLY_SORT"
export const SET_ONLY_INPUT = "SET_ONLY_INPUT"
export const RESET_FILTERS = "RESET_FILTERS"
export const LOAD_POPULAR_TAGS = "LOAD_POPULAR_TAGS"

/* SUCCESS HANDLING */
export const CREATE_DEBATE_SUCCESS = "CREATE_DEBATE_SUCCESS"
export const HIDE_CREATE_DEBATE_SUCCESS = "HIDE_CREATE_DEBATE_SUCCESS"
export const SUBSCRIBE_DEBATE_SUCCESS = "SUBSCRIBE_DEBATE_SUCCESS"
export const HIDE_SUBSCRIBE_DEBATE_SUCCESS = "HIDE_SUBSCRIBE_DEBATE_SUCCESS"
export const UNSUBSCRIBE_DEBATE_SUCCESS = "UNSUBSCRIBE_DEBATE_SUCCESS"
export const HIDE_UNSUBSCRIBE_DEBATE_SUCCESS = "HIDE_UNSUBSCRIBE_DEBATE_SUCCESS"

/* GOOGLE OAUTH */
export const GOOGLE_OAUTH2_SUCCESS = "GOOGLE_OAUTH2_SUCCESS"
export const GOOGLE_OAUTH2_FAIL = "GOOGLE_OAUTH2_FAIL"
export const GOOGLE_OAUTH2_LOADED = "GOOGLE_OAUTH2_LOADED"
export const GOOGLE_OAUTH2_RESET = "GOOGLE_OAUTH2_RESET"
export const CHANGE_USER_SETTINGS = "CHANGE_USER_SETTINGS"
export const CHANGE_USER_DEBATE_SETTINGS = "CHANGE_USER_DEBATE_SETTINGS"
