import Moment from 'moment'
import React from "react";
import $ from "jquery";
import chroma from "chroma-js"
import arrow_og from "./images/arrow_heads/arrow_og.png";
import arrow_roundArrow from "./images/arrow_heads/arrow_roundArrow.png";
import arrow_abs_square from "./images/arrow_heads/arrow_abs_square.png";
import arrow_square_hole from "./images/arrow_heads/arrow_square_hole.png";
import arrow_circle from "./images/arrow_heads/arrow_circle.png";
import arrow_square from "./images/arrow_heads/arrow_square.png";
import arrow_hexagon from "./images/arrow_heads/arrow_hexagon.png";
import arrow_unevenArrow from "./images/arrow_heads/arrow_unevenArrow.png";
import arrow_slim_arrow from "./images/arrow_heads/arrow_slim_arrow.png";
import {useDispatch} from "react-redux";
import {changeUserDebateSettings, changeUserSettings} from "./actions/googleActions";

export const objToQueryString = (obj) => {
    const keyValuePairs = [];
    for (const key in obj) {
        keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
    return keyValuePairs.join('&');
}

export const abbreviateNumber = (value) =>{
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "K", "M", "B","T"];
        var suffixNum = Math.floor( (""+value).length/3 );
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 !== 0)  shortValue = shortValue.toFixed(1);
        newValue = shortValue+suffixes[suffixNum];
    }
    return newValue;
}

export const isTokenValid = (expirationDate) => {
    return Moment().isSameOrBefore(Moment(expirationDate, 'x'))
}

export const HomeIcon = () =>
    <>
        <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-house-door-fill" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.5 10.995V14.5a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5V11c0-.25-.25-.5-.5-.5H7c-.25 0-.5.25-.5.495z"/>
            <path fillRule="evenodd" d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
        </svg>
    </>

export const SubscribedIcon = ( {width, height} ) =>
    <>
        <svg width={width} height={height} viewBox="0 0 16 16" className="bi bi-star-fill" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>
    </>

export const BrowseIcon = () =>
    <>
        <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
            <path fillRule="evenodd"
                  d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
        </svg>
    </>

export const PartInIcon = ( {width, height} ) =>
    <>
        <svg width={width} height={height} viewBox="0 0 16 16" className="bi bi-chat-right-text-fill" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/>
        </svg>
    </>

export const MyDebatesIcon = ( {width, height} ) =>
    <>
        <svg width={width} height={height} viewBox="0 0 16 16" className="bi bi-archive-fill" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM6 7a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1H6zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z"/>
        </svg>
    </>

export function sidebarCollapseClick() {
    $('.sidebar_container').toggleClass('hidden');
}

export function getBackgroundGradient(colorPattern) {
        let minColor = calcColor(colorPattern, {weight: 0});
        let maxColor = calcColor(colorPattern, {weight: 1});


        return {
            "background":"-webkit-linear-gradient(to right,"+minColor+","+maxColor+")",
            "background":"linear-gradient(to right,"+minColor+","+maxColor+")"
        }
}

export function getBorderColor(d, colorPattern) {
    let color = shadeColor(calcColor(colorPattern, d), -15);
    return "solid 2px " + color;
}

export const calcColor = (colorPattern, d) => {
        let colorFunction;
        switch(colorPattern) {
            case "0":
                //dark blue to light blue
                colorFunction = chroma.scale(['#0d63cb', '#1be4f6']);
                break;
            case "1":
                //blue to green
                colorFunction = chroma.scale(['#2c708f', '#6cf16e']);
                break;
            case "2":
                //red to yellow
                colorFunction = chroma.scale(['#fc4a1a', '#f7b733']);
                break;
            case "3":
                //dark green to light green
                colorFunction = chroma.scale(['#007991', '#5fffe0']);
                break;
            case "4":
                //orange to yellow
                colorFunction = chroma.scale(['#dd7b1e', '#f0fa67']);
                break;
            case "5":
                //dark green to light blue
                colorFunction = chroma.scale(['#147900', '#41ffd3']);
                break;
            case "6":
                //light pink to purplish
                colorFunction = chroma.scale(['#f0bcff', '#8a6aff']);
                break;
            case "7":
                //faded out blue to light blue
                colorFunction = chroma.scale(['#c4daff', '#4dbeff']);
                break;
            default:
                //dark blue to light blue
                colorFunction = chroma.scale(['#0d63cb', '#1be4f6']);
        }

        return colorFunction(d.weight).toString();
}

/*
export const calcColor = (d) => {
    let color;
    if (d.weight <= 0.1)
        //  color = "#ff4626";
        //    color = "#ff5e2d";
        color = "#9ef1ff"
    else if (d.weight <= 0.2)
        //  color = "#ff5e2d"
        color = "#88e9f9"
    else if (d.weight <= 0.3)
        //   color = "#ff8f33"
        //  color = "#FF6C28"
        //    color = "#FF822C"
        color = "#81DDF4"
    else if (d.weight <= 0.4)
        //  color = "#ffa72b"
        color = "#7AD2F0"
    else if (d.weight <= 0.5)
        //  color = "#ffe120"
        color = "#6bbae6"
    else if (d.weight <= 0.6)
        //    color = "#dbe555"
        color = "#6bbae6"
    else if (d.weight <= 0.7)
        //  color = "#C9DE46"
        color = "#64AFE2"
    else if (d.weight <= 0.8)
        // color = "#bcdd54"
        // color = "#c5de2d"
        //  color = "#B2D532"
        color = "#5CA3DD"
    else if (d.weight <= 0.9)
        //  color = "#c5de2d"
        //  color = "#B0D530"
        //  color = "#A4CE33"
        //    color = "#ACCE3B"
        color = "#5597D8"
    else
        // color = "#99c934"
        //    color = "#90BE30"
        color = "#4E8CD4"

    return color;
}
*/

export function shadeColor(color, percent) {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}

export const calcStrokeWidth = (score) => {
    let strokeWidth;

    if (score <= 0.1)
        strokeWidth = 2
    else if (score <= 0.3)
        strokeWidth = 2.6
    else if (score <= 0.4)
        strokeWidth = 3.2
    else if (score <= 0.5)
        strokeWidth = 3.8
    else if (score <= 0.7)
        strokeWidth = 4.4
    else if (score <= 0.9)
        strokeWidth = 5
    else if (score <= 1.0)
        strokeWidth = 5.5

    return strokeWidth
}

export const DEFAULT_SEMANTIC = 0;
export const DEFAULT_COLOR_PATERN = 0;
export const STROKE_COLOR_SUPPORT = "#d45c9e";
export const STROKE_COLOR_ATTACK = "#7f5ea7";
export const ATTACK_HEAD = "roundArrow";
export const SUPPORT_HEAD = "circle";
export const MAX_FILES = 5;

export function saveUserSettings(currentUser) {
    if(currentUser && currentUser.token != undefined) {
        let color_pattern = $('#colorPatternSelect').val();
        let attack_color = $('#colorAttacks').val();
        let support_color =  $('#colorSupports').val();
        let attack_head = $('#attacksHeadSelect').children("option:selected").val()
        let support_head = $('#supportsHeadSelect').children("option:selected").val();
        let semantic = $('#semanticSelect').children("option:selected").val();

        currentUser.color_pattern = color_pattern
        currentUser.attack_color = attack_color
        currentUser.support_color = support_color
        currentUser.attack_head = attack_head
        currentUser.support_head = support_head
        currentUser.semantic = semantic

        let jsonToSend = JSON.stringify(
            {
                color_pattern: color_pattern,
                attack_color: attack_color,
                support_color: support_color,
                attack_head: attack_head,
                support_head: support_head,
                semantic: semantic
            }
        )

        $.ajax({
            'crossDomain': true,
            'async': false,
            'global': false,
            "headers": {
                "content-type": "application/json",
                "authorization": "Bearer "+ currentUser.token},
            'type' : "POST",
            'url': baseUrl + "/saveUserSettings/" + currentUser.token,
            'data': jsonToSend,
            'success': function (data) {
                $('#settingsBox').css("display", "none")
            },
            'error': function (xhr, status, error) {
                alert("Whoops...Something happened, please try again!")
            }
        });
    }

}

export const getArrowImg = (name) => {
    switch(name) {
        case 'og':
            return arrow_og;
        case 'roundArrow':
            return arrow_roundArrow;
        case 'abs_square':
            return arrow_abs_square;
        case 'square_hole':
            return arrow_square_hole;
        case 'circle':
            return arrow_circle;
        case 'square':
            return arrow_square;
        case 'hexagon':
            return arrow_hexagon;
        case 'unevenArrow':
            return arrow_unevenArrow;
        case 'slim_arrow':
            return arrow_slim_arrow;
    }
}

export function handleSignInClick() {
    $('.g-sign-in-button').click();
}

export function signInTooltip(element, message) {
    $('.signInTooltip').remove();

    var offset = element.offset();

    var tooltip = $('<div>')
        .attr("class", "signInTooltip")
        .css( {
            "top": offset.top + element.height() + 4,
            "left": offset.left
        })
        .append($('<span>')
            .html(message));

    $('body').append(tooltip);
    tooltip.hide();

    var width_diff = tooltip.width() - element.width();
    tooltip.css("left", offset.left - width_diff/2)

    tooltip.show()

    setTimeout(function() {
        tooltip.fadeOut(function () {
            tooltip.remove()
        });
    }, 1500);
}

//DEBATE COLORS
export const DEBATE_COLOR_0 = "#a4becb75"
export const DEBATE_COLOR_1 = "rgb(255, 219, 89)"
export const DEBATE_COLOR_2 = "rgb(255, 200, 200)"
export const DEBATE_COLOR_3 = "rgb(147, 225, 104)"
export const DEBATE_COLOR_4 = "rgb(204, 184, 255)"

export const DEBATE_LIMIT = 40;
export const isDev = false;
export const baseUrl = isDev ? "http://localhost:8080/swarg/rest" : "https://swarg.di.fct.unl.pt/rest";
export const clientId = isDev ? "583272947271-8kp5rm7vc6c7hf9eoll63v027sk5tlie.apps.googleusercontent.com" : "583272947271-fark49nnhho3s8lt30r5nv4r30d4n2q2.apps.googleusercontent.com"