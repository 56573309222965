import {LOAD_POPULAR_TAGS} from "../actions/types";

const initialState = {
    loadedTags: false,
    popularTags: []
}

export default function tagsReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_POPULAR_TAGS:
            const newTags = [];
            action.tags.map( (tag) => {
                newTags.push(tag.name)
            })
            return {
                loaded: true,
                popularTags: newTags
            }
        default: return state;
    }
}