import {
    CHANGE_INPUT,
    CHANGE_TYPE,
    CHANGE_DATE,
    CHANGE_LIMIT,
    CHANGE_MODE,
    CHANGE_SKIP,
    CHANGE_SORT,
    CHANGE_TAGS, RESET_FILTERS, LOAD_POPULAR_TAGS, SET_ONLY_SORT, SET_ONLY_INPUT, CHANGE_DEBATE_TYPE
} from "./types";
import axios from "axios";
import { baseUrl } from "../functions";
import {resetSkip} from "./skipActions";
import {resetItems, resetLoaded} from "./debateActions";

export const changeType = ( {value} ) => dispatch => {
    dispatch( {
        type: CHANGE_TYPE,
        value: value
    })
}

export const changeDate = ( {value} ) => dispatch => {
    dispatch( {
        type: CHANGE_DATE,
        date: value
    })
}

export const changeSort = ( {value} ) => dispatch => {
    dispatch( {
        type: CHANGE_SORT,
        sort: value
    })
}

export const changeInput = ( {input} ) => dispatch => {
    dispatch( {
        type: CHANGE_INPUT,
        input: input
    })
}

export const changeMode = ( {value} ) => dispatch => {
    dispatch( {
        type: CHANGE_MODE,
        mode: value
    })
}

export const changeTags = ( {tags} ) => dispatch => {
    dispatch( {
        type: CHANGE_TAGS,
        tags: tags
    })
}

export const changeDebateType = ( {type} ) => dispatch => {
    dispatch( {
        type: CHANGE_DEBATE_TYPE,
        value: type
    })
}

export const setOnlySort = ( {sort} ) => dispatch => {
    dispatch( {
        type: SET_ONLY_SORT,
        sort: sort
    })
}

export const setOnlyInput = ( {input} ) => dispatch => {
    dispatch( {
        type: SET_ONLY_INPUT,
        input: input
    })
}

export const resetFilters = () => dispatch => {
    dispatch( {
        type: RESET_FILTERS
    })
}

export const loadPopularTags = () => dispatch => {
    axios({
        method: 'get',
        url: `${baseUrl}/tags/`,
    })
        .then(res => res.data)
        .then(tags =>  dispatch( {
            type: LOAD_POPULAR_TAGS,
            tags: tags
        }))
}
