import React, {useEffect, useRef, useState} from "react";
import FilterBox from "./FilterBox";
import DebateCard from "./DebateCard";
import CardDeck from "react-bootstrap/CardDeck";
import DebatePlaceholder from "./DebatePlaceholder";
import Empty from "./Empty";
import {connect, useDispatch} from "react-redux";
import {Spinner} from "react-bootstrap";
import {resetSkip} from "../actions/skipActions";
import {resetItems, resetLoaded} from "../actions/debateActions";
import {resetFilters} from "../actions/filterActions";

const FilteredDebatesContent = ( {debates, fetchData, limit, hasMore, isResetDebates, isResetFilters, filters} ) => {
    const dispatch = useDispatch();
    // add loader refrence
    const loader = useRef(null);
    const [page, setPage] = useState(0)

    useEffect( () => {
        if(isResetDebates) {
            fetchData({skip: 0, limit: limit})
        }
    }, [isResetDebates, isResetFilters])


    useEffect( () => {
        if(!isResetFilters) {
            setPage(0)
            dispatch(resetItems())
        }
    }, [filters])


    useEffect( () => {
        if(page > 0 && hasMore) {
            fetchData( {skip: page*limit, limit: limit} )
        }
    }, [page])

    useEffect(() => {
        let options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0
        };

        // initialize IntersectionObserver
        // and attaching to Load More div
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current)
        }

    }, [loader.current]);

    function handleObserver(entities) {
        const target = entities[0];
        if (target.isIntersecting) {
            setPage( (page) => page + 1 )
        }
    }


    return (
        <div className={`d-lg-flex container-fluid`}>
            <FilterBox/>
            {!isResetDebates ? (
                <div className={"d-flex flex-column w-100 align-items-center pb-4 pt-3"}>
                    {debates.length > 0 ? (
                        <>
                            <div className="debatesContent container-fluid">
                                {debates.map( (debate) => (<DebateCard key={debate.graphId} debate={debate}/>))}
                            </div>
                            {hasMore && (
                                <Spinner animation="border" className={"mt-4 mb-3"} ref={loader}/>
                            )}
                        </>

                    ) : (
                        <Empty/>
                    )}
                </div>
            ) : (
                <div className="debatesContent container-fluid">
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                    <DebatePlaceholder/>
                </div>
            )}
        </div>
    )
}


const mapStateToProps = state => ({
    filters: state.filters,
    limit: state.skip.limit,
    skip: state.skip.skip,
    hasMore: state.debates.hasMore,
    isResetDebates: state.debates.reset,
    isResetFilters: state.filters.reset
})

export default connect(mapStateToProps, null)(FilteredDebatesContent)
