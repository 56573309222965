import React, {useState, useEffect} from "react";
import { runForceGraph } from "./ForceGraphGenerator.js";
import styles from "./forceGraph.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faFilter, faBars } from '@fortawesome/free-solid-svg-icons';
import settingsIcon from "../images/settings.png"
import tutorialIcon from "../images/question-mark-square.png"
import GoogleButton from "./GoogleButton";
import Sidebar from "./Sidebar";
import arrow_og from "../images/arrow_heads/arrow_og.png";
import arrow_roundArrow from "../images/arrow_heads/arrow_roundArrow.png";
import arrow_abs_square from "../images/arrow_heads/arrow_abs_square.png";
import arrow_square_hole from "../images/arrow_heads/arrow_square_hole.png";
import arrow_circle from "../images/arrow_heads/arrow_circle.png";
import arrow_square from "../images/arrow_heads/arrow_square.png";
import arrow_hexagon from "../images/arrow_heads/arrow_hexagon.png";
import arrow_unevenArrow from "../images/arrow_heads/arrow_unevenArrow.png";
import arrow_slim_arrow from "../images/arrow_heads/arrow_slim_arrow.png";
import logo from "../images/logo.png"
import plusSignIcon from "../images/plus-sign.png"
import pushPinTransparentIcon from "../images/push-pin-transparent.png"
import focusIcon from "../images/zoomextent.png"
import imgPlaceholder from "../images/imgPlaceholder.png"
import pdfIcon from "../images/PDF_file_icon.png"
import {OverlayTrigger} from "react-bootstrap";
import HoverTooltip from "./HoverTooltip";
import {ExclamationCircle, Stack, X} from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import {CSSTransitionGroup} from "react-transition-group";
import Button from "react-bootstrap/Button";
import {connect, useDispatch} from "react-redux";
import {isTokenValid, saveUserSettings} from "../functions";
import {googleOAuth2Success} from "../actions/googleActions";
import Body from "./Body";
import {runTreeGraph} from "./TreeGraphGenerator";
import {loadActiveDebateInfo} from "../actions/debateActions";

const ForceGraph = ({ loaded, googleResponse, swargProfile, graphId, debate}) => {
    const containerRef = React.useRef(null);
    const dispatch = useDispatch();
    const [graphType, setGraphType] = useState(null)

    /* ADD DEBATE MODAL */
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setArgumentText("")
    }
    const handleShow = () => {
        if(swargProfile && swargProfile.token != undefined)
            setShow(true);
    }


    const [showErrorEmpty, setShowErrorEmpty] = useState(false)
    const [argumentText, setArgumentText] = useState("")

    const handleSubmit = () => {
        if(argumentText == "") {
            setShowErrorEmpty(true)
            const timer = setTimeout(() => {
                setShowErrorEmpty(false)
            }, 3000);
            return () => clearTimeout(timer);
        }
    }

    useEffect(() => {
        console.log("use effect force graph")
        console.log(loaded)

        let destroyFn;
        let currentUser = undefined;

        if(debate === null) {
            dispatch(loadActiveDebateInfo( {graphId: graphId}) )
        }
        else{
            if(loaded) {
                if(swargProfile && swargProfile.token) {
                    if(isTokenValid(swargProfile.date))
                        currentUser = swargProfile;
                    else {
                        console.log("dispatching success on forcegraph")
                        dispatch(googleOAuth2Success(googleResponse))
                    }
                }
                if (containerRef.current) {
                    const isTree = debate.type === "tree"

                    console.log("has initial:")
                    console.log(debate)
                    console.log(debate.hasInitialArguments)
                    const type = debate.hasInitialArguments ? "alternative" : "free"

                    const { destroy } = isTree ? runTreeGraph(containerRef.current, graphId, currentUser, dispatch) :
                        runForceGraph(containerRef.current, graphId, currentUser, type, dispatch);
                    destroyFn = destroy;
                }
            }
        }


        return destroyFn;
    }, [loaded, swargProfile, debate]);

    const content =
        <>
            <div className="interfaceContent h-100 w-100">
                <div id="sliderFilterBox" className="p-2">
                    <div id="slider-background">
                        <div className="range-div min">
                            <div className="slider-draggable min"/>
                        </div>
                        <div className="range-div max">
                            <div className="slider-draggable max"/>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between pl-1 pr-1">
                        <span>Weak</span>
                        <span>Strong</span>
                    </div>
                </div>
                <div id="settingsBox">
                    <div className="settings_section">
                        <span className="settings_header">Settings</span>
                        <hr/>
                            <div className="settings_row">
                                <span>Arguments' colors</span>
                                <select id="colorPatternSelect" className="headSelect" name="colorPatterns">
                                    <option value="0"/>
                                    <option value="1"/>
                                    <option value="2"/>
                                    <option value="3"/>
                                    <option value="4"/>
                                    <option value="5"/>
                                    <option value="6"/>
                                    <option value="7"/>
                                    <option value="8"/>
                                </select>
                                <div id="colorPattern-picker" className="colorPattern-picker"/>
                                <div id="colorPatterns" className="styles_box">
                                    <div id="pattern-0" name="0" className="colorPattern-picker2">
                                    </div>
                                    <div id="pattern-1" name="1" className="colorPattern-picker2">
                                    </div>
                                    <div id="pattern-2" name="2" className="colorPattern-picker2">
                                    </div>
                                    <div id="pattern-3" name="3" className="colorPattern-picker2">
                                    </div>
                                    <div id="pattern-4" name="4" className="colorPattern-picker2">
                                    </div>
                                    <div id="pattern-5" name="5" className="colorPattern-picker2">
                                    </div>
                                    <div id="pattern-6" name="6" className="colorPattern-picker2">
                                    </div>
                                    <div id="pattern-7" name="7" className="colorPattern-picker2">
                                    </div>
                                    <div id="pattern-8" name="8" className="colorPattern-picker2">
                                    </div>
                                </div>
                            </div>
                            <div className="settings_row">
                                <span>Attacks</span>
                                <div className="settings_selectors">
                                    <select id="attacksHeadSelect" className="headSelect" name="Attack">
                                        <option value="og">Original</option>
                                        <option value="roundArrow">More Rounded Arrow</option>
                                        <option value="hexagon">Hexagon</option>
                                        <option value="square">Square</option>
                                        <option value="abs_square">Abstract Square</option>
                                        <option value="square_hole">Square with circle in the middle</option>
                                        <option value="circle">Circle</option>
                                        <option value="unevenArrow">Uneven Arrow</option>
                                        <option value="slim_arrow">Slim Arrow</option>
                                    </select>
                                    <div id="styles_attacks_button" className="style_div">
                                         <img src={arrow_og}/>
                                         <i className="fas fa-caret-down"/>
                                    </div>
                                    <div id="styles_attacks" className="styles_box">
                                        <div id="attack-og" name="og" className="style_div attack">
                                            <img src={arrow_og}/>
                                        </div>
                                        <div id="attack-roundArrow" name="roundArrow" className="style_div attack">
                                            <img src={arrow_roundArrow}/>
                                        </div>
                                        <div id="attack-abs_square" name="abs_square" className="style_div attack">
                                            <img src={arrow_abs_square}/>
                                        </div>
                                        <div id="attack-square_hole" name="square_hole" className="style_div attack">
                                            <img src={arrow_square_hole}/>
                                        </div>
                                        <div id="attack-circle" name="circle" className="style_div attack">
                                            <img src={arrow_circle}/>
                                        </div>
                                        <div id="attack-square" name="square" className="style_div attack">
                                            <img src={arrow_square}/>
                                        </div>
                                        <div id="attack-hexagon" name="hexagon" className="style_div attack">
                                            <img src={arrow_hexagon}/>
                                        </div>
                                        <div id="attack-unevenArrow" name="unevenArrow" className="style_div attack">
                                            <img src={arrow_unevenArrow}/>
                                        </div>
                                        <div id="attack-slim_arrow" name="slim_arrow" className="style_div attack">
                                            <img src={arrow_slim_arrow}/>
                                        </div>
                                    </div>
                                    <div id="color-picker-attacks" className="color-picker"/>
                                    <input type="color" id="colorAttacks"/>
                                </div>
                            </div>
                            <div className="settings_row">
                                <span>Supports</span>
                                <div className="settings_selectors">
                                    <select id="supportsHeadSelect" className="headSelect" name="Support">
                                        <option value="og">Original</option>
                                        <option value="roundArrow">More Rounded Arrow</option>
                                        <option value="hexagon">Hexagon</option>
                                        <option value="square">Square</option>
                                        <option value="abs_square">Abstract Square</option>
                                        <option value="square_hole">Square with circle in the middle</option>
                                        <option value="circle">Circle</option>
                                        <option value="unevenArrow">Uneven Arrow</option>
                                        <option value="slim_arrow">Slim Arrow</option>
                                    </select>
                                    <div id="styles_supports_button" className="style_div">
                                        <img src={arrow_og}/>
                                        <i className="fas fa-caret-down"/>
                                    </div>
                                    <div id="styles_supports" className="styles_box">
                                        <div id="support-og" name="og" className="style_div support">
                                            <img src={arrow_og}/>
                                        </div>
                                        <div id="support-roundArrow" name="roundArrow" className="style_div support">
                                            <img src={arrow_roundArrow}/>
                                        </div>
                                        <div id="support-abs_square" name="abs_square" className="style_div support">
                                            <img src={arrow_abs_square}/>
                                        </div>
                                        <div id="support-square_hole" name="square_hole" className="style_div support">
                                            <img src={arrow_square_hole}/>
                                        </div>
                                        <div id="support-circle" name="circle" className="style_div support">
                                            <img src={arrow_circle}/>
                                        </div>
                                        <div id="support-square" name="square" className="style_div support">
                                            <img src={arrow_square}/>
                                        </div>
                                        <div id="support-hexagon" name="hexagon" className="style_div support">
                                            <img src={arrow_hexagon}/>
                                        </div>
                                        <div id="support-unevenArrow" name="unevenArrow" className="style_div support">
                                            <img src={arrow_unevenArrow}/>
                                        </div>
                                        <div id="support-slim_arrow" name="slim_arrow" className="style_div support">
                                            <img src={arrow_slim_arrow}/>
                                        </div>
                                    </div>
                                    <div id="color-picker-supports" className="color-picker"/>
                                    <input type="color" id="colorSupports"/>
                                </div>
                            </div>
                    </div>
                    <div className="settings_advanced">
                        <div className="advanced_settings_click">
                            <span>Advanced</span> <i className="fas fa-caret-down"/>
                        </div>
                        <div className="settings_row advanced_hidden">
                            <span>Semantic</span>
                            <div className="semantic_select_div">
                                <select id="semanticSelect" name="semantic">
                                    <option value="0">sigDampMaxBased</option>
                                    <option value="1">maxEulerBased</option>
                                    <option value="2">hCategorizer</option>
                                    <option value="3">simpleProduct</option>
                                    {debate && debate.type === "tree" && (
                                        <>
                                            <option value="4">EulerBased</option>
                                            <option value="5">DF-QuAD</option>
                                        </>
                                    )}
                                </select>
                                <i className="fas fa-info-circle"/>
                            </div>

                        </div>
                    </div>
                    <div className="settings_bottom">
                        { swargProfile && swargProfile.token != undefined ? (
                            <div className="saveButton" id="saveButton">
                                <p>Save</p>
                            </div>
                        ) : (
                            <OverlayTrigger
                            placement="bottom"
                            overlay={
                            <HoverTooltip id="hover-tooltip-over" text={"Sign in to save your preferences"}/>
                        }
                            >
                                <div className="saveButton" id="saveButton">
                                <p>Save</p>
                                </div>
                            </OverlayTrigger>
                        )}
                    </div>
                </div>
                <button id="hiddenAddButton" className={"d-none"} onClick={handleShow}/>
                <div className="bottom-buttons">
                   <OverlayTrigger
                        placement="top"
                        delay={{ show: 0, hide: 100 }}
                        overlay={
                            <HoverTooltip text={ swargProfile && swargProfile.token != undefined ? "Add argument" : "Sign in to add an argument"}/>
                        }
                    >
                       {debate && debate.type === "tree" ? (
                           <button id="addButton" className="button_hover">
                               <img src={plusSignIcon}/>
                           </button>
                       ) : (
                           <button id="addButton" className="button_hover" onClick={handleShow}>
                               <img src={plusSignIcon}/>
                           </button>
                       )}
                    </OverlayTrigger>
                    {debate && debate.type === "tree" ? (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 100 }}
                            overlay={
                                <HoverTooltip text={"Collapse all"}/>
                            }
                        >
                            <button id="collapseButton" className="button_hover">
                                <Stack color="black" size={40}/>
                            </button>
                        </OverlayTrigger>
                       ) : (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 100 }}
                            overlay={
                                <HoverTooltip text="Unpin all arguments"/>
                            }
                        >
                            <button id="releaseAllNodesButton" className="button_hover">
                                <img src={pushPinTransparentIcon}/>
                            </button>
                        </OverlayTrigger>
                    )}
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 0, hide: 100 }}
                        overlay={
                            <HoverTooltip text="Fit to screen"/>
                        }
                    >
                        <button id="focusButton" className="button_hover">
                            <img src={focusIcon}/>
                        </button>
                    </OverlayTrigger>
                </div>
                <Modal
                    id="submitArgModal"
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="newArgumentTitle">New argument</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <textarea
                            id="divTextarea"
                            name="Text1"
                            rows="20"
                            value={argumentText}
                            onChange={ (e) => {setArgumentText(e.target.value)}}
                            className={ showErrorEmpty ? 'error' : '' }
                        />
                        <CSSTransitionGroup
                            transitionName="input_error"
                            transitionEnterTimeout={300}
                            transitionLeaveTimeout={300}
                        >
                            {showErrorEmpty &&
                            (<div
                                key="input_error"
                                className="input_error_alert my-2"
                            >
                                <ExclamationCircle color="red" size={20}/>
                                <span className="ml-2 mt-1">Empty argument!</span>
                            </div>)}
                        </CSSTransitionGroup>
                        <div className="image-upload change pt-2">
                            <label htmlFor="file-input">
                                <img src={imgPlaceholder}/>
                                <span className="ml-2">Upload Image/Video</span>
                            </label>
                            <form encType="multipart/form-data" method="post" name="fileinfo">
                                <input id="file-input" type='file' accept=".mp4,.jpeg,.jpg,.png,.pdf" multiple/>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div id="alertUploading" className="input_error_alert2">
                            <ExclamationCircle color="red" size={20}/>
                            <span id="alertUploadingSpan" className="ml-1">Please wait for all files to finish uploading</span>
                        </div>
                        <Button id="submitArg" variant="primary" onClick={handleSubmit}>Create Argument</Button>
                    </Modal.Footer>
                </Modal>
                <div id="argumentFilesViewModal" className="modal" role="dialog" data-backdrop="false">
                    <div className="uploadViewer">
                        <div className="closeUploadView">
                            <button type="button" className="close closeButtonUpload">&times;</button>
                        </div>
                        <div className="midSection">
                            <i className="previousFile fas fa-angle-left"/>
                            <div className="imgDiv">
                                <video
                                    controls
                                    playsinline
                                    disablePictureInPicture
                                    id="player"
                                >
                                </video>
                                <img className="imageFile"/>
                                    <div className="pdfPreview">
                                        <img src={pdfIcon}/>
                                            <p className="fileName">
                                                <span className="trunkatePDF fileName"/>
                                            </p>
                                            <button className="btn downloadBtn">
                                                <i className="fa fa-download"/> Download
                                            </button>
                                    </div>
                            </div>
                            <i className="nextFile fas fa-angle-right"/>
                        </div>
                        <div className="fileProgress">

                        </div>
                    </div>
                </div>
                <div id="startTutorialModal" className="modal" role="dialog">
                    <div className="modal-content pt-2 pr-2 pb-3 pl-3 startTutorialContent d-flex flex-column justify-content-between align-items-center bg-white">
                        <div className="w-100">
                            <button id="closeStartTutorial" type="button" data-dismiss="modal" className="close">&times;</button>
                        </div>
                        <span>Get ready to debate</span>
                        <a href="#" id="startTutorial" className="button0">Start tutorial</a>
                        <div className="check_tutorial w-100">
                            <input type="checkbox" id="check_tutorial-checkbox" checked/> Show tutorial when opening a
                            debate
                        </div>
                    </div>
                </div>
                <div id="tutorialModal" className="modal" role="dialog">
                    <div className="modal-content tutorialModalContent d-flex flex-column align-items-center bg-white">
                        <svg id="help-svg" className="svg-content-responsive">
                        </svg>
                        <div className="bottom-buttons">
                            <button id="addButton" className="button_hover help-addButton">
                                <img src={plusSignIcon}/>
                            </button>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 0, hide: 100 }}
                                overlay={
                                    <HoverTooltip text="Unpin all arguments"/>
                                }
                            >
                                <button id="help-releaseAllNodesButton" className="button_hover">
                                    <img src={pushPinTransparentIcon}/>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 0, hide: 100 }}
                                overlay={
                                    <HoverTooltip text="Fit to screen"/>
                                }
                            >
                                <button id="help-focusButton" className="button_hover">
                                    <img src={focusIcon}/>
                                </button>
                            </OverlayTrigger>
                        </div>
                        <div className="exit-div">
                            <a href="#" className="help-button-exit">Exit tutorial</a>
                        </div>
                        <div className="help-header help-tooltip tooltipArrow tooltip-bottom rotatingAnimation">
                            <span>Click to add a new argument</span>
                        </div>
                    </div>
                </div>
                <div className="modal" id="help-addArgument" role="dialog" data-backdrop="false"
                     data-keyboard="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">New Argument</h4>
                                <button type="button" className="close closefirstmodal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <textarea id="help-divTextarea" name="Text2" rows="20" placeholder={"Write your argument here"}/>
                                <div id="help-emptyArgument" className="input_error_alert2">
                                    <ExclamationCircle color="red" size={20}/>
                                    <span className="ml-2 mt-1">Empty argument!</span>
                                </div>
                                <div className="image-upload mt-2">
                                    <img src={imgPlaceholder}/>
                                    <text className="text-faded ml-2">Upload Image/Video</text>
                                </div>
                            </div>
                            <div className="modal-footer ">
                                <Button id="help-submitArg" variant="primary">Create Argument</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <img id="logo" src={logo} className="img" alt="logo"/>
                <ul className='custom-menu' id="argument_options">
                    <li data-action="first">Add Attack</li>
                    <li data-action="second">Add Support</li>
                    <hr/>
                </ul>
                <ul className='custom-menu' id="check-arg-connect">
                    <span id="are_you_sure">Are you sure?</span>
                    <div className="check-arg-bottom">
                        <div className="check-connect-ask-again">
                            <input type="checkbox" id="arg-connect-ask-again"/> Do not ask me again
                        </div>
                        <div className="check-arg-buttons">
                            <li data-action="second" id="connectCancel">Cancel</li>
                            <li data-action="first" id="connectConfirm">Attack/Support</li>
                        </div>
                    </div>
                </ul>
                <ul className='custom-menu' id="help_argument_options">
                    <li data-action="first" className="help-add-attack">Add Attack</li>
                    <li data-action="second" className="help-faded">Add Support</li>
                    <hr/>
                </ul>
                <div className="addArgumentHelper py-3 pl-3 pr-2 align-items-center">
                    <span className="mr-2">Click on the argument you want to attack or support</span>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 0, hide: 100 }}
                        overlay={
                            <HoverTooltip text="Cancel"/>
                        }
                    >
                        <X className="cancelIcon" size={25}/>
                    </OverlayTrigger>
                </div>
                <div ref={containerRef} className={`wrapper h-100 w-100 ${styles.container}`}/>
            </div>
        </>

    return <Body loaded={true} defaultHome={true} content={content}/>
};

const mapStateToProps = state => ({
    loaded: state.google.loaded,
    googleResponse: state.google.googleObj,
    swargProfile: state.google.swargProfile,
    debate: state.debates.activeDebate
})

export default connect(mapStateToProps)(ForceGraph);