import React, {useRef} from "react"
import Tags from "@yaireo/tagify/dist/react.tagify"
import "@yaireo/tagify/dist/tagify.css" // Tagify CSS
import {useDispatch} from "react-redux";
import {changeTags} from "../actions/filterActions";
import {connect} from "react-redux"

const baseTagifySettings = {
    maxTags: 10,
    duplicates: false,
    delimiters: ",| ",
    placeholder: "Write some tags"
    //backspace: "edit",
    /*dropdown: {
        enabled: 0 // a;ways show suggestions dropdown
    }*/
}

const tagifyCallbacks = {
    // add: callback,
    // remove: callback,
    // input: callback,
    // edit: callback,
    // invalid: callback,
    // click: callback,
    // keydown: callback,
    // focus: callback,
    // blur: callback,
    // "edit:input": callback,
    // "edit:updated": callback,
    // "edit:start": callback,
    // "edit:keydown": callback,
    // "dropdown:show": callback,
    // "dropdown:hide": callback,
    // "dropdown:select": callback
}

const settings = {
    ...baseTagifySettings,
    callbacks: tagifyCallbacks
}

const TagsInput = ({onChange, tagifyRef, value}) => {
    return (
            <Tags
                tagifyRef={tagifyRef}
                settings={settings}
                value={value}
                autoFocus={false}
                onChange={onChange}
            />
    )
}


export default TagsInput;