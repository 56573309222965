import React from "react";
import Sidebar from "./Sidebar";
import {useLocation} from "react-router-dom";

const Body = ({ content, loaded, defaultHome }) => {
    const path = useLocation().pathname
    const isDebate = path.includes("/debate")

    return (
        <div className={`body h-100 ${isDebate ? "position-absolute w-100" : ""} d-flex ${loaded ? "" : "overflow-hidden"}`}
             style={isDebate ? {} : {"marginTop": "80px", "marginLeft": "90px"}}>
            <div className={`body_content_wrapper d-flex w-100`}>
                <div className={`body_content container-fluid ${defaultHome ? "p-0" : "m-2"}`}>
                    {content}
                </div>
            </div>
        </div>
    )
}

export default Body