import React, {useEffect} from "react"
import FilteredDebatesContent from "./FilteredDebatesContent";
import Body from "./Body";
import {fetchSubscribedDebates, resetItems, resetLoaded} from "../actions/debateActions"
import { connect, useDispatch } from "react-redux";
import {resetFilters} from "../actions/filterActions";
import {handleSignInClick, isTokenValid, SubscribedIcon} from "../functions";
import {googleOAuth2Success} from "../actions/googleActions";
import {XSquare} from "react-bootstrap-icons";
import {resetSkip} from "../actions/skipActions";

const Subscribed = (props) => {
    const dispatch = useDispatch();

    const fetchData = ( {skip, limit} ) => {
         if(props.swargProfile && props.swargProfile.token) {
            if(isTokenValid(props.swargProfile.date)) {
                dispatch(fetchSubscribedDebates({filters: props.filters, token: props.swargProfile.token, limit: limit, skip: skip}))
            }
            else {
                dispatch(googleOAuth2Success(props.googleResponse))
            }
        }
    }

    useEffect(() => {
        dispatch(resetItems())
        dispatch(resetFilters())
    },[])

    const content =
        <>
            {props.loaded && (
                <>
                    {props.swargProfile !== null ? (
                        <>
                            <FilteredDebatesContent debates={props.debates} fetchData={fetchData}/>
                        </>
                    ) : (
                        <div className="w-100 d-flex align-items-center flex-column h-75 justify-content-center">
                            <SubscribedIcon width={"50px"} height={"50px"}/>
                            <h4 className="mt-4">Don't miss out on the discussion</h4>
                            <span> <span className="signInClickable" onClick={handleSignInClick}>Sign in</span> to keep track of your favorite debates</span>
                        </div>
                    )}
                </>
            )}
        </>

    return (
        <Body loaded={props.loadedDebates} defaultHome={props.swargProfile===null} content={content}/>
    )
}

const mapStateToProps = state => ({
    debates: state.debates.items,
    filters: state.filters,
    limit: state.skip.limit,
    skip: state.skip.skip,
    loadedDebates: state.debates.loaded,
    loaded: state.google.loaded,
    googleResponse: state.google.googleObj,
    swargProfile: state.google.swargProfile
})

export default connect(mapStateToProps, null)(Subscribed)