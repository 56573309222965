import React from "react";
import { Link, useLocation } from 'react-router-dom';
import {BrowseIcon, HomeIcon, MyDebatesIcon, PartInIcon, SubscribedIcon} from "../functions";


const Sidebar = () => {
    const path = useLocation().pathname
    const isDebate = path.includes("/debate")

    return (
        <nav className={ `sidebar_container ${isDebate ? 'hidden' : ''} h-100 ${isDebate ? 'position-fixed' : "position-fixed"}`}
             style={ {"marginTop": "80px"} }>
            <ul className="list-unstyled">
                <li>
                    <Link to="/home" className={path === "/home" ? "active" : ""}>
                        <HomeIcon/>
                        <p>Home</p>
                    </Link>
                </li>
                <li>
                    <Link to="/subscribed" className={path === "/subscribed" ? "active" : ""}>
                        <SubscribedIcon width={"2em"} height={"2em"}/>
                        <p>Subscribed Debates</p>
                    </Link>
                </li>
                <li>
                    <Link to="/browse" className={path.includes("/browse") ? "active" : ""}>
                        <BrowseIcon/>
                        <p>Browse</p>
                    </Link>
                </li>
                <li>
                    <Link to="/participated" className={path === "/participated" ? "active" : ""}>
                        <PartInIcon width={"2em"} height={"2em"}/>
                        <p>Participated in</p>
                    </Link>
                </li>
                <li>
                    <Link to="/myDebates" className={path === "/myDebates" ? "active" : ""}>
                        <MyDebatesIcon width={"2em"} height={"2em"}/>
                        <p>My Debates</p>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default Sidebar