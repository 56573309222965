import {
    FETCH_DEBATES,
    FETCH_HOME_MY_DEBATES,
    FETCH_HOME_NEWEST_DEBATES,
    FETCH_HOME_PARTIN_DEBATES,
    FETCH_HOME_SUBSCRIBED_DEBATES,
    CREATE_DEBATE_SUCCESS,
    SUBSCRIBE_DEBATE_SUCCESS,
    UNSUBSCRIBE_DEBATE_SUCCESS,
    RESET_HOME_COUNT, RESET_LOADED, CHANGE_ACTIVE, INCREASE_SKIP, RESET_ITEMS
} from "./types";
import { objToQueryString, baseUrl } from "../functions.js"
import axios from "axios";
import {changeSkip} from "./filterActions";
import {resetSkip} from "./skipActions";

const listToQueryString = (list) => {
    let res = "";
    for(const index in list) {
        res = res + `&tags=${list[index]}`
    }
    return res
}

export const resetLoaded = () => dispatch => {
    dispatch({
        type: RESET_LOADED
    })
}

export const resetHomeCount = () => dispatch => {
    dispatch({
        type: RESET_HOME_COUNT
    })
}

export const resetItems = () => dispatch => {
    dispatch({
        type: RESET_ITEMS
    })
}

export const fetchAllDebates = ( {filters, token, limit, skip} ) => dispatch => {
        const queryString = objToQueryString({
            limit: limit,
            skip: skip,
            type: filters.type,
            date: filters.date,
            sort: filters.sort,
            input: filters.input,
            mode: filters.mode,
            debateType: filters.debateType
        })
        const tagsQueryString = listToQueryString(filters.tags)
        const tokenString = token === undefined ? "" : `/token/${token}`
        axios({
            method: 'get',
            url: `${baseUrl}/searchDebates/all${tokenString}?${queryString}${tagsQueryString}`,
        })
        .then(res => res.data)
        .then(debates => dispatch({
            type: FETCH_DEBATES,
            payload: debates
        }))
        .then(()=> dispatch({
            type: INCREASE_SKIP
        }))
}

export const fetchSubscribedDebates = ( {filters, token,  limit, skip} ) => dispatch => {
    const queryString = objToQueryString({
        limit: limit,
        skip: skip,
        type: filters.type,
        date: filters.date,
        sort: filters.sort,
        input: filters.input,
        mode: filters.mode,
        debateType: filters.debateType
    })
    const tagsQueryString = listToQueryString(filters.tags)

    axios({
        method: 'get',
        url: `${baseUrl}/debatefeed/token/${token}?${queryString}${tagsQueryString}`,
    })
        .then(res => res.data)
        .then(debates => dispatch({
            type: FETCH_DEBATES,
            payload: debates
        }))
        .then(()=> dispatch({
            type: INCREASE_SKIP
        }))
}

export const fetchParticipatedIn = ( {filters, token, limit, skip} ) => dispatch => {
    const queryString = objToQueryString({
        limit: limit,
        skip: skip,
        type: filters.type,
        date: filters.date,
        sort: filters.sort,
        input: filters.input,
        mode: filters.mode,
        debateType: filters.debateType
    })
    const tagsQueryString = listToQueryString(filters.tags)

    axios({
        method: 'get',
        url: `${baseUrl}/gparticipated/token/${token}?${queryString}${tagsQueryString}`,
    })
        .then(res => res.data)
        .then(debates => dispatch({
            type: FETCH_DEBATES,
            payload: debates
        }))
        .then(()=> dispatch({
            type: INCREASE_SKIP
        }))
}

export const fetchMyDebates = ( {filters, token, skip, limit} ) => dispatch => {
    const queryString = objToQueryString({
        limit: limit,
        skip: skip,
        type: filters.type,
        date: filters.date,
        sort: filters.sort,
        input: filters.input,
        mode: filters.mode,
        debateType: filters.debateType
    })
    const tagsQueryString = listToQueryString(filters.tags)

    axios({
        method: 'get',
        url: `${baseUrl}/myDebates/token/${token}?${queryString}${tagsQueryString}`,
    })
        .then(res => res.data)
        .then(debates => dispatch({
            type: FETCH_DEBATES,
            payload: debates
        }))
        .then(()=> dispatch({
            type: INCREASE_SKIP
        }))
}

export const fetchHomeNewestDebates = ( {token} ) => dispatch => {
    const queryString = objToQueryString({
        limit: "5",
        skip: "0",
        type: "0",
        date: "0",
        sort: "newest",
        input: "",
        mode: "0",
        debateType: ""
    })
    fetch(`${baseUrl}/searchDebates/all/token/${token}?${queryString}`)
        .then(res => res.json())
        .then(debates => dispatch({
            type: FETCH_HOME_NEWEST_DEBATES,
            payload: debates
        }))
}

export const fetchHomeSubscribedDebates = ( {token} ) => dispatch => {
    const queryString = objToQueryString({
        limit: "5",
        skip: "0",
        type: "0",
        date: "0",
        sort: "newest",
        input: "",
        mode: "0",
        debateType: ""
    })
    fetch(`${baseUrl}/debatefeed/token/${token}?${queryString}`)
        .then(res => res.json())
        .then(debates => dispatch({
            type: FETCH_HOME_SUBSCRIBED_DEBATES,
            payload: debates
        }))
}

export const fetchHomePartInDebates = ( {token} ) => dispatch => {
    const queryString = objToQueryString({
        limit: "5",
        skip: "0",
        type: "0",
        date: "0",
        sort: "sizeDesc",
        input: "",
        mode: "0",
        debateType: ""
    })
    fetch(`${baseUrl}/gparticipated/token/${token}?${queryString}`)
        .then(res => res.json())
        .then(debates => dispatch({
            type: FETCH_HOME_PARTIN_DEBATES,
            payload: debates
        }))
}

export const fetchHomeMyDebates = ( {token} ) => dispatch => {
    const queryString = objToQueryString({
        limit: "5",
        skip: "0",
        type: "0",
        date: "0",
        sort: "newest",
        input: "",
        mode: "0",
        debateType: ""
    })
    fetch(`${baseUrl}/myDebates/token/${token}?${queryString}`)
        .then(res => res.json())
        .then(debates => dispatch({
            type: FETCH_HOME_MY_DEBATES,
            payload: debates
        }))
}

export const createDebate = ( {open, title, previewColor, thumbnail, tags, ownerId} ) => dispatch => {
    console.log("creating debate with tags:")
    console.log(tags)
    axios({
        method: 'post',
        url: `${baseUrl}/addgraph`,
        data: {
            open: open,
            title: title,
            previewColor: previewColor,
            thumbnail: thumbnail,
            hasInitialArguments: false,
            tags: tags,
            ownerId: ownerId,
            type: "free"
        }
    })
        .then(res => res.data)
        .then(id => dispatch({
            type: CREATE_DEBATE_SUCCESS,
            payload: id
        }))
        .catch(err => {
            console.log("There was an error when trying to create a new debate.")
        })
}

export const createAlternativeDebate = ( {open, title, previewColor, thumbnail, tags, ownerId, alternativeArguments, userToken} ) => dispatch => {
    axios({
        method: 'post',
        url: `${baseUrl}/addgraph`,
        data: {
            open: open,
            title: title,
            previewColor: previewColor,
            thumbnail: thumbnail,
            hasInitialArguments: true,
            tags: tags,
            ownerId: ownerId,
            type: "free"
        }
    })
        .then(res => res.data)
        .then(id => {
            dispatch(addAlternativeArguments({
                graphId: id,
                userId: ownerId,
                userToken: userToken,
                args: alternativeArguments
            }))
        })
}

export const createTreeDebate = ( {open, title, previewColor, thumbnail, tags, ownerId, text, upload, userToken } ) => dispatch => {
    axios({
        method: 'post',
        url: `${baseUrl}/addgraph`,
        data: {
            open: open,
            title: title,
            previewColor: previewColor,
            thumbnail: thumbnail,
            hasInitialArguments: false,
            tags: tags,
            ownerId: ownerId,
            type: "tree"
        }
    })
        .then(res => res.data)
        .then(id => dispatch(addRootNode({
            graphId: id,
            userId: ownerId,
            text: text,
            upload: upload,
            userToken: userToken
        })))
        .catch(err => {
            console.log("There was an error when trying to create a new debate.")
        })
}

export const addAlternativeArguments = ( {graphId, userId, userToken, args} ) => dispatch => {
    let argsToSend = args.map(function(d) {
        return {
            id: 0,
            posVotes: 1,
            negVotes: 0,
            userId: userId,
            weight: 0,
            arg: "",
            text: d.text,
            upload: d.upload,
            icon: "",
            isInitial: true
        }
    })
    axios({
        method: 'post',
        url: `${baseUrl}/addInitialNodes/${graphId}/${userToken}`,
        data: argsToSend
    })
        .then(res => res.data)
        .then(id => dispatch({
            type: CREATE_DEBATE_SUCCESS,
            payload: id
        }))
        .catch(err => {
            console.log("There was an error when trying to add an alternative argument to the new debate")
        })
}

export const addRootNode = ( {graphId, userId, text, upload, userToken} ) => dispatch => {
    console.log("adding root node")
    axios({
        method: 'post',
        url: `${baseUrl}/addnode/${graphId}/not/${userToken}/0`,
        data: {
            id: 0,
            posVotes: 1,
            negVotes: 0,
            userId: userId,
            weight: 0,
            arg: "",
            text: text,
            upload: upload,
            icon: ""
        }
    })
        .then(res => res.data)
        .then(node => dispatch({
            type: CREATE_DEBATE_SUCCESS,
            payload: node.graphId
        }))
        .catch(err => {
            console.log("There was an error when trying to add a root argument to the new debate")
        })
}

export const subscribeDebate = ( {graphId, token} ) => dispatch => {
    axios({
        method: 'post',
        url: `${baseUrl}/gsubscribed/${token}`,
        data: graphId,
        headers: {
            'Content-type': 'application/json'
        }
    })
        .then( () => dispatch({
            type: SUBSCRIBE_DEBATE_SUCCESS,
            graphId: graphId
        }))
        .catch(err => {
            console.log("There was an error when subscribing to the debate")
        })
}

export const unsubscribeDebate = ( {graphId, token} ) => dispatch => {
    axios({
        method: 'delete',
        url: `${baseUrl}/unsubscribe/${token}`,
        data: graphId,
        headers: {
            'Content-type': 'application/json'
        },

    })
        .then( () => dispatch({
            type: UNSUBSCRIBE_DEBATE_SUCCESS,
            graphId: graphId
        }))
        .catch(err => {
            console.log("There was an error when unsubscribing to the debate")
        })
}

export const loadActiveDebateInfo = ( { graphId } ) => dispatch => {
    axios({
        method: 'get',
        url: `${baseUrl}/graphInfo/${graphId}`,
    })
        .then(res => res.data)
        .then(debate => dispatch({
            type: CHANGE_ACTIVE,
            debate: debate
        }))
}

export const changeActiveDebate = ( {debate} ) => dispatch => {
    dispatch({
        type: CHANGE_ACTIVE,
        debate: debate
    })
}