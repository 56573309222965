import React from "react"
import DebatePlaceholder from "./DebatePlaceholder";

const HomeSectionPlaceholder = () => {
    return (
        <div className="ml-5 mb-4 mt-3">
            <div className="mb-1 ml-1">
                <div className="title-placeholder placeholder"/>
                <div className="seeAll-placeholder placeholder"/>
            </div>
            <div className="homeSection_debates">
                <DebatePlaceholder/>
                <DebatePlaceholder/>
                <DebatePlaceholder/>
                <DebatePlaceholder/>
                <DebatePlaceholder/>
            </div>
            <hr/>
        </div>
    )
}

export default HomeSectionPlaceholder