import React, {useState} from "react";
import logo from '../images/logo.png'
import AddDebateModal from "./AddDebateModal";
import GoogleButton from "./GoogleButton";
import { connect } from "react-redux";
import {FormControl, InputGroup, OverlayTrigger} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Search, ArrowLeftShort} from "react-bootstrap-icons";
import HoverTooltip from "./HoverTooltip";
import { useLocation, useHistory } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faFilter} from "@fortawesome/free-solid-svg-icons";
import settingsIcon from "../images/settings.png";
import tutorialIcon from "../images/question-mark-square.png";

const Header = ( props ) => {
    const [fullSearch, setFullSearch] = useState(false);
    const [input, setInput] = useState("")
    const path = useLocation().pathname
    const history = useHistory()

    const handleFullSearch = () => { setFullSearch(!fullSearch)}

    const isDebate = path.includes("/debate")

    const handleSearch = () => {
        history.push("/browse?input=" + input)
    }

    const handleSearchKeyDown = (e) => {
        if(e.key === 'Enter')
            handleSearch();
    }

    return (
        <div className={isDebate ? "interfaceHeader position-absolute w-100 d-flex p-2 bg-none" : "container-fluid header_container position-fixed top-0 start-0 bg-white"}>
            <div className={isDebate ? "row w-100 justify-content-between" : 'row h-100 align-items-center justify-content-between'}>
                {!fullSearch ? (
                    <>
                        {isDebate ? (
                            <>
                                <div className="row pl-2" style={{zIndex: 1000}}>
                                    <div className="col">
                                        <div id="menuButton">
                                            <button type="button" id="sidebarCollapse" className="button_hover">
                                                <FontAwesomeIcon icon={faBars}/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 0, hide: 100 }}
                                            overlay={
                                                <HoverTooltip text="Filter"/>
                                            }
                                        >
                                            <div className="filtersDiv">
                                                <button id="dropdownFilters" className="dropdown-toggle button_hover" type="button">
                                                    <FontAwesomeIcon icon={faFilter}/>
                                                    <span className="caret"/>
                                                </button>
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="col">
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 0, hide: 100 }}
                                            overlay={
                                                <HoverTooltip text="Settings" className="settings_tooltip"/>
                                            }
                                        >
                                            <div className="settingsDiv pt-1">
                                                <button id="settingsButton">
                                                    <img src={settingsIcon} className="button_hover" />
                                                </button>
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="col">
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 0, hide: 100 }}
                                            overlay={
                                                <HoverTooltip text="Tutorial"/>
                                            }
                                        >
                                            <div className="tutorialDiv pt-1">
                                                <button id="tutorialButton">
                                                    <img src={tutorialIcon} className="button_hover"/>
                                                </button>
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='col'>
                                    <img src={logo} alt='logo'/>
                                </div>
                                <div className={`col-4 d-none justify-content-center 
                                            ${path.includes("/browse") ? "" : "d-sm-flex"}`}>
                                    <InputGroup className="w-100">
                                        <FormControl placeholder="Browse all debates" value={input} onKeyDown={handleSearchKeyDown} onChange={(e) => {setInput(e.target.value)}}/>
                                        <InputGroup.Append>
                                            <Button onClick={handleSearch} variant="dark" style={ {backgroundColor: "#3e4b56"}}> <i className="fa fa-search"/></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                            </>
                        )}
                        <div className='col align-items-center justify-content-end d-flex flex-row' style={{zIndex: "1000"}}>
                            {!isDebate && (
                                <>
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 0, hide: 100 }}
                                        overlay={
                                            <HoverTooltip text="Search debates"/>
                                        }
                                    >
                                        <Search className={`${path.includes("/browse") ? "d-none" : "d-sm-none"} mr-3 search_icon p-2`} size={35} onClick={handleFullSearch}/>
                                    </OverlayTrigger>
                                    <div className="mr-3">
                                        {props.loaded && props.swargProfile !== null && (
                                            <AddDebateModal/>
                                        )}
                                    </div>
                                </>
                             )}
                            <GoogleButton/>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-2 d-flex justify-content-center">
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 0, hide: 100 }}
                                overlay={
                                    <HoverTooltip text="Back"/>
                                }
                            >
                                <ArrowLeftShort className="arrow_back" size={45} onClick={handleFullSearch}/>
                            </OverlayTrigger>
                        </div>
                        <div className="col">
                            <InputGroup className="w-100">
                                <FormControl placeholder="Search debates"/>
                                <InputGroup.Append>
                                    <Button variant="dark" style={ {backgroundColor: "#3e4b56"}}> <i className="fa fa-search"/></Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    loaded: state.google.loaded,
    swargProfile: state.google.swargProfile
})

export default connect(mapStateToProps, null)(Header)