import React, {useEffect, useState} from "react";
import CardDeck from "react-bootstrap/Card";
import DebateCard from "./DebateCard";
import { Link } from "react-router-dom";
import {connect} from "react-redux";

const HomeSection = ( {title, link, debates} ) => {

    return (
        <>
            {debates.length > 0 && (
                <div className="homeSection ml-5 mb-4 mt-3">
                    <div className="homeSection_title">
                        <h4>{title}</h4>
                        <Link to={link}>SEE ALL</Link>
                    </div>
                    <div className="homeSection_debates">
                        {debates.map( (debate) => (<DebateCard key={debate.graphId} debate={debate}/>))}
                    </div>
                    <hr/>
                </div>
            )}
        </>

    )
}

export default HomeSection