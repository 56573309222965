import {
    CHANGE_ACTIVE,
    FETCH_DEBATES,
    FETCH_HOME_MY_DEBATES,
    FETCH_HOME_NEWEST_DEBATES,
    FETCH_HOME_PARTIN_DEBATES,
    FETCH_HOME_SUBSCRIBED_DEBATES,
    RESET_HOME_COUNT,
    RESET_ITEMS,
    RESET_LOADED,
    SUBSCRIBE_DEBATE_SUCCESS,
    UNSUBSCRIBE_DEBATE_SUCCESS
} from "../actions/types";
import {DEBATE_LIMIT} from "../functions";

const initialState = {
    items: [],
    newest: [],
    subscribed: [],
    partIn: [],
    myDebates: [],
    loadedCount: 0,
    loaded: false,
    reset: true,
    activeDebate: null,
    hasMore: true
}

const updateSubscribed = (graphId, list, isSubscribed) => {
    const res = list.map( (item) => {
        if(item.graphId === graphId) {
            return {
                ...item,
                isSubscribed: isSubscribed
            }
        }
        else return item;
    })

    return res;
}

const deleteSubscribed = (graphId, list) => {
    return list.filter( (debate) => debate.graphId !== graphId)
}

export default function debatesReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_LOADED:
            return {
                ...state,
                loaded: false
            }
        case RESET_HOME_COUNT:
            return {
                ...state,
                loadedCount: 0
            }
        case RESET_ITEMS:
            return {
                ...state,
                items: [],
                reset: true,
                hasMore: true
            }
        case FETCH_DEBATES:
            return {
                ...state,
                reset: false,
                loaded: true,
                items: [...state.items, ...action.payload],
                hasMore: !(action.payload.length < DEBATE_LIMIT)
            }

        case FETCH_HOME_NEWEST_DEBATES:
            return {
                ...state,
                loadedCount: state.loadedCount + 1,
                newest: action.payload
            }
        case FETCH_HOME_SUBSCRIBED_DEBATES:
            return {
                ...state,
                loadedCount: state.loadedCount + 1,
                subscribed: action.payload
            }
        case FETCH_HOME_PARTIN_DEBATES:
            return {
                ...state,
                loadedCount: state.loadedCount + 1,
                partIn: action.payload
            }
        case FETCH_HOME_MY_DEBATES:
            return {
                ...state,
                loadedCount: state.loadedCount + 1,
                myDebates: action.payload
            }
        case SUBSCRIBE_DEBATE_SUCCESS:
            const itemsNew = updateSubscribed(action.graphId, state.items, true)
            const newestNew = updateSubscribed(action.graphId, state.newest, true)
            const partInNew = updateSubscribed(action.graphId, state.partIn, true)
            const myDebatesNew = updateSubscribed(action.graphId, state.myDebates, true)
            return {
                ...state,
                items: itemsNew,
                newest: newestNew,
                partIn: partInNew,
                myDebates: myDebatesNew
            }
        case UNSUBSCRIBE_DEBATE_SUCCESS:
            const itemsNeww = updateSubscribed(action.graphId, state.items, false)
            const newestNeww = updateSubscribed(action.graphId, state.newest, false)
            const partInNeww = updateSubscribed(action.graphId, state.partIn, false)
            const myDebatesNeww = updateSubscribed(action.graphId, state.myDebates, false)
            return {
                ...state,
                items: itemsNeww,
                newest: newestNeww,
                partIn: partInNeww,
                myDebates: myDebatesNeww
            }
        case CHANGE_ACTIVE:
            return {
                ...state,
                activeDebate: action.debate
            }
        default:
            return state;
    }
}