import React, {Fragment} from "react";
import {BrowserRouter as Router, Route, Redirect, Switch} from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import {Provider} from 'react-redux';
import store from "./store";
import Browse from "./components/Browse";
import Subscribed from "./components/Subscribed";
import ParticipatedIn from "./components/ParticipatedIn";
import MyDebates from "./components/MyDebates";
import ForceGraph from "./components/ForceGraph";
import Sidebar from "./components/Sidebar";

const App = (props) => (
        <Provider store={store}>
            <Router>
                <Switch>
                    <Fragment>
                        <Header/>
                        <Sidebar/>
                        <Route path="/debate/:id" exact render={ (props) => (
                            <ForceGraph graphId={props.match.params.id}/>
                        )}/>
                        <Route path="/home" exact render={(props) => (
                            <Home/>
                        )}/>
                        <Route path="/subscribed" exact render={(props) => (
                            <Subscribed/>
                        )}/>
                        <Route path="/browse" exact render={(props) => (
                            <Browse/>
                        )}/>
                        <Route path="/participated" exact render={(props) => (
                            <ParticipatedIn/>
                        )}/>
                        <Route path="/myDebates" exact render={(props) => (
                            <MyDebates/>
                        )}/>
                        <Route exact path="/">
                            <Redirect to="/home"/>
                        </Route>
                    </Fragment>
                </Switch>
            </Router>
        </Provider>
    )

export default App;
