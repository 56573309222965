import {HIDE_CREATE_DEBATE_SUCCESS, CREATE_DEBATE_SUCCESS} from "../actions/types";

const initialState = {
    success: false,
    id: null
}

export default function successReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_DEBATE_SUCCESS:
            return {
                ...state,
                success: true,
                id: action.payload
            }
        case HIDE_CREATE_DEBATE_SUCCESS:
            return {
                ...state,
                success: false,
                id: null
            }
        default:
            return state;
    }
}