import { combineReducers } from "redux";
import debatesReducer from './debatesReducer'
import filterReducer from './filterReducer'
import successReducer from "./successReducer";
import {googleReducer} from "./googleReducer";
import tagsReducer from "./tagsReducer";
import skipReducer from "./skipReducer";

export default combineReducers({
    debates: debatesReducer,
    filters: filterReducer,
    success: successReducer,
    google: googleReducer,
    tags: tagsReducer,
    skip: skipReducer
})