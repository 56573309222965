import * as d3 from "d3";
import $ from 'jquery'
import pinTransparentIcon from "../images/push-pin-transparent.png";
import {
    abbreviateNumber, ATTACK_HEAD,
    baseUrl, calcColor, calcStrokeWidth, DEFAULT_COLOR_PATERN,
    DEFAULT_SEMANTIC, getArrowImg, getBackgroundGradient, getBorderColor, MAX_FILES, saveUserSettings, shadeColor,
    sidebarCollapseClick, signInTooltip,
    STROKE_COLOR_ATTACK,
    STROKE_COLOR_SUPPORT, SUPPORT_HEAD
} from "../functions";
import Plyr from "plyr";
import trunk8 from "trunk8";
import nodeMinus from "../images/node-minus.svg"
import nodePlus from "../images/node-plus.svg"
import caretRightFill from "../images/caret-right-fill.svg"
import caretRight from "../images/caret-right.svg"
import chevronRight from "../images/chevron-right.svg"

const changeMarkerEnds = (svg, select, type) => { //type: "Attack" or "Support"
    // remove current heads
    $('#end' + type + ', #fade' + type + ', #highlight' + type ).parent().remove()

    switch ($(select).children("option:selected").val()) {
        case "og":
            //normal heads
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "0 -5 10 10")
                .attr("refX", 7)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M0,-5L10,0L0,5")
                .attr("fill", $('#color' + type + 's').val());

            //faded heads
            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "0 -5 10 10")
                .attr("refX", 7)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M0,-5L10,0L0,5")
                .attr("fill", $('#color' + type + 's').val());

            //highlight heads
            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "0 -5 10 10")
                .attr("refX", 7)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M0,-5L10,0L0,5")
                .attr("fill", "black")
            break;
        //this is the value from the selected color in the color input, either "#colorAttacks" or "#colorSupports"
        case "roundArrow":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-20 -278 975 560")
                .attr("refX", 200)
                .attr("refY", 0)
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 410.413 -56.588 L 46.347 -265.084 c -52.938 -30.318 -95.852 -5.44 -95.852 55.563 v 415.652 c 0 61.004 42.914 85.882 95.852 55.563 l 364.066 -208.49 C 463.351 22.887 463.351 -26.269 410.413 -56.588 z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-20 -278 975 560")
                .attr("refX", 200)
                .attr("refY", 0)
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 410.413 -56.588 L 46.347 -265.084 c -52.938 -30.318 -95.852 -5.44 -95.852 55.563 v 415.652 c 0 61.004 42.914 85.882 95.852 55.563 l 364.066 -208.49 C 463.351 22.887 463.351 -26.269 410.413 -56.588 z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-20 -278 975 560")
                .attr("refX", 200)
                .attr("refY", 0)
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 410.413 -56.588 L 46.347 -265.084 c -52.938 -30.318 -95.852 -5.44 -95.852 55.563 v 415.652 c 0 61.004 42.914 85.882 95.852 55.563 l 364.066 -208.49 C 463.351 22.887 463.351 -26.269 410.413 -56.588 z")
                .attr("fill", "black");
            break;
        case "unevenArrow":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-80 -213 760 440")
                .attr("refX", 230)
                .attr("refY", 0)
                .attr("x", "0")
                .attr("y", "0")
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 438.731 -14.537 l -416 -192 c -6.624 -3.008 -14.528 -1.216 -19.136 4.48 c -4.64 5.696 -4.8 13.792 -0.384 19.648 l 136.8 182.4 l -136.8 182.4 c -4.416 5.856 -4.256 13.984 0.352 19.648 c 3.104 3.872 7.744 5.952 12.448 5.952 c 2.272 0 4.544 -0.48 6.688 -1.472 l 416 -192 c 5.696 -2.624 9.312 -8.288 9.312 -14.528 S 444.395 -11.913 438.731 -14.537 z")
                .attr("fill", $('#color' + type + 's').val())

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-80 -213 760 440")
                .attr("refX", 230)
                .attr("refY", 0)
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 438.731 -14.537 l -416 -192 c -6.624 -3.008 -14.528 -1.216 -19.136 4.48 c -4.64 5.696 -4.8 13.792 -0.384 19.648 l 136.8 182.4 l -136.8 182.4 c -4.416 5.856 -4.256 13.984 0.352 19.648 c 3.104 3.872 7.744 5.952 12.448 5.952 c 2.272 0 4.544 -0.48 6.688 -1.472 l 416 -192 c 5.696 -2.624 9.312 -8.288 9.312 -14.528 S 444.395 -11.913 438.731 -14.537 z")
                .attr("fill", $('#color' + type + 's').val())


            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-80 -213 760 440")
                .attr("refX", 230)
                .attr("refY", 0)
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 438.731 -14.537 l -416 -192 c -6.624 -3.008 -14.528 -1.216 -19.136 4.48 c -4.64 5.696 -4.8 13.792 -0.384 19.648 l 136.8 182.4 l -136.8 182.4 c -4.416 5.856 -4.256 13.984 0.352 19.648 c 3.104 3.872 7.744 5.952 12.448 5.952 c 2.272 0 4.544 -0.48 6.688 -1.472 l 416 -192 c 5.696 -2.624 9.312 -8.288 9.312 -14.528 S 444.395 -11.913 438.731 -14.537 z")
                .attr("fill", "black")
            break;
        case "hexagon":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -169 605 335")
                .attr("refX", 230)
                .attr("refY", 0)
                .attr("x", "0")
                .attr("y", "0")
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 288 166.28 C 288 166.28 96 166.28 96 166.28 C 96 166.28 0 0 0 0 C 0 0 96 -166.28 96 -166.28 C 96 -166.28 288 -166.28 288 -166.28 C 288 -166.28 384 0 384 0 C 384 0 288 166.28 288 166.28 Z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -169 605 335")
                .attr("refX", 230)
                .attr("refY", 0)
                .attr("x", "0")
                .attr("y", "0")
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 288 166.28 C 288 166.28 96 166.28 96 166.28 C 96 166.28 0 0 0 0 C 0 0 96 -166.28 96 -166.28 C 96 -166.28 288 -166.28 288 -166.28 C 288 -166.28 384 0 384 0 C 384 0 288 166.28 288 166.28 Z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -169 605 335")
                .attr("refX", 230)
                .attr("refY", 0)
                .attr("x", "0")
                .attr("y", "0")
                .attr("markerWidth", 7)
                .attr("markerHeight", 7)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 288 166.28 C 288 166.28 96 166.28 96 166.28 C 96 166.28 0 0 0 0 C 0 0 96 -166.28 96 -166.28 C 96 -166.28 288 -166.28 288 -166.28 C 288 -166.28 384 0 384 0 C 384 0 288 166.28 288 166.28 Z")
                .attr("fill", "black");
            break;
        case "square":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-5 -5 10 10")
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 3.5)
                .attr("markerHeight", 3.5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 0,0 m -5,-5 L 5,-5 L 5,5 L -5,5 Z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-5 -5 10 10")
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 3.5)
                .attr("markerHeight", 3.5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 0,0 m -5,-5 L 5,-5 L 5,5 L -5,5 Z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-5 -5 10 10")
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 3.5)
                .attr("markerHeight", 3.5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 0,0 m -5,-5 L 5,-5 L 5,5 L -5,5 Z")
                .attr("fill", "black");
            break;
        case "abs_square":
            var lel = svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -251 851 500")
                .attr("refX", 100)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")

            lel.append("svg:path")
                .attr("d", "M 77.38 -170.81 C 77.38 -170.81 412.61 -170.81 412.61 -170.81 C 412.61 -170.81 412.62 160.81 412.62 160.81 C 412.62 160.81 77.38 160.81 77.38 160.81 C 77.38 160.81 0 240 0 240 C 0 240 490 240 490 240 C 490 240 490 -250 490 -250 C 490 -250 0 -250 0 -250 C 0 -250 77.38 -170.81 77.38 -170.81 Z")
                .attr("fill", $('#color' + type + 's').val())

            lel.append("svg:path")
                .attr("d", "M 152.54 87.46 C 152.54 87.46 152.54 -97.46 152.54 -97.46 C 152.54 -97.46 337.46 -97.46 337.46 -97.46 C 337.46 -97.46 337.46 87.46 337.46 87.46 C 337.46 87.46 152.54 87.46 152.54 87.46 Z")
                .attr("fill", $('#color' + type + 's').val());

            var fade = svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -251 851 500")
                .attr("refX", 100)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")

            fade.append("svg:path")
                .attr("d", "M 77.38 -170.81 C 77.38 -170.81 412.61 -170.81 412.61 -170.81 C 412.61 -170.81 412.62 160.81 412.62 160.81 C 412.62 160.81 77.38 160.81 77.38 160.81 C 77.38 160.81 0 240 0 240 C 0 240 490 240 490 240 C 490 240 490 -250 490 -250 C 490 -250 0 -250 0 -250 C 0 -250 77.38 -170.81 77.38 -170.81 Z")
                .attr("fill", $('#color' + type + 's').val())

            fade.append("svg:path")
                .attr("d", "M 152.54 87.46 C 152.54 87.46 152.54 -97.46 152.54 -97.46 C 152.54 -97.46 337.46 -97.46 337.46 -97.46 C 337.46 -97.46 337.46 87.46 337.46 87.46 C 337.46 87.46 152.54 87.46 152.54 87.46 Z")
                .attr("fill", $('#color' + type + 's').val());

            var highlight = svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -251 851 500")
                .attr("refX", 100)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")

            highlight.append("svg:path")
                .attr("d", "M 77.38 -170.81 C 77.38 -170.81 412.61 -170.81 412.61 -170.81 C 412.61 -170.81 412.62 160.81 412.62 160.81 C 412.62 160.81 77.38 160.81 77.38 160.81 C 77.38 160.81 0 240 0 240 C 0 240 490 240 490 240 C 490 240 490 -250 490 -250 C 490 -250 0 -250 0 -250 C 0 -250 77.38 -170.81 77.38 -170.81 Z")
                .attr("fill", "black")

            highlight.append("svg:path")
                .attr("d", "M 152.54 87.46 C 152.54 87.46 152.54 -97.46 152.54 -97.46 C 152.54 -97.46 337.46 -97.46 337.46 -97.46 C 337.46 -97.46 337.46 87.46 337.46 87.46 C 337.46 87.46 152.54 87.46 152.54 87.46 Z")
                .attr("fill", "black");
            break;
        case "square_hole":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -246 851 492")
                .attr("refX", 110)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 490 -245 H 0 v 490 h 490 V -245 z M 245 130.156 c -71.883 0 -130.156 -58.273 -130.156 -130.156 c 0 -71.884 58.273 -130.156 130.156 -130.156 S 375.156 -71.884 375.156 0 C 375.156 71.883 316.883 130.156 245 130.156 z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -246 851 492")
                .attr("refX", 110)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 490 -245 H 0 v 490 h 490 V -245 z M 245 130.156 c -71.883 0 -130.156 -58.273 -130.156 -130.156 c 0 -71.884 58.273 -130.156 130.156 -130.156 S 375.156 -71.884 375.156 0 C 375.156 71.883 316.883 130.156 245 130.156 z")
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "-1 -246 851 492")
                .attr("refX", 110)
                .attr("refY", 0)
                .attr("markerWidth", 6)
                .attr("markerHeight", 6)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", "M 490 -245 H 0 v 490 h 490 V -245 z M 245 130.156 c -71.883 0 -130.156 -58.273 -130.156 -130.156 c 0 -71.884 58.273 -130.156 130.156 -130.156 S 375.156 -71.884 375.156 0 C 375.156 71.883 316.883 130.156 245 130.156 z")
                .attr("fill", "black");
            break;
        case "circle":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", '-6 -6 12 12')
                .attr("refX", 1)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", 'M 0, 0  m -5, 0  a 5,5 0 1,0 10,0  a 5,5 0 1,0 -10,0')
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", '-6 -6 12 12')
                .attr("refX", 1)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", 'M 0, 0  m -5, 0  a 5,5 0 1,0 10,0  a 5,5 0 1,0 -10,0')
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", '-6 -6 12 12')
                .attr("refX", 1)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", 'M 0, 0  m -5, 0  a 5,5 0 1,0 10,0  a 5,5 0 1,0 -10,0')
                .attr("fill", "black");
            break;
        case "slim_arrow":
            svg.append("svg:defs").selectAll("marker")
                .data(["end" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", '-12 -13 43 25')
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 8)
                .attr("markerHeight", 8)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", 'M 6.291 -1.724 L -3.295 -11.31 c -0.878 -0.878 -2.317 -0.878 -3.195 0 l -0.8 0.8 c -0.878 0.877 -0.878 2.316 0 3.194 L 0.024 0 l -7.315 7.315 c -0.878 0.878 -0.878 2.317 0 3.194 l 0.8 0.8 c 0.878 0.879 2.317 0.879 3.195 0 l 9.586 -9.587 c 0.472 -0.471 0.682 -1.103 0.647 -1.723 C 6.973 -0.62 6.763 -1.252 6.291 -1.724 z')
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["fade" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", '-12 -13 43 25')
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 8)
                .attr("markerHeight", 8)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", 'M 6.291 -1.724 L -3.295 -11.31 c -0.878 -0.878 -2.317 -0.878 -3.195 0 l -0.8 0.8 c -0.878 0.877 -0.878 2.316 0 3.194 L 0.024 0 l -7.315 7.315 c -0.878 0.878 -0.878 2.317 0 3.194 l 0.8 0.8 c 0.878 0.879 2.317 0.879 3.195 0 l 9.586 -9.587 c 0.472 -0.471 0.682 -1.103 0.647 -1.723 C 6.973 -0.62 6.763 -1.252 6.291 -1.724 z')
                .attr("fill", $('#color' + type + 's').val());

            svg.append("svg:defs").selectAll("marker")
                .data(["highlight" + type])      // Different link/path types can be defined here
                // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", '-12 -13 43 25')
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 8)
                .attr("markerHeight", 8)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("d", 'M 6.291 -1.724 L -3.295 -11.31 c -0.878 -0.878 -2.317 -0.878 -3.195 0 l -0.8 0.8 c -0.878 0.877 -0.878 2.316 0 3.194 L 0.024 0 l -7.315 7.315 c -0.878 0.878 -0.878 2.317 0 3.194 l 0.8 0.8 c 0.878 0.879 2.317 0.879 3.195 0 l 9.586 -9.587 c 0.472 -0.471 0.682 -1.103 0.647 -1.723 C 6.973 -0.62 6.763 -1.252 6.291 -1.724 z')
                .attr("fill", "black");
            break;
        default: break;
    }
}

export function runTreeGraph(
    container,
    graphId,
    currentUser,
    dispatch
) {
    //TODO TUTORIAL FOR TREE DEBATES - HIDING TUTORIAL BUTTON FOR NOW
    $('#tutorialButton').hide();


    let attack_color;
    let support_color;
    let colorPattern = DEFAULT_COLOR_PATERN;
    const updateSettings = (svg) => {
        if(currentUser != undefined && currentUser.color_pattern) {
            let color = currentUser.color_pattern;
            if(color === "default") {
                colorPattern = DEFAULT_COLOR_PATERN;
            }
            else {
                colorPattern = color;
            }
        }

        $('[name=colorPatterns]').val(colorPattern)
        $('#colorPattern-picker').css(getBackgroundGradient(colorPattern))
        $('#slider-background').css(getBackgroundGradient(colorPattern))

        $('#pattern-0').css(getBackgroundGradient("0"))
        $('#pattern-1').css(getBackgroundGradient("1"))
        $('#pattern-2').css(getBackgroundGradient("2"))
        $('#pattern-3').css(getBackgroundGradient("3"))
        $('#pattern-4').css(getBackgroundGradient("4"))
        $('#pattern-5').css(getBackgroundGradient("5"))
        $('#pattern-6').css(getBackgroundGradient("6"))
        $('#pattern-7').css(getBackgroundGradient("7"))
        $('#pattern-8').css(getBackgroundGradient("8"))

        nodes.forEach(function(d) {
            $('#'+d.data.id+'.foreignObject').find('#contentDiv')
                .css("background-color", calcColor(colorPattern, d.data))
                .css("border", getBorderColor(d.data))
        })

        if(currentUser != undefined && currentUser.attack_color) {
            let color = currentUser.attack_color;
            if(color == "default") {
                attack_color = STROKE_COLOR_ATTACK;
            }
            else {
                attack_color = color;
            }
        }
        else {
            attack_color = STROKE_COLOR_ATTACK;
        }

        $('#colorAttacks').attr("value", attack_color);
        $('#color-picker-attacks').css("background-color", attack_color);
        $('.path.links.attack').attr("stroke", attack_color)
        $('#endAttack').find("path").attr("fill", attack_color);
        $('#previewAttack').attr("stroke", attack_color)

        if(currentUser != undefined && currentUser.support_color) {
            let color = currentUser.support_color;
            if(color == "default") {
                support_color = STROKE_COLOR_SUPPORT;
            }
            else {
                support_color = color;
            }
        }
        else {
            support_color = STROKE_COLOR_SUPPORT;
        }

        $('#colorSupports').attr("value", support_color);
        $('#color-picker-supports').css("background-color", support_color);
        $('.path.links.support').attr("stroke", support_color)
        $('#endSupport').find("path").attr("fill", support_color);
        $('#previewSupport').attr("stroke", support_color)

        let attack_head;
        if(currentUser != undefined && currentUser.attack_head) {
            let head = currentUser.attack_head;
            if(head == "default") {
                attack_head = ATTACK_HEAD;
            }
            else {
                attack_head = head;
            }
        }
        else {
            attack_head = ATTACK_HEAD;
        }

        $('[name=Attack]').val(attack_head);
        $('#styles_attacks_button').find('img').attr("src", getArrowImg(attack_head))
        $('.style_div.attack.selected').removeClass('selected')
        $('[name=' + attack_head + '].style_div.attack').addClass('selected')
        changeMarkerEnds(svg, $('[name=Attack]'), $('[name=Attack]').attr("name"))

        let support_head;
        if(currentUser != undefined && currentUser.support_head) {
            let head = currentUser.support_head;
            if(head == "default") {
                support_head = SUPPORT_HEAD;
            }
            else {
                support_head = head;
            }
        }
        else {
            support_head = SUPPORT_HEAD;
        }

        $('[name=Support]').val(support_head);
        $('.style_div.support.selected').removeClass('selected')
        $('[name=' + support_head + '].style_div.support').addClass('selected')
        $('#styles_supports_button').find('img').attr("src", getArrowImg(support_head))
        changeMarkerEnds(svg, $('[name=Support]'), $('[name=Support]').attr("name"))

        let semantic;
        if(currentUser != undefined && currentUser.semantic) {
            let s = currentUser.semantic;
            if(s == "default") {
                semantic = DEFAULT_SEMANTIC;
            }
            else {
                semantic = s;
            }
        }
        else {
            semantic = DEFAULT_SEMANTIC;
        }

        $('[name=semantic]').val(semantic)

    }

    let treeData;
    let nodes = [];
    let links;
    var i = 0,
        duration = 500,
        root;

    var margin = {top: 50, right: 90, bottom: 30, left: 90},
        width = $('.wrapper').width() - margin.left - margin.right,
        height = $('.wrapper').height() - margin.top - margin.bottom;

    d3.select(container).select('svg').remove()
    var svg = d3.select(container).append("svg")
        .attr("id", "svgId")
        .attr("width", width + margin.right + margin.left)
        .attr("height", height + margin.top + margin.bottom)

    var g = svg
        .append("g")
        .attr("class", "everything")

    let link_g = g
        .append("g")
        .attr("class", "links")

    let fatLink_g = g
        .append("g")
        .attr("class", "fatlinks")

    let node_g = g
        .append("g")
        .attr("class", "nodes")

    svg.append("svg:defs").selectAll("marker")
        .data(["endAttack"])      // Different link/path types can be defined here
        // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
        .enter().append("svg:marker")    // This section adds in the arrows
        .attr("id", String)
        .attr("viewBox", '-6 -6 12 12')
        .attr("refX", 1)
        .attr("refY", 0)
        .attr("markerWidth", 5)
        .attr("markerHeight", 5)
        .attr("orient", "auto")
        .append("svg:path")
        .attr("d", 'M 0, 0  m -5, 0  a 5,5 0 1,0 10,0  a 5,5 0 1,0 -10,0')
        .attr("fill", "#7f5ea7");

    svg.append("svg:defs").selectAll("marker")
        .data(["endSupport"])      // Different link/path types can be defined here
        // .data([{id: "end", opacity: 1}, {id: "fade", opacity: 0.1}])      // Different link/path types can be defined here
        .enter().append("svg:marker")    // This section adds in the arrows
        .attr("id", String)
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 7)
        .attr("refY", 0)
        .attr("markerWidth", 5)
        .attr("markerHeight", 7)
        .attr("orient", "auto")
        .append("svg:path")
        .attr("d", "M0,-5L10,0L0,5")
        .attr("fill", STROKE_COLOR_SUPPORT);

    const zoom = d3
        .zoom()
        .filter(function(event) {
            return !event.button && event.type != "dblclick";
        })
        .scaleExtent([1 / 12, 4])
        .on('zoom', function (event) {
            zoom_actions(event);
        })

    function zoom_actions(event) {
        g.attr("transform", event.transform)
    }

    svg.call(zoom)

    // declares a tree layout and assigns the size
    var treemap = d3.tree()
        .size([height, width])
        .nodeSize([270,])
        .separation(function separation(a, b) {
            if(a.data.height === 270 || b.data.height === 270)
                return a.parent === b.parent ? 1 : 1;
            else
                return a.parent == b.parent ? 0.8 : 0.8;
        });


    updateSettings(svg)
    getGraph()

    function compareAndUpdate(node, tempNode) {
        if(node.data.id === tempNode.data.id) { //double check that we are comparing the same node
            if(node.data.positiveVotes != tempNode.data.positiveVotes) {
                node.data.positiveVOtes = tempNode.data.positiveVotes;
                $('#posVotes' + tempNode.data.id).fadeOut(400, function() {
                    $(this).html(tempNode.data.positiveVotes).fadeIn(400);
                });
            }
            if(node.data.negativeVotes != tempNode.data.negativeVotes) {
                node.data.negativeVotes = tempNode.data.negativeVotes;
                $('#negVotes' + tempNode.data.id).fadeOut(400, function() {
                    $(this).html(tempNode.data.negativeVotes).fadeIn(400);
                });
            }
            if(node.data.weight != tempNode.data.weight) {
                node.data.weight = tempNode.data.weight;
                //update background-color
                $('#'+tempNode.data.id+'.foreignObject').find('.contentDiv').css("background-color", calcColor(colorPattern, tempNode.data))
            }
            if(node.data.relationPosVotes != tempNode.data.relationPosVotes) {
                node.data.relationPosVotes = tempNode.data.relationPosVotes;
            }
            if(node.data.relationNegVotes != tempNode.data.relationNegVotes) {
                node.data.relationNegVotes = tempNode.data.relationNegVotes;
            }
            if(node.data.relationWeight != tempNode.data.relationWeight) {
                console.log("different weight")
                node.data.relationWeight = tempNode.data.relationWeight;
                $('#link' + tempNode.data.id).css("stroke-width", calcStrokeWidth(tempNode.data.relationWeight))
            }
            //if(node.data.id == 479) debugger;
            let sum = node.data.nAttacks + node.data.nSupports;
            let tempSum = tempNode.data.nAttacks + tempNode.data.nSupports;
            if(node.data.nAttacks != tempNode.data.nAttacks) {
                node.data.nAttacks = tempNode.data.nAttacks
            }
            if(node.data.nSupports != tempNode.data.nSupports) {
                node.data.nSupports = tempNode.data.nSupports;
            }

            if(node.children || node._children) { //has children
                if(node.children !== null) { //children are shown
                    let sizeDiff = tempNode.children.length - node.children.length
                    if(sizeDiff === 0) {
                        for(let i=0; i<node.children.length; i++)
                            compareAndUpdate(node.children[i], tempNode.children[i])
                    }
                    else {
                        let i;
                        for(i=0; i<node.children.length && sizeDiff > 0; i++) {
                            if(node.children[i].data.id !== tempNode.children[i].data.id) {
                                tempNode.children[i].data.width = 250;
                                tempNode.children[i].data.height = 170;
                                tempNode.children[i].data.filtered = false;
                                tempNode.children[i].data.expanded = true;
                                tempNode.children[i].removed = false;
                                tempNode.children[i].linkRemoved = false;
                                tempNode.children[i].fatLinkRemoved = false;
                                tempNode.children[i].parent = node;
                                if(tempNode.children[i].data.id === newArgId) {
                                    tempNode.children[i].data.typeOfVote = "positive"
                                    newArgId = -1;
                                }

                                node.children.splice(i, 0, tempNode.children[i])
                                sizeDiff--;
                            }
                            else {
                                compareAndUpdate(node.children[i], tempNode.children[i])
                            }
                        }
                        for(i; i<node.children.length; i++) {
                            compareAndUpdate(node.children[i], tempNode.children[i])
                        }
                        for(i; i<tempNode.children.length; i++) {
                            tempNode.children[i].data.width = 250;
                            tempNode.children[i].data.height = 170;
                            tempNode.children[i].data.filtered = false;
                            tempNode.children[i].data.expanded = true;
                            tempNode.children[i].removed = false;
                            tempNode.children[i].linkRemoved = false;
                            tempNode.children[i].fatLinkRemoved = false;
                            tempNode.children[i].parent = node;
                            if(tempNode.children[i].data.id === newArgId) {
                                tempNode.children[i].data.typeOfVote = "positive"
                                newArgId = -1;
                            }
                            node.children.push(tempNode.children[i])
                        }
                        update(node)
                        if(sum == 0 && tempSum != 0) {
                            d3.selectAll("#contentDiv" + node.data.id).append(function() {
                                return appendExpandIcon(node)
                            })
                        }
                    }
                }
                else if(node._children !== null) { //children are hidden
                    let sizeDiff = tempNode.children.length - node._children.length
                    if(sizeDiff === 0) {
                        for(let i=0; i<node._children.length; i++)
                            compareAndUpdate(node._children[i], tempNode.children[i])
                    }
                    else {
                        let i;
                        for(i=0; i<node._children.length && sizeDiff > 0; i++) {
                            if(node._children[i].data.id !== tempNode.children[i].data.id) {
                                tempNode.children[i].data.width = 250;
                                tempNode.children[i].data.height = 170;
                                tempNode.children[i].data.filtered = false;
                                tempNode.children[i].removed = false;
                                tempNode.children[i].linkRemoved = false;
                                tempNode.children[i].fatLinkRemoved = false;
                                tempNode.children[i].parent = node;
                                node._children.splice(i, 0, tempNode.children[i])
                                sizeDiff--;
                            }
                            else {
                                compareAndUpdate(node._children[i], tempNode.children[i])
                            }
                        }
                        for(i; i<node._children.length; i++) {
                            compareAndUpdate(node._children[i], tempNode.children[i])
                        }
                        for(i; i<tempNode.children.length; i++) {
                            tempNode.children[i].data.width = 250;
                            tempNode.children[i].data.height = 170;
                            tempNode.children[i].data.filtered = false;
                            tempNode.children[i].removed = false;
                            tempNode.children[i].linkRemoved = false;
                            tempNode.children[i].fatLinkRemoved = false;
                            tempNode.children[i].parent = node;
                            node._children.push(tempNode.children[i])
                        }
                        if(sum == 0 && tempSum != 0) {
                            d3.selectAll("#contentDiv" + node.data.id).append(function() {
                                return appendExpandIcon(node)
                            })
                        }
                    }
                }
            }
            else { //no children
                //see if it now has new children that need to be added
                if(tempNode.children) {
                    tempNode.children.forEach(function (d) {
                        d.data.width = 250;
                        d.data.height = 170;
                        d.data.filtered = false;
                        d.removed = false;
                        d.linkRemoved = false;
                        d.fatLinkRemoved = false;
                        d.parent = node;
                        if(d.data.id === newArgId) {
                            d.data.typeOfVote = "positive"
                            newArgId = -1;
                        }
                    })

                    node.children = tempNode.children
                    update(node)
                    if(sum == 0 && tempSum != 0) {
                        d3.selectAll("#contentDiv" + node.data.id).append(function() {
                            return appendExpandIcon(node)
                        })
                    }

                }
            }
        }
        else {
            console.log("An unexpected error occurred when trying to update the graph's weights")
        }
    }

    function reloadGraph() {
        let semantic;
        if(currentUser != undefined && currentUser.semantic != undefined &&
            currentUser.semantic != "default")
            semantic = currentUser.semantic
        else
            semantic = DEFAULT_SEMANTIC

        let url = baseUrl + "/treegraph/" + graphId + "/" + semantic;


        let json_nodes;
        let json_links;
        $.ajax({
            'async': true,
            'global': false,
            'url': url,
            'dataType': "json",
            'success': function (data) {
                let tempTreeData = data;
                let tempRoot = d3.hierarchy(tempTreeData, function (d) {
                    return d.children;
                });
                tempTreeData = treemap(tempRoot)
                let tempNodes = tempTreeData.descendants().reverse(),
                    tempLinks = tempTreeData.descendants().slice(1);

                compareAndUpdate(root, tempRoot)

                if(newParent !== null) {
                    zoomFitExpand(0.7, duration, newParent)
                    newParent = null
                }

                //check if any new arguments have been pushed
                let pushed = false;
               /* tempNodes.forEach(function (d) {
                    let exists = false;
                    console.log("nodesss")
                    console.log(nodes)
                    nodes.forEach(function (f) {
                        if(f.data.id === d.data.id){
                            exists = true;

                        }
                    })*/
                    /*if(!exists) {
                        console.log("new node, finding parent")
                        console.log(d)
                        d.data.width = 250;
                        d.data.height = 170;
                        d.data.filtered = false;
                        d.removed = false;
                        d.linkRemoved = false;
                        d.fatLinkRemoved = false;

                        let tempParent = d.parent;
                        let parent = getNode(d.parent.data.id)
                        if(parent.children || parent._children) { //parent already has children
                            if(parent.children === null) { //children are hidden
                                parent._children = tempParent.children;
                            }
                            else { //children are being shown
                                parent.children = tempParent.children;
                            }
                        }
                        else {
                            parent.children = tempParent.children;
                        }
                        pushed = true;
                    }*/
                //})
                //if(pushed) update(root)
            }
        });
    }

    function intervalFunction() {
        console.log("reloading graph")
        reloadGraph()
    }

     let reloadInterval = setInterval(intervalFunction, 5000)


    //update(root);

    function votedByUser(successFunc) {
        if(currentUser.token != undefined){
            console.log("getting user votes")
            $.ajax({
                'async': false,
                'global': false,
                "headers": {
                    "authorization": "Bearer "+ currentUser.token},
                'url': baseUrl + "/getuservotes/"+ currentUser.token +"/g/"+ graphId,
                'dataType': "json",
                'success': function (data) {
                    let json = data;
                    nodes.forEach(function (d) {
                        json.votedNodes.forEach(function (f) {
                            if(d.data.id == f.nid){
                                d.data.typeOfVote = f.typeOfVote;
                            }
                        });
                        d.data.typeOfRelationVote = "";
                        json.posEdges.forEach(function (f) {
                            if(d.data.lid == f)
                                d.data.typeOfRelationVote = "positive";
                        });
                        json.negEdges.forEach(function (f) {
                            if(d.data.lid == f)
                                d.data.typeOfRelationVote = "negative";
                        });
                    });

                    if(successFunc) successFunc()

                    //update(root)
                    //zoomFitInitial(0, 0, root)
                }
            });
        }
    }

    function getGraph() {
        let semantic;
        if(currentUser != undefined && currentUser.semantic != undefined &&
            currentUser.semantic != "default")
            semantic = currentUser.semantic
        else
            semantic = DEFAULT_SEMANTIC

        let url = baseUrl + "/treegraph/" + graphId + "/" + semantic;

        $.ajax({
            'async': true,
            'global': false,
            'url': url,
            'dataType': "json",
            'success': function (data) {
                treeData = data;
                // Assigns parent, children, height, depth
                root = d3.hierarchy(treeData, function (d) {
                    return d.children;
                });
                root.x0 = height / 2;
                root.y0 = 0;

                treeData = treemap(root);
                nodes = treeData.descendants().reverse();
                nodes.forEach(function(d) {
                    d.data.width = 250;
                    d.data.height = 170;
                    d.data.filtered = false;
                    d.data.expanded = true;
                    d.removed = false;
                    d.linkRemoved = false;
                    d.fatLinkRemoved = false;
                })
                links = treeData.descendants().slice(1);

                // Collapse after the second level
                if(root.children)
                    root.children.forEach(collapse);

                if(currentUser != undefined) {
                    votedByUser();
                }
                update(root)
                zoomFitInitial(0, 0, root)
            }
        });
    }

    function zoomFitInitial(paddingPercent, transitionDuration, root) {
        //debugger;

        //debugger;
        var nodeArea = document.getElementsByClassName("nodes")[0];
        var bounds = nodeArea.getBBox();
        var parent = nodeArea.parentElement.parentElement;
        var fullWidth = parent.clientWidth,
            fullHeight = parent.clientHeight;
        /*var width = bounds.width,
            height = bounds.height;*/
        var width = 700
        var height = root.y0 + 700;
        var x = root.x0 + width/2 - 200//d.y
        var y = root.y0 - height/2

        var midX = x + width / 2,
            midY = y + height / 2;
        if (width == 0 || height == 0) return; // nothing to fit
        var scale = Math.min((paddingPercent || 0.75) / Math.max(width / fullWidth, height / fullHeight), 1.1);
        var translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY];

        console.trace("zoomFit", translate, scale);
        svg
            .transition()
            .duration(transitionDuration || 0) // milliseconds
            .call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
    }


    // Collapse the node and all it's children
    function collapse(d) {
        if (d.children) {
            d.data.expanded = false;
            d._children = d.children
            d._children.forEach(collapse)
            d.children = null
        }
    }

    function appendExpandIcon(d) {
        let emptyElement = document.createElement("div")
        let element = document.createElement("div");
        element.className = "nodeExpandDiv";
        element.setAttribute("name", d.data.id)

        if( d._children || d.children ) {
            let imgElementPlus = document.createElement("img");
            imgElementPlus.className = "nodeImg";
            imgElementPlus.id = "nodePlusImg";
            imgElementPlus.src = chevronRight;
            element.appendChild(imgElementPlus)

            let imgElementMinus = document.createElement("img")
            imgElementMinus.className = "nodeImg";
            imgElementMinus.id = "nodeMinusImg";
            imgElementMinus.src = chevronRight
            element.appendChild(imgElementMinus)

            if(d.children === null ) { //children are hidden
                imgElementMinus.className += " hidden"
            }
            else { //children are being shown
                imgElementPlus.className += " rotateOut"
                imgElementMinus.className += " rotateIn"
            }
            return element;
        }
        else
            return emptyElement;
    }

    function drawNodes(node, source) {
        console.log("drawing")
        let nodeEnter = node.enter().append('g')
            .attr('class', 'node')
            .attr('id', function(d) {
                return "node" + d.data.id
            })
            .attr("transform", function (d) {
                return "translate(" + source.y0  + "," + source.x0 + ")";
            })


        let res = nodeEnter.append("foreignObject")
            .attr("width", 250)
            .attr("height", 170)
            .attr("class", "foreignObjectNew")
            .attr("transform", function() {
                return "translate(-20," + (-170/2) +")"
            })
            .attr("id", function (d) {
                console.log(d)
                return d.data.id;
            })

        let contentDiv = res.append("xhtml:div")
            .attr("id", function(d) {
                return "contentDiv" + d.data.id
            })
            .attr("class", "contentDiv")
            .attr("name", function (d) {
                return d.data.id;
            })
            .style("background-color", d => calcColor(colorPattern, d.data))
            .style("border", d=> getBorderColor(d.data, colorPattern))

        contentDiv.append(appendExpandIcon)

        contentDiv.append(function (d) {
            let element = document.createElement("div");
            element.className = "nodeText";
            let span = document.createElement("span")
            span.className = "trunkate"
            span.innerHTML = d.data.text;
            element.appendChild(span)

            let images = [];
            d.data.upload.forEach((file) => {
                images.push(file);
            })

            if (images.length > 0) {
                let element1 = document.createElement("div");
                element1.className = "nodeImageDiv";
                element1.setAttribute("nodeId", d.data.id);

                let icon = document.createElement("i");
                icon.className = "fas fa-images";

                let span = document.createElement("span");
                span.innerHTML = "View files";

                element1.appendChild(icon)
                element1.appendChild(span)

                element.appendChild(element1);
            }

            return element;
        })

        res.append("xhtml:div")
            .attr("id", "likeDiv")
            .attr("class", "likeDiv")
            .style("font", "20px 'Helvetica'")
            .html(function (d) {
                return getLikeContent(d.data);
            })


        $('.foreignObjectNew').find(".trunkate").trunk8(trunk8_settings);
        d3.selectAll('.foreignObjectNew').attr("class", "foreignObject")

        return nodeEnter;

    }

    function drawLinks(link, source) {
        return link.enter().insert('path', "g")
            .attr("id", function(d) {
                return "link" + d.data.id
            })
            .attr("class", function (d) {
                if(d.data.relationType === "attack")
                    return "path links attack";
                else
                    return "path links support";

            })
            .attr("stroke-width", function (d) {
                console.log("weight")
                console.log(d.data.text)
                return calcStrokeWidth(d.data.relationWeight);
            })
            .attr("stroke", function (d) {
                if(d.data.relationType === "attack")
                    return attack_color;
                else
                    return support_color;
            })
            .attr("fill", "none")
            .attr('d', function (d) {
                var o = {x: source.x0, y: source.y0 + 30 /* 30 is an adjustment so the arrows dont spawn behind the node */}
                return diagonal(o, o, d.data.relationType)
            })
    }

    function drawFatLinks(fatLink, source) {
        return fatLink
            .enter().insert("path", "g")
            .attr("id", function (d) {
                return d.data.id;
            })
            .attr("class", "fatPaths")
            .attr("stroke-width", 20)
            .attr("stroke", "transparent")
            .attr("fill", "none")
            .attr('d', function (d) {
                var o = {x: source.x0, y: source.y0}
                return diagonal(o, o, d.data.relationType)
            })
    }

    const trunk8_settings =  {
        fill: '&hellip;<span class="trunkateFill">Show more</span>',
        lines: 4
    };

    function update(source) {
        console.log("update")
        // Assigns the x and y position for the nodes
        treeData = treemap(root);

        // Compute the new tree layout.
        nodes = treeData.descendants().reverse();
        links = treeData.descendants().slice(1);

        // Normalize for fixed-depth.
        nodes.forEach(function (d) {
            d.y = d.depth * 500
        });

        // ****************** Nodes section ***************************

        // Update the nodes...
        var node = node_g.selectAll('g.node')
            .data(nodes.filter(function(n) {
                return !n.data.filtered
            }), function (d) {
                return d.id || (d.id = ++i);
            });

        // Enter any new modes at the parent's previous position.
        /*var nodeEnter = node.enter().append('g')
            .attr('class', 'node')
            .attr("transform", function (d) {
                return "translate(" + source.y0 + "," + source.x0 + ")";
            })
            .on('click', click);*/

        let nodeEnter = drawNodes(node, source)
        nodeEnter.each(function(d) {
            d.removed = false;
        })

        // UPDATE
        var nodeUpdate = nodeEnter.merge(node);

        // Transition to the proper position for the node
        nodeUpdate.transition()
            .duration(duration)
            .attr("transform", function (d) {
                if(d.data.height === 270) {
                    let x = d.x - 40;
                    return "translate(" + d.y + "," + x + ")";
                }
                else
                    return "translate(" + d.y + "," + d.x + ")";
            });

        // Update the node attributes and style
        /* nodeUpdate.select('circle.node')
             .attr('r', 10)
             .style("fill", function (d) {
                 return d._children ? "lightsteelblue" : "#fff";
             })
             .attr('cursor', 'pointer');*/


        // Remove any exiting nodes
        var nodeExit = node.exit().filter(function(d) {
            return !d.removed;
        }).transition()
            .duration(duration)
            .attr("transform", function (d) {
                d.data.width = 250;
                d.data.height = 170;
                let res = "translate(" + source.y + "," + source.x + ")";
                d.removed = true;
           return res;
            })
            .remove();


        // ****************** links section ***************************

        // Update the links...
        var link = link_g.selectAll('path.links')
            .data(links.filter(function(n) {
                return !n.data.filtered
            }), function (d) {
                return d.id;
            });

        let fatLink = fatLink_g.selectAll('path.fatPaths')
            .data(links.filter(function(n) {
                return !n.data.filtered
            }), function (d) {
                return d.id;
            });



        // Enter any new links at the parent's previous position.

        let linkEnter = drawLinks(link, source)
        linkEnter.each(function(d) {
            d.linkRemoved = false;
        })

        /* var linkEnter = link.enter().insert('path', "g")
             .attr("class", "link")
             .attr('d', function (d) {
                 var o = {x: source.x0, y: source.y0}
                 return diagonal(o, o)
             });*/

        // UPDATE
        var linkUpdate = linkEnter.merge(link);

        // Transition back to the parent element position
        linkUpdate.transition()
            .duration(duration)
            .attr('d', function (d) {
                return diagonal(d, d.parent, d.data.relationType)
            });

        // Remove any exiting links
        var linkExit = link.exit().filter(function(d) {
            return !d.linkRemoved;
        }).transition()
            .duration(duration)
            .attr('d', function (d) {
                d.linkRemoved = true;
                var o = {x: source.x, y: source.y + 30 /* 30 is an adjustment so the arrows dont spawn behind the node */}
                return diagonal(o, o, d.data.relationType)
            })
            .remove();

        let fatLinkEnter = drawFatLinks(fatLink, source)
        fatLinkEnter.each(function(d) {
            d.fatLinkRemoved = false;
        })
        let fatLinkUpdate = fatLinkEnter.merge(fatLink)
        fatLinkUpdate.transition()
            .duration(duration)
            .attr('d', function (d) {
                return diagonal(d, d.parent, d.data.relationType)
            });

        var fatLinkExit = fatLink.exit().filter(function(d) {
            return !d.fatLinkRemoved;
        }).attr('d', function (d) {
                d.fatLinkRemoved = true;
                var o = {x: source.x, y: source.y}
                return diagonal(o, o, d.data.relationType)
            })
            .remove();

        // Store the old positions for transition.
        nodes.forEach(function (d) {
            d.x0 = d.x;
            d.y0 = d.y;
        });
    }

    // Creates a curved (diagonal) path from parent to the child nodes
    function diagonal(s, d, relationType) {
        let path;
        let width = d.data ? d.data.width : 0;
        //no offset if argument has only attacks or only arguments
        let heightAdjust;
        if(d.data) {
            heightAdjust = d.data.nAttacks === 0 || d.data.nSupports == 0 ? 0 : 25;
        }
        else {
            heightAdjust = 0;
        }
        if(relationType === "attack") {
            path = `M ${s.y} ${s.x}
                        C ${(s.y + d.y + width) / 2} ${s.x},
                          ${(s.y + d.y + width) / 2} ${d.x},
                          ${d.y + width - 23} ${d.x - heightAdjust}`
        }
        else {
            path = `M ${s.y} ${s.x}
                        C ${(s.y + d.y + width - 30) / 2} ${s.x},
                          ${(s.y + d.y + width - 30) / 2} ${d.x},
                          ${d.y + width - 23} ${d.x + heightAdjust}`
        }


        return path
    }


    // Toggle children on click.
    let isChanging = false;
    function click(event, d) {
        if (event.defaultPrevented || isChanging) return;
        isChanging = true;
        setTimeout(function() {
            isChanging = false;
        }, duration)
        if (d.children) { //hide children nodes
            d._children = d.children;
            d.children = null;
            d.data.expanded = false;
            $("#"+d.data.id + ".foreignObject").find("#nodePlusImg").removeClass("rotateOut")
            $("#"+d.data.id + ".foreignObject").find("#nodeMinusImg").removeClass("rotateIn")
            update(d);
            if(d.parent) {
                zoomFitCollapse(0.7, duration, d)
            }
            else {
                zoomFitNode(0.5, duration, d)
            }

        } else { //show children nodes
            d.children = d._children;
            d._children = null;
            d.data.expanded = true;
            $("#"+d.data.id + ".foreignObject").find("#nodePlusImg").addClass("rotateOut")
            $("#"+d.data.id + ".foreignObject").find("#nodeMinusImg").addClass("rotateIn")
            update(d);
            zoomFitExpand(0.7, duration, d)
        }
    }

    function zoomFitFull(paddingPercent, transitionDuration) {
        var nodeArea = document.getElementsByClassName("nodes")[0];
        var bounds = nodeArea.getBBox();
        var parent = nodeArea.parentElement.parentElement;
        var fullWidth = parent.clientWidth,
            fullHeight = parent.clientHeight;
        var width = bounds.width,
            height = bounds.height;
        var midX = bounds.x + width / 2,
            midY = bounds.y + height / 2;
        if (width == 0 || height == 0) return; // nothing to fit
        var scale = (paddingPercent || 0.75) / Math.max(width / fullWidth, height / fullHeight);
        var translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY];

        console.trace("zoomFit", translate, scale);
        svg
            .transition()
            .duration(transitionDuration || 0) // milliseconds
            .call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
    }

    function zoomFitExpand(paddingPercent, transitionDuration, d) {

        let nChilds = d.children.length
        let firstChild = d.children[0]
        let lastChild = d.children[nChilds-1]

        //debugger;
        var nodeArea = document.getElementsByClassName("nodes")[0];
        var bounds = nodeArea.getBBox();
        var parent = nodeArea.parentElement.parentElement;
        var fullWidth = parent.clientWidth,
            fullHeight = parent.clientHeight;
        /*var width = bounds.width,
            height = bounds.height;*/
        var width = lastChild.y - d.y + 250;
        var height = lastChild.x - firstChild.x;
        if(height == 0)
            height = 170
        var x = d.y//d.y
        var y = firstChild.x
        var midX = x + width / 2,
            midY = y + height / 2;
        if (width == 0 || height == 0) return; // nothing to fit
        var scale = Math.min((paddingPercent || 0.75) / Math.max(width / fullWidth, height / fullHeight), 1.1);
        var translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY];

        console.trace("zoomFit", translate, scale);
        svg
            .transition()
            .duration(transitionDuration || 0) // milliseconds
            .call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
    }

    function zoomFitNode(paddingPercent, transitionDuration, d) {
        //debugger;
        var nodeArea = document.getElementsByClassName("nodes")[0];
        var bounds = nodeArea.getBBox();
        var parent = nodeArea.parentElement.parentElement;
        var fullWidth = parent.clientWidth,
            fullHeight = parent.clientHeight;
        /*var width = bounds.width,
            height = bounds.height;*/
        var width = 250;
        var height = 170;
        var x = d.y
        var y = d.x - 85
        var midX = x + width / 2,
            midY = y + height / 2;
        if (width == 0 || height == 0) return; // nothing to fit
        var scale = Math.min((paddingPercent || 0.75) / Math.max(width / fullWidth, height / fullHeight), 1.1);
        var translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY];

        console.trace("zoomFit", translate, scale);
        svg
            .transition()
            .duration(transitionDuration || 0) // milliseconds
            .call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
    }

    function zoomFitCollapse(paddingPercent, transitionDuration, d) {
        //debugger;
        let nodeParent = d.parent

        let nChilds = nodeParent.children.length
        let firstChild = nodeParent.children[0]
        let lastChild = nodeParent.children[nChilds-1]

        //debugger;
        var nodeArea = document.getElementsByClassName("nodes")[0];
        var bounds = nodeArea.getBBox();
        var parent = nodeArea.parentElement.parentElement;
        var fullWidth = parent.clientWidth,
            fullHeight = parent.clientHeight;
        /*var width = bounds.width,
            height = bounds.height;*/
        var width = lastChild.y - nodeParent.y + 250;
        var height = lastChild.x - firstChild.x;
        var x = nodeParent.y
        var y = firstChild.x
        var midX = x + width / 2,
            midY = y + height / 2;
        if (width == 0 || height == 0) return; // nothing to fit
        var scale = Math.min((paddingPercent || 0.75) / Math.max(width / fullWidth, height / fullHeight), 1.1);
        var translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY];

        console.trace("zoomFit", translate, scale);
        svg
            .transition()
            .duration(transitionDuration || 0) // milliseconds
            .call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
    }

    function collapseAll() {
        $("#"+root.data.id + ".foreignObject").find("#nodePlusImg").removeClass("rotateOut")
        $("#"+root.data.id + ".foreignObject").find("#nodeMinusImg").removeClass("rotateIn")
        collapse(root)
        update(root);
    }

    function svgClick() {
        if($('#settingsBox').is(':visible')) {
            if($('#styles_attacks').is(':visible') || $('#styles_supports').is(':visible')) {
                $('.styles_box').css("display", "none")
            }
            else {
                $('#settingsBox').css("display", "none");
            }
        }
        if($('#sliderFilterBox').hasClass("open")) {
            $('#sliderFilterBox').removeClass("open")
        }
    }

    function getNodeData(id) {
        let clickedNode = null;
        nodes.forEach(function(node) {
            if(node.data.id == id) {
                clickedNode = node.data;
            }
        })

        return clickedNode;
    }

    function getNode(id) {
            let clickedNode = null;
            nodes.forEach(function(node) {
                if(node.data.id == id) {
                    clickedNode = node;
                }
            })

            return clickedNode;
        }

    let currentFileIdx = 0;
    let totalFiles = 0;
    let currentFiles;
    let currentNodeId;

    const controls = [
        'play-large', // The large play button in the center
        'restart', // Restart playback
        //'rewind', // Rewind by the seek time (default 10 seconds)
        'play', // Play/pause playback
        //'fast-forward', // Fast forward by the seek time (default 10 seconds)
        'progress', // The progress bar and scrubber for playback and buffering
        'current-time', // The current time of playback
        'duration', // The full duration of the media
        'mute', // Toggle mute
        'volume', // Volume control
        'captions', // Toggle captions
        'settings', // Settings menu
        //'pip', // Picture-in-picture (currently Safari only)
        //'airplay', // Airplay (currently Safari only)
        //'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
        'fullscreen', // Toggle fullscreen
    ];

    const player = new Plyr('#player', { controls: controls });

    function imageViewDotClick() {
        $('.fileProgress').find(".dot.selected").removeClass("selected");
        currentFileIdx = $(this).index();

        let cur = currentFileIdx + 1;
        $('.fileProgress').find(".dot:nth-child(" + cur + ")").addClass("selected");
        changeFile();
    }

    function openFileViewModal(e) {
        e.stopPropagation();
        e.stopImmediatePropagation();

        let nodeId = $(this).attr("nodeId");
        let node = getNodeData(nodeId);
        let images = node.upload

        currentFileIdx = 0;
        totalFiles = images.length;
        currentFiles = images;
        currentNodeId = nodeId;

        $('.fileProgress').empty();
        for(let i=0; i<totalFiles; i++) {
            if(i == 0) {
                $('.fileProgress').append($('<span>')
                    .attr("class", "dot selected"));
            }
            else {
                $('.fileProgress').append($('<span>')
                    .attr("class", "dot"));
            }
        }

        $('#argumentFilesViewModal').modal('show');

        changeFile();
        openImageModal(node)
    }

    $(".previousFile").on("click", function () {
        $('.fileProgress').find(".dot.selected").removeClass("selected");

        if(currentFileIdx == 0)
            currentFileIdx = totalFiles-1;
        else
            currentFileIdx--;

        let cur = currentFileIdx + 1;
        $('.fileProgress').find(".dot:nth-child(" + cur + ")").addClass("selected");
        changeFile();
    })

    $(".nextFile").on("click", function () {
        $('.fileProgress').find(".dot.selected").removeClass("selected");
        if(currentFileIdx == totalFiles-1)
            currentFileIdx = 0;
        else
            currentFileIdx++;

        let cur = currentFileIdx + 1;

        $('.fileProgress').find(".dot:nth-child(" + cur + ")").addClass("selected");

        console.log("current: " + currentFileIdx)
        changeFile();
    })

    const trunk8_settings_pdf =  {
        width: 200
    };

    function changeFile() {
        player.stop();
        $("#argumentFilesViewModal").find(".plyr").hide()
        $("#argumentFilesViewModal").find(".imageFile").hide();
        $("#argumentFilesViewModal").find(".pdfPreview").hide();

        let extension = currentFiles[currentFileIdx].split(".").pop().toLowerCase();
        console.log(extension)
        if(extension == "jpeg"
            || extension == "jpg"
            || extension == "png") {
            console.log(currentFiles[currentFileIdx] + " is an image!")
            var img = $("#argumentFilesViewModal").find(".imageFile") //img src has to have localhost or swarg.di... apparently
            img.attr("src", baseUrl + "/getFile/" + currentNodeId + "/" + currentFiles[currentFileIdx])
            img.show();
        }
        else if(extension == "mp4") {
            console.log(currentFiles[currentFileIdx] + " is a video!")
            var video =  $("#argumentFilesViewModal").find("video");
            video.attr("src", baseUrl + "/stream/" + currentNodeId + "/" + currentFiles[currentFileIdx]);
            $("#argumentFilesViewModal").find(".plyr").show()
        }
        else if(extension == "pdf") {
            console.log(currentFiles[currentFileIdx] + " is a pdf file!")
            let pdfDiv = $("#argumentFilesViewModal").find(".pdfPreview");
            $(".trunkatePDF").html(currentFiles[currentFileIdx]);

            if(currentFiles[currentFileIdx].length > trunk8_settings_pdf.width)
                $(".trunkatePDF").trunk8(trunk8_settings_pdf)

            $(".downloadBtn").on("click", function () {
                window.open(baseUrl + "/getFile/" + currentNodeId + "/" + currentFiles[currentFileIdx]);
            })

            pdfDiv.show();
            pdfDiv.find("img").show();
        }
        else {
            console.log(currentFiles[currentFileIdx] + " is an unexpected type of file: " + extension)
        }
    }

    function openImageModal(d) {
        console.log(d)
        let iconPos;
        let iconNeg;
        if (d.typeOfVote === "positive") {
            iconPos = "fas fa-thumbs-up"
            iconNeg = "far fa-thumbs-down"
        } else if (d.typeOfVote === "negative") {
            iconPos = "far fa-thumbs-up"
            iconNeg = "fas fa-thumbs-down"
        } else {
            iconPos = "far fa-thumbs-up"
            iconNeg = "far fa-thumbs-down"
        }

        $("body").append($("<div>")
            .attr("class", "argImageView")
            .append($("<span>")
                .attr("class", "arg")
                .html(function () {
                    return d.text
                }))
            .append($("<hr>"))
            .append($("<div>")
                .attr("class", "likeDivExpanded")
                .append($("<button>")
                    .attr("id", d.id)
                    .attr("class", "likeNode modal_like")
                    .append($("<i>")
                        .attr("id", "modal_like" + d.id)
                        .attr("class", iconPos)))
                .append($("<span>")
                    .attr("id", "modal_posVotes" + d.id)
                    .html(abbreviateNumber(d.positiveVotes)))
                .append($("<button>")
                    .attr("id", d.id)
                    .attr("class", "dislikeNode modal_dislike")
                    .append($("<i>")
                        .attr("id", "modal_dislike" + d.id)
                        .attr("class", iconNeg)))
                .append($("<span>")
                    .attr("id", "modal_negVotes" + d.id)
                    .html(abbreviateNumber(d.negativeVotes))))
            .append($("<hr>"))
        )

        $(".modal_like").on("click", clickModalLikeNode)
        $(".modal_dislike").on("click", clickModalDislikeNode)
    }

    function closeImageModal(e) {
        e.stopPropagation()
        e.stopImmediatePropagation()
        console.log("closing stuff")
        player.stop();
        $(".argImageView").remove();
        $('#argumentFilesViewModal').modal('hide');
    }


    function clickLikeNode(event) {
        if (event.defaultPrevented) return;
        event.stopPropagation();

        $('.svg-tooltip').remove()

        if(/*checkValidToken()*/ currentUser != undefined) {
            let clickedNode = getNodeData(event.currentTarget.parentNode.previousSibling.getAttribute("name"))
            likePos(clickedNode);
            update(root)
        }
        else {
            signInTooltip($(this), "You must sign in to vote");
        }
    }

    function clickDislikeNode(event) {
        if (event.defaultPrevented) return;
        event.stopPropagation();

        $('.svg-tooltip').remove()

        if(/*checkValidToken()*/ currentUser != undefined) {
            let clickedNode = getNodeData(event.currentTarget.parentNode.previousSibling.getAttribute("name"));
            likeNeg(clickedNode);
            update(root)
        }
        else {
            signInTooltip($(this), "You must sign in to vote");
        }
    }

    function clickModalLikeNode(event) {
        event.stopPropagation();

        if(/*checkValidToken()*/ currentUser != undefined) {
            let clickedNode = getNodeData(event.currentTarget.getAttribute("id"));
            likePos(clickedNode);
        }
        else {
            signInTooltip($(this), "You must sign in to vote");
        }

    }

    function clickModalDislikeNode(event) {
        event.stopPropagation();

        if(/*checkValidToken()*/ currentUser != undefined) {
            let clickedNode = getNodeData(event.currentTarget.getAttribute("id"));
            likeNeg(clickedNode);
        }
        else {
            signInTooltip($(this), "You must sign in to vote");
        }
    }

    function likePos(clickedNode, parent){
        console.log("clicked node")
        console.log(clickedNode);
        if(clickedNode.typeOfVote === "positive"){
            clickedNode.positiveVotes--;
            clickedNode.typeOfVote = "";
        }
        else if(clickedNode.typeOfVote === "negative"){
            clickedNode.positiveVotes++;
            clickedNode.negativeVotes--;
            clickedNode.typeOfVote = "positive"
        }
        else{
            clickedNode.positiveVotes++;
            console.log("positive votes:" + clickedNode.positiveVotes)
            clickedNode.typeOfVote = "positive";
        }

        $('#' + clickedNode.id + ".foreignObject").children(".likeDiv").html(function () {
            return getLikeContent(clickedNode);
        })

        let iconPos;
        let iconNeg;
        if (clickedNode.typeOfVote === "positive") {
            iconPos = "fas fa-thumbs-up"
            iconNeg = "far fa-thumbs-down"
        } else if (clickedNode.typeOfVote === "negative") {
            iconPos = "far fa-thumbs-up"
            iconNeg = "fas fa-thumbs-down"
        } else {
            iconPos = "far fa-thumbs-up"
            iconNeg = "far fa-thumbs-down"
        }


        $("#modal_like" + clickedNode.id).removeClass().attr("class", iconPos)
        $("#modal_posVotes" + clickedNode.id).html(clickedNode.positiveVotes)

        $("#modal_dislike" + clickedNode.id).removeClass().attr("class", iconNeg)
        $("#modal_negVotes" + clickedNode.id).html(clickedNode.negativeVotes)

        $.ajax({
            'async': true,
            'type' : "POST",
            'global': false,
            "headers": {
                "authorization": "Bearer "+ currentUser.token},
            'url': baseUrl + "/votepos/g/"+graphId + "/" + $('#semanticSelect').children("option:selected").val() +
                "/node/"+clickedNode.id+"/token/" + currentUser.token,
            'dataType': "json",
            'success': function (data) {
                reloadGraph()
            }
        });
    }

    function likeNeg(clickedNode){
        console.log("likeNeg")
        console.log(clickedNode)
        if(clickedNode.typeOfVote === "positive"){
            clickedNode.positiveVotes--;
            clickedNode.negativeVotes++;
            clickedNode.typeOfVote = "negative";
        }
        else if(clickedNode.typeOfVote === "negative"){
            clickedNode.negativeVotes--;
            clickedNode.typeOfVote = ""
        }
        else{
            clickedNode.negativeVotes++;
            clickedNode.typeOfVote = "negative";
        }

        $('#' + clickedNode.id + ".foreignObject").children(".likeDiv").html(function () {
            return getLikeContent(clickedNode);
        })

        let iconPos;
        let iconNeg;
        if (clickedNode.typeOfVote === "positive") {
            iconPos = "fas fa-thumbs-up"
            iconNeg = "far fa-thumbs-down"
        } else if (clickedNode.typeOfVote === "negative") {
            iconPos = "far fa-thumbs-up"
            iconNeg = "fas fa-thumbs-down"
        } else {
            iconPos = "far fa-thumbs-up"
            iconNeg = "far fa-thumbs-down"
        }

        $("#modal_like" + clickedNode.id).removeClass().attr("class", iconPos)
        $("#modal_posVotes" + clickedNode.id).html(clickedNode.positiveVotes)

        $("#modal_dislike" + clickedNode.id).removeClass().attr("class", iconNeg)
        $("#modal_negVotes" + clickedNode.id).html(clickedNode.negativeVotes)

        $.ajax({
            'async': true,
            'type' : "POST",
            'global': false,
            "headers": {
                "authorization": "Bearer "+ currentUser.token},
            'url': baseUrl + "/voteneg/g/"+graphId + "/" + $('#semanticSelect').children("option:selected").val() +
                "/node/"+clickedNode.id+"/token/"+ currentUser.token,
            'dataType': "json",
            'success': function (data) {
                reloadGraph()
            }
        });
    }

    function linkClick(e) {
        e.stopPropagation();

        svg.selectAll(".svg-tooltip").remove();
       // disableDrag();
        //disable zoom
        svg.on('.zoom', null);

        $("#svgId").off("mouseenter", ".foreignObject", nodeMouseEnter);
        $("#svgId").off("mouseleave", ".foreignObject", nodeMouseLeave);
        $("#svgId").off("mouseenter", ".fatPaths", linkMouseEnter);
        $("#svgId").off("mouseleave", ".fatPaths", linkMouseLeave);
        $("#svgId").off("click", ".fatPaths", linkClick);


        $(".wrapper").on("click", "#svgId", linkClickOut);
        let coordinates= d3.pointer(e, svg.node());
        let x = coordinates[0];
        let y = coordinates[1];

        let clickedFatPath = e.currentTarget;
        let d = getNodeData(clickedFatPath.getAttribute("id"));

        let fo = svg.append("foreignObject")
            .attr("width", 700)
            .attr("height", 200)
            .attr("x", x)
            .attr("y", y)
            .attr("class", "svg-tooltip")
            .attr("id", "likeAttackObject")
        fo.append("xhtml:div")
            .attr("id", "likeAttackDiv")
            .style("font", "20px 'Helvetica'")
            .html(function () {
                var iconPos;
                var iconNeg;
                if(d.typeOfRelationVote === "positive"){
                    iconPos = "fas fa-thumbs-up"
                    iconNeg = "far fa-thumbs-down"
                }
                else if(d.typeOfRelationVote === "negative"){
                    iconPos = "far fa-thumbs-up"
                    iconNeg = "fas fa-thumbs-down"
                }
                else{
                    iconPos = "far fa-thumbs-up"
                    iconNeg = "far fa-thumbs-down"
                }
                return "<button id= " + d.id + " class=\"likeFatAttack\" style=\"font-size:24px\"><i class='" + iconPos + "'></i></button>"
                    + abbreviateNumber(d.relationPosVotes) + " <button id = " + d.id + " class=\"dislikeFatAttack\" style=\"font-size:24px\"><i class='" + iconNeg + "'></i></button>" +
                    abbreviateNumber(d.relationNegVotes) + " ";
            });
    }

    function linkClickOut(e) {
        e.stopPropagation()
        linkMouseLeave();
        $(".wrapper").off("click", "#svgId", linkClickOut);
        $("#svgId").on("mouseenter", ".foreignObject", nodeMouseEnter);
        $("#svgId").on("mouseleave", ".foreignObject", nodeMouseLeave);
        $("#svgId").on("mouseenter", ".fatPaths", linkMouseEnter);
        $("#svgId").on("mouseleave", ".fatPaths", linkMouseLeave);
        $("#svgId").on("click", ".fatPaths", linkClick);

        //enableDrag()
        svg.call(zoom)
    }


    function linkMouseEnter(e) {
        svg.selectAll(".svg-tooltip").remove();

        //disable zoom
        svg.on('.zoom', null);

        let id = e.currentTarget.getAttribute("id")
        $('#link'+ id).addClass("hovered");
        d3.select('#link'+ id).raise()


        let coordinates= d3.pointer(e, svg.node());
        let x = coordinates[0];
        let y = coordinates[1];

        let d = getNodeData(id);
        let fo = svg.append("foreignObject")
            .attr("width", 700)
            .attr("height", 200)
            .attr("x", x)
            .attr("y", y)
            .attr("class", "svg-tooltip")
            .attr("id", "showLinkScore")
        fo.append("xhtml:div")
            .attr("id", "scoreAttackDiv")
            .html("Score: <b> " + Number((Math.round(d.relationWeight + "e+4") + "e-2"  )).toFixed(0) + "/100</b> <p> " +
                "Positive: <b>" + abbreviateNumber(d.relationPosVotes) + "</b> Negative: <b>" + abbreviateNumber(d.relationNegVotes) + "</b></p><p><i> Click on the arrow to vote</i></p>");
    };

    function linkMouseLeave() {
        $(".hovered").removeClass("hovered");
        $(".svg-tooltip").remove();
        svg.call(zoom)
    }

    function clickLikeAttack(e) {
        e.stopPropagation();
        if(/*checkValidToken()*/ currentUser && currentUser.token != undefined) {
            console.log("liked id")
            console.log(e.currentTarget.getAttribute("id"))
            let selectedLink = getNodeData(e.currentTarget.getAttribute("id"));

            if(selectedLink.typeOfRelationVote === "positive"){
                selectedLink.relationPosVotes--;
                selectedLink.typeOfRelationVote = "";
            }
            else if(selectedLink.typeOfVote === "negative"){
                selectedLink.relationPosVotes++;
                selectedLink.relationNegVotes--;
                selectedLink.typeOfRelationVote = "positive"
            }
            else{
                selectedLink.relationPosVotes++;
                selectedLink.typeOfRelationVote = "positive";
            }


            //votePosE(selectedLink);

            $(e.currentTarget).parent().html(function () {
                let iconPos;
                let iconNeg;
                if(selectedLink.typeOfRelationVote === "positive"){
                    iconPos = "fas fa-thumbs-up"
                    iconNeg = "far fa-thumbs-down"
                }
                else if(selectedLink.typeOfRelationVote === "negative"){
                    iconPos = "far fa-thumbs-up"
                    iconNeg = "fas fa-thumbs-down"
                }
                else{
                    iconPos = "far fa-thumbs-up"
                    iconNeg = "far fa-thumbs-down"
                }
                return "<button id= " + selectedLink.id + " class=\"likeFatAttack\" style=\"font-size:24px\"><i class='" + iconPos + "'></i></button>"
                    + abbreviateNumber(selectedLink.relationPosVotes) + " <button id = " + selectedLink.id + " class=\"dislikeFatAttack\" style=\"font-size:24px\"><i class='" + iconNeg + "'></i></button>" +
                    abbreviateNumber(selectedLink.relationNegVotes) + " ";
            })

            $.ajax({
                'async': true,
                'type' : "POST",
                'global': false,
                "headers": {
                    "authorization": "Bearer "+ currentUser.token},
                'url': baseUrl + "/voteposE/g/"+graphId + "/" + $('#semanticSelect').children("option:selected").val() +
                    "/node/" + selectedLink.lid + "/token/" + currentUser.token,
                'dataType': "json",
                'success': function () {
                    reloadGraph()
                }
            });


        }
        else {
            signInTooltip($(e.currentTarget), "You must sign in to vote");
        }
    }


    function clickDislikeAttack(e) {
        e.stopPropagation();
        if(/*checkValidToken() */ currentUser && currentUser.token != undefined) {
            console.log("disliked id")
            console.log(e.currentTarget.getAttribute("id"))
            let selectedLink = getNodeData(e.currentTarget.getAttribute("id"));

            if(selectedLink.typeOfRelationVote === "positive"){
                selectedLink.relationPosVotes--;
                selectedLink.relationNegVotes++;
                selectedLink.typeOfRelationVote = "negative";
            }
            else if(selectedLink.typeOfRelationVote === "negative"){
                selectedLink.relationNegVotes--;
                selectedLink.typeOfRelationVote = ""
            }
            else{
                selectedLink.relationNegVotes++;
                selectedLink.typeOfRelationVote = "negative";
            }

            $(e.currentTarget).parent().html(function () {
                let iconPos;
                let iconNeg;
                if(selectedLink.typeOfRelationVote === "positive"){
                    iconPos = "fas fa-thumbs-up"
                    iconNeg = "far fa-thumbs-down"
                }
                else if(selectedLink.typeOfRelationVote === "negative"){
                    iconPos = "far fa-thumbs-up"
                    iconNeg = "fas fa-thumbs-down"
                }
                else{
                    iconPos = "far fa-thumbs-up"
                    iconNeg = "far fa-thumbs-down"
                }
                return "<button id= " + selectedLink.id + " class=\"likeFatAttack\" style=\"font-size:24px\"><i class='" + iconPos + "'></i></button>"
                    + abbreviateNumber(selectedLink.relationPosVotes) + " <button id = " + selectedLink.id + " class=\"dislikeFatAttack\" style=\"font-size:24px\"><i class='" + iconNeg + "'></i></button>" +
                    abbreviateNumber(selectedLink.relationNegVotes) + " ";
            })

            $.ajax({
                'async': true,
                'type' : "POST",
                'global': false,
                "headers": {
                    "authorization": "Bearer "+ currentUser.token},
                'url': baseUrl + "/votenegE/g/" + graphId + "/" + $('#semanticSelect').children("option:selected").val() +
                    "/node/" + selectedLink.lid + "/token/" + currentUser.token,
                'dataType': "json",
                'success': function () {
                    reloadGraph()
                }
            });
        }
        else {
            signInTooltip($(e.currentTarget), "You must sign in to vote");
        }
    }

    function showMoreClick(e) {
        e.stopPropagation()

        svg.selectAll(".svg-tooltip").remove();

        let clicked_foreignObject = $(e.currentTarget).parent().parent().parent().parent()[0];

        $(clicked_foreignObject).addClass("clicked");

        //handle arrows adjusting to bigger node
        let graph_node = getNodeData($(clicked_foreignObject).attr("id"))
        graph_node.width = 345
        graph_node.height = 270
        $(clicked_foreignObject).attr("width", 345)
        $(clicked_foreignObject).attr("height", 270)


       // updateCollision()

        //untrunkate
        $(clicked_foreignObject).find(".trunkate").trunk8('revert')

        let nodeText = $(clicked_foreignObject).find('.nodeText')

        $(nodeText)
            .append($('<span>')
                .attr("class", "showLess")
                .html("Show less"))

        $(nodeText).find(".showLess").on("click", function (e) {
            e.stopPropagation()
            showLessClick(e, clicked_foreignObject)
        })

        g.selectAll('path.links')
            .attr('d', function (d) {
                return diagonal(d, d.parent, d.data.relationType)
            });

        update(root)
    }

    function showLessClick(e, foreignObject) {
        $(e.target).off("click")
        $(foreignObject).find('.nodeText').scrollTop(0)
        $(foreignObject).removeClass("clicked")
        $(foreignObject).find(".trunkate").trunk8({
            fill: trunk8_settings.fill,
            lines: trunk8_settings.lines
        });

        //handle arrows adjusting to smaller node
        let graph_node = getNodeData($(foreignObject).attr("id"))
        graph_node.width = 250
        graph_node.height = 170
        $(foreignObject).attr("width", 250)
        $(foreignObject).attr("height", 170)

        // updateCollision()

        $(foreignObject).find('.showLess').remove()

        g.selectAll('path.links')
            .attr('d', function (d) {
                return diagonal(d, d.parent, d.data.relationType)
            });
        update(root)
    }

    function highlightParents(node) {
        console.log(node)
        $('#' + node.data.id + '.foreignObject').removeClass("faded")
        $('#link' + node.data.id).removeClass("faded")
        if(node.parent) {
            highlightParents(node.parent)
        }
    }

    function highlightChildren(node) {
        if(node.children) {
            node.children.forEach(function (child) {
                $('#' + child.data.id + '.foreignObject').removeClass("faded")
                $('#link' + child.data.id).removeClass("faded")
                highlightChildren(child)
            })
        }
    }

    let timer;
    function nodeMouseEnter(e) {
        let node = getNodeData(e.currentTarget.getAttribute("id"))
        let nodeTree = getNode(e.currentTarget.getAttribute("id"))
        if(node && nodeTree) {
            let contentDiv = $(e.currentTarget).find(".contentDiv")
            let newBorderColor = shadeColor(calcColor(colorPattern, node), -35)
            $(contentDiv).css("border", "solid 2px " + newBorderColor)

            timer = setTimeout(function() {
                $('.links').addClass("faded")
                $('.foreignObject').addClass("faded")
                highlightParents(nodeTree)
                highlightChildren(nodeTree)
            }, 500)
        }

    }

    function nodeMouseLeave(e) {
        let node = getNodeData(e.currentTarget.getAttribute("id"))
        if(node) {
            let contentDiv = $(e.currentTarget).find(".contentDiv")
            let newBorderColor = shadeColor(calcColor(colorPattern, node), -15)

            $(contentDiv).css("border", "solid 2px " + newBorderColor)
        }

        clearTimeout(timer)
        $('.foreignObject').removeClass("faded")
        $('.links').removeClass("faded")
    }

    function scrollOnNode(e) {
        let text_div = $(e.currentTarget).find(".nodeText")

        if(text_div.prop('scrollHeight') > text_div.height()) {
            svg.on('.zoom', null);
        }
    }
    function scrollOnNode_end() {
        svg.call(zoom)
    }

    let locked = false;
    let overlapping = false;
    $('.slider-draggable').on('mousedown', function (e) {
        e.stopPropagation()

        let posX = e.pageX;
        let rangeDiv = $(this).parent();
        $('#sliderFilterBox').on('mousemove', function (e) {
            e.stopPropagation()

            //if both sliders are on top of each other
            if(overlapping) {
                var diffX = e.pageX - posX;
                var rangeDivMax = $('.range-div.max')
                var rangeDivMin = $('.range-div.min')
                if(rangeDivMax.width() == 0) { //if max slider is in max position, can only move min slider when they're overlapping
                    moveMinSlider(e, posX)
                    rangeDiv = rangeDivMin
                }
                else if(rangeDivMin.width() == 0) { //vice versa
                    moveMaxSlider(e, posX)
                    rangeDiv = rangeDivMax
                }
                else if(diffX > 0) { //moving to the right
                    moveMaxSlider(e, posX)
                    rangeDiv = rangeDivMax
                }
                else if(diffX < 0) { //moving to left
                    moveMinSlider(e, posX)
                    rangeDiv = rangeDivMin
                }
            }
            else { //behave normally
                if(rangeDiv.hasClass("min"))
                    moveMinSlider(e, posX)
                else
                    moveMaxSlider(e, posX)
            }
            filterByRangeSlider()
            posX = e.pageX;
        })

        $('#root').on('mouseup', rangeSliderLeave)
    })

    /* here 14 is the 22px(slider marker width) - 4px - 4px (4px border on each side) */
    const markerVisibleColorWidth = 14;

    function moveMinSlider(e, posX) {
        overlapping = false;

        var rangeDiv = $('.range-div.min')
        var diffX = e.pageX - posX;

        var width = rangeDiv.width();
        var newWidth = width + diffX;


        if(newWidth + $('.range-div.max').width() + markerVisibleColorWidth >= $('#slider-background').width()) {
            newWidth = $('#slider-background').width() - $('.range-div.max').width() - markerVisibleColorWidth
            locked = true;
        }
        else {
            locked = false;
        }

        rangeDiv.css("width", Math.max(0, newWidth))
    }

    function moveMaxSlider(e, posX) {
        overlapping = false;

        let rangeDiv = $('.range-div.max')
        let diffX = posX - e.pageX;

        let width = rangeDiv.width();
        let newWidth = width + diffX;

        if(newWidth + $('.range-div.min').width() + markerVisibleColorWidth >= $('#slider-background').width()) {
            newWidth = $('#slider-background').width() - $('.range-div.min').width() - markerVisibleColorWidth
            locked = true;
        }
        else {
            locked = false;
        }

        rangeDiv.css("width", Math.max(0, newWidth))
    }

    function filterByRangeSlider() {
        let min = ( $('.range-div.min').width() / ( $('#slider-background').width() - 14.0 ) ) * 100;
        let max = 100 - ( ( $('.range-div.max').width() / ( $('#slider-background').width() - 14.0 ) ) * 100 );

        //filterByCat(min, max)
        filterByCatRec(root, min, max)
    }

    function rangeSliderLeave() {
        console.log("mouseup")
        $('#sliderFilterBox').off('mousemove')
        if(locked)
            overlapping = true;
        else
            overlapping = false;
    }

    function filterByCatRec(node, min, max) {
        console.log("filter rec")

        if(node.children) {
            node.children.forEach(function (n) {
                let changed = false;
                let score = n.data.weight * 100;
                if(score >= min && score <= max) {//inside filter range
                    const oldFiltered = n.data.filtered
                    n.data.filtered = false;
                    if(oldFiltered !== n.data.filtered) {
                        if(n.data.expanded) {
                            n.children = n._children;
                            n._children = null;
                        }
                        update(node)
                    }
                    filterByCatRec(n, min, max)
                }
                else { //outside filter range
                    const oldFiltered = n.data.filtered;
                    n.data.filtered = true;
                    if(oldFiltered !== n.data.filtered) {
                        if(n.children) {
                            n._children = n.children;
                            n.children = null;
                        }
                        update(node)
                    }
                }
            })
        }
    }

    function filterByCat(min, max) {
        let changed = false;



        nodes.forEach(function (n) {
            if(n != root) {
                let score = n.data.weight * 100;
                if(score >= min && score <= max) {//inside filter range
                    const oldFiltered = n.data.filtered
                    n.data.filtered = false;
                    if(oldFiltered !== n.data.filtered) {
                        n.children = n._children;
                        n._children = null;
                        changed = true;
                        update(n.parent, false)
                    }
                }
                else { //outside filter range
                    const oldFiltered = n.data.filtered;
                    n.data.filtered = true;
                    if(oldFiltered !== n.data.filtered) {
                        n._children = n.children;
                        n.children = null;
                        /*let childIndex = n.parent.children.indexOf(n)
                        n.parent.children.splice(childIndex, 1)
                        if(n.parent.children.length === 0)
                            n.parent.children = null;*/

                        changed = true;
                        update(n.parent, false)
                    }
                }
            }
        })
    }


    function addArgumentClick() {
        clearInterval(reloadInterval)

       // $('#hiddenAddButton').click()
        $('.addArgumentHelper').addClass("show")
        $('path.path').addClass("faded")
        $('.button_hover').addClass("faded")

        //disable click events on nodes and link events
        $("#svgId").off("click", ".likeNode", clickLikeNode);
        $("#svgId").off("click", ".dislikeNode", clickDislikeNode);
        $("#svgId").off("click", ".nodeImageDiv", openFileViewModal);
        $("#svgId").off("click", ".nodeExpandDiv", nodeExpandClick);
        $("#svgId").off("click", ".fatPaths", linkClick);
        $("#svgId").off("mouseenter", ".fatPaths", linkMouseEnter);
        $("#svgId").off("mouseleave", ".fatPaths", linkMouseLeave);
        $("#svgId").off("mouseenter", ".foreignObject", nodeMouseEnter);
        $("#svgId").off("mouseleave", ".foreignObject", nodeMouseLeave);

        //disable events on other buttons
        $('#collapseButton').off("click", collapseAll);
        $('#sidebarCollapse').off('click', sidebarCollapseClick);
        $('#settingsButton').off("click", settingsBoxClick)
        $('#dropdownFilters').off("click", filtersClick)

        $('.foreignObject').on("click", showActionsList)
        $('#svgId').on("click", hideActionsList)
    }

    function cancelAddArgument() {
        reloadInterval = setInterval(intervalFunction, 5000)
        $('.addArgumentHelper').removeClass("show")
        $('path.path').removeClass("faded")
        $('.button_hover').removeClass("faded")
        hideActionsList()

        //enable node and link events again
        $("#svgId").on("click", ".likeNode", clickLikeNode);
        $("#svgId").on("click", ".dislikeNode", clickDislikeNode);
        $("#svgId").on("click", ".nodeImageDiv", openFileViewModal);
        $("#svgId").on("click", ".nodeExpandDiv", nodeExpandClick);
        $("#svgId").on("click", ".fatPaths", linkClick);
        $("#svgId").on("mouseenter", ".fatPaths", linkMouseEnter);
        $("#svgId").on("mouseleave", ".fatPaths", linkMouseLeave);
        $("#svgId").on("mouseenter", ".foreignObject", nodeMouseEnter);
        $("#svgId").on("mouseleave", ".foreignObject", nodeMouseLeave);

        //enable other events
        $('#collapseButton').on("click", collapseAll);
        $('#sidebarCollapse').on('click', sidebarCollapseClick);
        $('#settingsButton').off("click", settingsBoxClick)
        $('#dropdownFilters').on("click", filtersClick)

        $('.foreignObject').off("click", showActionsList)
        $('#svgId').off("click", hideActionsList)
    }

    let clickedNodeId;
    let relationType;
    function showActionsList(e) {
        e.stopImmediatePropagation()

        //disable zoom
        svg.on('.zoom', null);

        clickedNodeId = $(e.currentTarget).children(".contentDiv")[0].getAttribute("name")

        $("#argument_options").hide(100);
        const isHidden = $('.sidebar_container').hasClass('hidden');
        let top = e.pageY;
        let left = isHidden ? e.pageX : e.pageX - 90;
        // Show contextmenu
        $("#argument_options").finish().toggle(100).// In the right position (the mouse)
        css({
            top: top + "px",
            left: left + "px"
        });
    }

    function hideActionsList() {
        $("#argument_options").hide(100);
        svg.call(zoom)
    }

    function chooseLinkType(e) {
        // This is the triggered action name
        switch (e.currentTarget.getAttribute("data-action")) {
            // A case for each action. Your actions here
            case "first":
                //addLink(clickedNode, ATTACK_END_URL);
                relationType = "attack"
                $('#hiddenAddButton').click()
                $('#newArgumentTitle').html("New attacking argument")
                break;
            case "second":
                relationType = "support"
                $('#hiddenAddButton').click()
                $('#newArgumentTitle').html("New supporting argument")
                break;
        }
        // Hide it AFTER the action was triggered
        $("#argument_options").hide(100);
    }

    let fileName = [];
    let file_num = 0;
    let file_id = 0;
    let data;
    const trunk8_settings_file =  {
        lines: 1
    };

    function getFile() {
        const file_name = $(this).parent().parent().attr("file_name");
        let index = -1;

        window.open(baseUrl + "/getTempFile/" + graphId + "/" + currentUser.token + "/" + file_name + "/" + index);
    }
    function removeFile() {
        let file = $(this).parent();
        let file_name = file.attr("file_name");
        const index = fileName.indexOf(file_name);
        if (index > -1) {
            fileName.splice(index, 1);
        }
        let ind = -1;
        $.ajax({
            'type' : "DELETE",
            'async': true,
            'global': true,
            'url': baseUrl + "/deleteFile/" + graphId + "/" + currentUser.token + "/" + file_name + "/" + ind + "/false",
            'dataType': "json",
            'success' : function(data) {
                file.remove();
                file_num--;
            },
            'error' :function(xhr, status, error) {
                let errorMessage = xhr.status + ': ' + xhr.statusText;
                alert('Error - ' + errorMessage);
            }
        })

    }
    function preUploadFile(progressDiv) {
        progressDiv.css("width", "75%");
        let xhr = new XMLHttpRequest();
        let index = -1;

        xhr.open("post", baseUrl + "/uploadFiles/" + graphId + "/" + currentUser.token + "/" + index, true);

        xhr.onload = function () {
            progressDiv.css("width", "100%");
            progressDiv.parent().next().off("click");
            progressDiv.parent().next().on("click", removeFile);
            setTimeout(function () {
                progressDiv.parent().css("visibility", "hidden");
                progressDiv.parent().parent().find(".file-name2").addClass("uploaded");
            }, 1500);
        };

        progressDiv.parent().next().on("click", function () {
            xhr.abort();
            progressDiv.parent().parent().remove();
            file_num--;
        })

        xhr.send(data);
    }

    function fileInputChange() {
        const files = this.files;
        data = new FormData()
        if (files.length) {
            for (let i = 0; i < files.length; i++) {
                if(files[i].size/1024/1024 > 10) {   // 10MB
                    alert("Your file must not exceed 10MB");
                }
                else if(file_num === MAX_FILES) {
                    alert("You can only upload " + MAX_FILES + " files per argument")
                    return;
                }
                else {
                    const name = files[i].name;
                    if(fileName.includes(name)) {
                        alert("Duplicate file!")
                    }
                    else {
                        $('.image-upload.change').append($('<div>')
                            .attr("class", "file-preview")
                            .attr("id", "file" + file_id)
                            .attr("file_name", name)
                            .append($('<div>')
                                .attr("class", "file-name")
                                .append($('<div>')
                                    .attr("class", "file-name2")
                                    .append($('<span>')
                                        .attr("class", "file-title")
                                        .html(name))
                                )
                                .append($('<span>')
                                    .attr("class", "file-size")
                                    .html(function () {
                                        var size = files[i].size / 1024 / 1024;
                                        return " (" + size.toFixed(2) + "MB)";
                                    })))
                            .append($('<div>')
                                .attr("class", "progressbar")
                                .append($('<div>')))
                            .append($('<button>')
                                .attr("type", "button")
                                .attr("class", "close removeFile")
                                .html("&times;"))
                        )

                        $(".file-title").trunk8(trunk8_settings_file);


                        let progressDiv = $("#file" + file_id).find(".progressbar").children();
                        progressDiv.css("width", "20%");

                        data.append("files", files[i], name);
                        fileName.push(name);
                        preUploadFile(progressDiv);
                        file_num++;
                        file_id++;
                    }
                }

            }
            $('#file-input').val('');
        }
    }

    let newArgId;
    let newParent = null;
    function submitArg() {
        let content = $("#divTextarea").val().trim();

        //check if argument is empty
        if(content === "") {
            return
        }

        //check if any image is still being uploaded - all .file-name2 must be .uploaded
        if($('.file-name2:not(.uploaded)').length > 0) {

            if ($('#alertUploading').data("active")) {
            }
            else {
                $('#alertUploading').show().data("active", true);
                setTimeout(function() {
                    $('#alertUploading').hide().data("active", false);
                }, 3000);
            }

            return;
        }

        let temp = JSON.stringify({
            "id": 0,
            "posVotes": 1,
            "negVotes": 0,
            "userId": currentUser.userId,
            "weight": 0,
            "arg": "",
            "text": content,
            "upload": fileName,
            "icon": ""
        });

        let url = baseUrl + "/addNodeWithRelation/" + graphId + "/" + relationType + "/" + clickedNodeId + "/" + currentUser.token;
        $.ajax({
            'async': true,
            'type' : "POST",
            'global': false,
            "headers": {
                "authorization": "Bearer " + currentUser.token },
            'url': url,
            'dataType': "json",
            'data': temp,
            'contentType': "application/json",
            'success': function (data) {
                $("#submitArgModal").find(".close").click()
                cancelAddArgument()


                let node = getNode(clickedNodeId)
                if(!node.data.expanded && node._children) {
                    node.children = node._children;
                    node._children = null;
                    node.data.expanded = true;
                    $("#"+node.data.id + ".foreignObject").find("#nodePlusImg").addClass("rotateOut")
                    $("#"+node.data.id + ".foreignObject").find("#nodeMinusImg").addClass("rotateIn")
                }
                newParent = node;


                let newArg = data
                console.log("new arg")
                console.log(newArg)
                newArg = Object.assign({}, {
                    ...newArg,
                    typeOfVote: "positive",
                    filtered: false,
                    fixed: false,
                    width: 250,
                    height: 170,
                    positiveVotes: 1,
                    negativeVotes: 0
                })
                console.log(newArg)
                newArgId = newArg.id;

                likePos(newArg, node); //likepos reloads graph once it's done

               // reloadGraph()
            }
        });
    }

    function nodeExpandClick(e) {
        let node = getNode(e.currentTarget.getAttribute("name"))
        click(e, node)
    }

    function settingsBoxClick(e) {
        e.stopPropagation();
        e.stopImmediatePropagation()
        if($('#sliderFilterBox').hasClass("open")) {
            $('#sliderFilterBox').removeClass("open")
        }
        if($('#settingsBox').is(':visible')) {
            $('#settingsBox').css("display", "none");
        }
        else {
            $('#settingsBox').css("display", "flex");
            $('.styles_box').css("display", "none");
        }
    }

    function filtersClick(e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        if($('#settingsBox').is(':visible')) {
            if($('#styles_attacks').is(':visible') || $('#styles_supports').is(':visible')) {
                $('.styles_box').css("display", "none")
            }
            else {
                $('#settingsBox').css("display", "none");
            }
        }
        $('#sliderFilterBox').toggleClass('open')
    }

    /* EVENT HANDLERS */
    $('#addButton').on("click", addArgumentClick)
    $('#focusButton').on("click", function () {
        zoomFitFull(0.70, 500)
    });
    $('body').on("change", "#file-input", fileInputChange)
    $('body').on("click", "#submitArg", submitArg)
    $("body").on("click", ".file-name2.uploaded", getFile);
    $('body').on("click", ".cancelIcon", cancelAddArgument)
    $("#root").on("click", "#argument_options li", chooseLinkType);
    $("svg").on("click", svgClick);
    $("#svgId").on("click", ".trunkateFill", showMoreClick);
    $('#saveButton').on("click", function() {
        saveUserSettings(currentUser, dispatch)
        attack_color = $('#colorAttacks').val();
        support_color =  $('#colorSupports').val();
        let attack_head = $('#attacksHeadSelect').children("option:selected").val()
        let support_head = $('#supportsHeadSelect').children("option:selected").val();
        let semantic = $('#semanticSelect').children("option:selected").val();
        currentUser.attack_color = attack_color;
        currentUser.support_color = support_color;
        currentUser.attack_head = attack_head;
        currentUser.support_head = support_head;
        currentUser.semantic = semantic;

    });
    $('#collapseButton').on("click", collapseAll);
    $('#sidebarCollapse').on('click', sidebarCollapseClick);
    $('#settingsBox').on("click", function (e) {
        e.stopPropagation(); //avoid settings box to close when you interact with it

        $('.styles_box').css("display", "none");
    })
    $('#settingsButton').on("click", settingsBoxClick)
    $('.styles_box').on("click", function(e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
    })

    $('.colorPattern-picker2').on("click", function(e) {
        colorPattern = $(this).attr("name");
        var select = $('[name=colorPatterns]');
        select.val(colorPattern);

        $('#colorPattern-picker').css(getBackgroundGradient(colorPattern))
        $('#slider-background').css(getBackgroundGradient(colorPattern))

        nodes.forEach(function(d) {
            $('#'+d.data.id+'.foreignObject').find('.contentDiv')
                .css("background-color", calcColor(colorPattern, d.data))
                .css("border", getBorderColor(d.data, colorPattern))
        })

        $('#colorPatterns').css("display", "none");
    })

    $('#colorPattern-picker').on("click", function(e) {
        e.stopPropagation();
        if($('#colorPatterns').is(':visible')) {
            $('#colorPatterns').css("display", "none");
        }
        else {
            $('#colorPatterns').css("display", "flex");
            $('#styles_supports').css("display", "none");
            $('#styles_attacks').css("display", "none");
        }
    })

    $('#styles_attacks_button').on("click", function (e) {
        e.stopPropagation()
        if($('#styles_attacks').is(':visible')) {
            $('#styles_attacks').css("display", "none");
        }
        else {
            $('#styles_attacks').css("display", "flex");
            $('#styles_supports').css("display", "none");
        }
    })
    $('#styles_supports_button').on("click", function (e) {
        e.stopPropagation()
        if($('#styles_supports').is(':visible')) {
            $('#styles_supports').css("display", "none");
        }
        else {
            $('#styles_supports').css("display", "flex");
            $('#styles_attacks').css("display", "none");
        }
    })
    $('.advanced_settings_click').on("click", function () {
        if($('.advanced_settings_click i').hasClass('rotate')) {
            $('.advanced_hidden').removeClass("showing")
            $('.advanced_settings_click i').removeClass("rotate");
        }
        else {
            $('.advanced_hidden').addClass("showing")
            $('.advanced_settings_click i').addClass("rotate");
        }
    })
    $('#color-picker-attacks').on('click', function (e) {
        $('#colorAttacks').click();
    })
    $('#color-picker-supports').on('click', function () {
        $('#colorSupports').click();
    })
    $('#colorAttacks').on('input change', function () {
        attack_color = $(this).val()
        $(this).prev().css('background-color', $(this).val());
        $('.path.links.attack').attr("stroke", $(this).val())
        $('#endAttack').find("path").attr("fill", $(this).val());
    })
    $('#colorSupports').on('input change', function () {
        support_color = $(this).val()
        $(this).prev().css('background-color', $(this).val());
        $('.path.links.support').attr("stroke", $(this).val())
        $('#endSupport').find("path").attr("fill", $(this).val());
    })
    $('.style_div.attack').on("click", function() {
        var head = $(this).attr("name");
        var select = $('[name=Attack]');
        select.val(head);

        changeMarkerEnds(svg, select, select.attr("name"))

        $('#styles_attacks_button').find('img').attr("src", getArrowImg(head))
        $('.style_div.attack.selected').removeClass('selected')
        $(this).addClass('selected')
    })
    $('.style_div.support').on("click", function () {
        var head = $(this).attr("name");
        var select = $('[name=Support]');
        select.val(head);

        changeMarkerEnds(svg, select, select.attr("name"))

        $('#styles_supports_button').find('img').attr("src", getArrowImg(head))
        $('.style_div.support.selected').removeClass('selected')
        $(this).addClass('selected')
    })
    $('#dropdownFilters').on("click", filtersClick)

    $("body").on("click", ".closeButtonUpload", closeImageModal)
    $("#svgId").on("click", ".fatPaths", linkClick);
    $("#svgId").on("mouseenter", ".fatPaths", linkMouseEnter);
    $("#svgId").on("mouseleave", ".fatPaths", linkMouseLeave);
    $("#svgId").on("click", ".likeFatAttack", clickLikeAttack);
    $("#svgId").on("click", ".dislikeFatAttack", clickDislikeAttack);
    $("#svgId").on("mouseenter", ".foreignObject", nodeMouseEnter);
    $("#svgId").on("mouseleave", ".foreignObject", nodeMouseLeave);
    $("#svgId").on("mouseenter", ".foreignObject.clicked", scrollOnNode);
    $("#svgId").on("mouseleave", ".foreignObject", scrollOnNode_end);
    $("#svgId").on("click", ".likeNode", clickLikeNode);
    $("#svgId").on("click", ".dislikeNode", clickDislikeNode);
    $("#svgId").on("click", ".nodeImageDiv", openFileViewModal);
    $("#svgId").on("click", ".nodeExpandDiv", nodeExpandClick);


    return {
        destroy: () => {
            $("#svgId").off("click", ".trunkateFill", showMoreClick);
            $('body').off("change", "#file-input", fileInputChange)
            $('body').off("click", "#submitArg", submitArg)
            $("body").off("click", ".file-name2.uploaded", getFile);
            $('body').off("click", ".cancelIcon", cancelAddArgument)
            $("#root").off("click", "#argument_options li", chooseLinkType);
            $("body").off("click", ".closeButtonUpload", closeImageModal)
            $("#svgId").off("click", ".fatPaths", linkClick);
            $("#svgId").off("mouseenter", ".fatPaths", linkMouseEnter);
            $("#svgId").off("mouseleave", ".fatPaths", linkMouseLeave);
            $("#svgId").off("click", ".likeFatAttack", clickLikeAttack);
            $("#svgId").off("click", ".dislikeFatAttack", clickDislikeAttack);
            $("#svgId").off("mouseenter", ".foreignObject", nodeMouseEnter);
            $("#svgId").off("mouseleave", ".foreignObject", nodeMouseLeave);
            $("#svgId").off("mouseenter", ".foreignObject.clicked", scrollOnNode);
            $("#svgId").off("mouseleave", ".foreignObject", scrollOnNode_end);
            $("#svgId").off("click", ".likeNode", clickLikeNode);
            $("#svgId").off("click", ".dislikeNode", clickDislikeNode);
            $("#svgId").off("click", ".nodeImageDiv", openFileViewModal);
            $("#svgId").off("click", ".nodeExpandDiv", nodeExpandClick);

            $('#sidebarCollapse').off('click', sidebarCollapseClick);
            clearInterval(reloadInterval)
        }
    }
}

const getLikeContent = (d) => {
    console.log("get like content")
    let iconPos;
    let iconNeg;
    if (d.typeOfVote === "positive") {
        iconPos = "fas fa-thumbs-up"
        iconNeg = "far fa-thumbs-down"
    } else if (d.typeOfVote === "negative") {
        iconPos = "far fa-thumbs-up"
        iconNeg = "fas fa-thumbs-down"
    } else {
        iconPos = "far fa-thumbs-up"
        iconNeg = "far fa-thumbs-down"
    }

    return "<button id=" + +d.id + " class=\"likeNode\" style=\"font-size:24px\"><i class='" + iconPos + "'></i></button>"
        + "<span id='posVotes" + d.id + "'>" + abbreviateNumber(d.positiveVotes) + "</span>" + " <button id=" + d.id + " class=\"dislikeNode\" style=\"font-size:24px\"><i class='" + iconNeg + "'></i></button>" +
        "<span id='negVotes" + d.id + "'>" + abbreviateNumber(d.negativeVotes) + "</span>"
}
