import {
    GOOGLE_OAUTH2_SUCCESS,
    GOOGLE_OAUTH2_FAIL,
    GOOGLE_OAUTH2_LOADED,
    GOOGLE_OAUTH2_RESET,
    CHANGE_USER_SETTINGS, CHANGE_USER_DEBATE_SETTINGS
} from "./types";
import axios from "axios";
import { baseUrl } from "../functions";

export const googleOAuth2Success = (googleResponse) => dispatch => {
        if (typeof googleResponse === 'undefined') {
            googleResponse = null;
            dispatch({
                type: GOOGLE_OAUTH2_FAIL,
                googleResponse: googleResponse
            });
        }
        else {
            const email = googleResponse.profileObj.email;
            const username = email.substring(0, email.indexOf('@'))
            const password = googleResponse.profileObj.googleId

            return axios({
                method: 'post',
                url: `${baseUrl}/authUser/`,
                data: {
                    user: username,
                    email: email,
                    password: password
                },
                headers: {
                    'Content-type': 'application/json'
                }
            })
                .then( (response) => {
                    if(response.data.exist) {
                        dispatch({
                            type: GOOGLE_OAUTH2_SUCCESS,
                            googleResponse: googleResponse,
                            swargObj: response.data
                        });
                    }
                    else {
                        dispatch({
                            type: GOOGLE_OAUTH2_FAIL,
                            googleResponse
                        });
                    }
                })
        }
};

export const googleAuthLoaded = () => dispatch => {
    dispatch({
        type: GOOGLE_OAUTH2_LOADED
    })
}

export const googleAuthReset = () => dispatch => {
    dispatch({
        type: GOOGLE_OAUTH2_RESET
    })
}

export const changeUserSettings = ( { swargProfile }) => dispatch => {
    dispatch({
        type: CHANGE_USER_SETTINGS,
        swargProfile: swargProfile
    })
}

export const changeUserDebateSettings = ( {color_pattern, attack_color, support_color, attack_head, support_head, semantic} ) => dispatch => {
    dispatch({
        type: CHANGE_USER_DEBATE_SETTINGS,
        color_pattern: color_pattern,
        attack_color: attack_color,
        support_color: support_color,
        attack_head: attack_head,
        support_head: support_head,
        semantic: semantic
    })
}