import React, {useEffect, useState} from "react"
import HomeSection from "./HomeSection";
import {
    fetchHomeMyDebates,
    fetchHomeNewestDebates,
    fetchHomePartInDebates,
    fetchHomeSubscribedDebates, fetchSubscribedDebates, resetHomeCount
} from "../actions/debateActions"
import { connect, useDispatch } from "react-redux";
import Body from "./Body";
import {isTokenValid} from "../functions";
import {googleOAuth2Success} from "../actions/googleActions";
import HomeSectionPlaceholder from "./HomeSectionPlaceholder";
import endScrollIcon from "../images/endScroll.png"
import relationsIcon from "../images/relations2.png"
import conversationIcon from "../images/conversation1.png"
import voteIcon from "../images/vote.png"
import dragIcon from "../images/drag.png"
import browseDotsIcon from "../images/browseDots.png"

const Home = (props) => {
    const dispatch = useDispatch();

    const fetchData = () => {
        if(props.swargProfile && props.swargProfile.token) {
            if(isTokenValid(props.swargProfile.date)) {
                dispatch(fetchHomeNewestDebates({token: props.swargProfile.token}))
                dispatch(fetchHomeSubscribedDebates({token: props.swargProfile.token}))
                dispatch(fetchHomePartInDebates({token: props.swargProfile.token}))
                dispatch(fetchHomeMyDebates({token: props.swargProfile.token}))
            }
            else {
                dispatch(googleOAuth2Success(props.googleResponse))
            }
        }
    }

    useEffect(() => {
        dispatch(resetHomeCount())
    },[props.swargProfile])

    useEffect(() => {
        if(props.swargProfile && props.swargProfile.token && props.loadedCount <= 0) {
            fetchData();
        }
    }, [props.swargProfile, props.loadedCount])

    const content =
            <>
                {props.loaded && (
                    <>
                        {props.swargProfile !== null ? (
                            <>
                                {props.loadedCount >= 4 ? (
                                        <>
                                            <HomeSection title='Newest Debates' link='./browse?sort=newest' debates={props.newest}/>
                                            <HomeSection title='Subscribed Debates' link='./subscribed' debates={props.subscribed}/>
                                            <HomeSection title='Participated in' link='./participated' debates={props.partIn}/>
                                            <HomeSection title='My debates' link='./my_debates' debates={props.myDebates}/>
                                        </>
                                    ) : (
                                        <>
                                            <HomeSectionPlaceholder/>
                                            <HomeSectionPlaceholder/>
                                            <HomeSectionPlaceholder/>
                                            <HomeSectionPlaceholder/>
                                        </>
                                    )}
                                </>
                        ) : (
                            <div id="contentLogout" className="d-flex flex-column w-100 text-center justify-content-center">
                                <div className="first-section d-flex flex-column align-items-center justify-content-center">
                                    <h1>It shouldn't be so hard to have an online discussion.</h1>
                                    <h2>Unlock the full potential of online debating with SWARG</h2>
                                </div>
                                <div className="second-section d-flex align-items-center flex-column">
                                    <h2>No more endless scrolling</h2>
                                    <div className="s2-container d-flex align-items-center">
                                        <div className="s2-img">
                                            <img src={endScrollIcon}/>
                                        </div>
                                        <div className="s2-info">
                                            <p>SWARG's innovative design allows you to jump into any debate and easily
                                                see what's going on.
                                            </p>
                                            <br/>
                                            <p>By moving away from the traditional solutions, SWARG allows you to
                                                stay on the subject and maintain a clear picture of the
                                                discussion.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="third-section d-flex align-items-center flex-column bg-white">
                                    <h2>Don't get lost in the debate</h2>
                                    <div className="s3-container d-flex align-items-center justify-content-between">
                                        <div className="s3-div d-flex flex-column align-items-center">
                                            <div className="s3-img d-flex justify-content-between">
                                                <span className="glyphicon glyphicon-minus"></span>
                                                <div id="colorBar">
                                                    <div id="color1bar">
                                                    </div>
                                                    <div id="color2bar">
                                                    </div>
                                                    <div id="color3bar">
                                                    </div>
                                                    <div id="color4bar">
                                                    </div>
                                                    <div id="color5bar">
                                                    </div>
                                                    <div id="color6bar">
                                                    </div>
                                                    <div id="color7bar">
                                                    </div>
                                                    <div id="color8bar">
                                                    </div>
                                                    <div id="color9bar">
                                                    </div>
                                                    <div id="color10bar">
                                                    </div>
                                                </div>
                                                <span className="glyphicon glyphicon-plus"></span>
                                            </div>
                                            <span className="special position-relative text-center">
                                                Quickly see what the best and worst arguments are
                                            </span>
                                        </div>
                                        <div className="s3-div d-flex flex-column align-items-center">
                                            <img src={relationsIcon}/>
                                                <p>Immediately find which arguments attack or support other
                                                    arguments
                                                </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="fourth-section d-flex align-items-center flex-column">
                                    <h2>Join the discussion</h2>
                                    <div className="s4-container d-flex align-items-center justify-content-between">
                                        <div className="s4-div d-flex flex-column align-items-center">
                                            <img src={conversationIcon}/>
                                                <p className="title">Add your own arguments and relations</p>
                                                <p>Add and connect arguments anywhere on the debate. No more worrying
                                                    about your ideas getting lost in the middle of new unrelated
                                                    arguments.
                                                </p>
                                        </div>
                                        <div className="s4-div d-flex flex-column align-items-center">
                                            <img src={voteIcon}/>
                                                <p className="title">Rate other arguments and relations</p>
                                                <p>Upvote arguments and relations that you agree with and downvote those
                                                    you think are wrong
                                                    or irrelevant.
                                                </p>
                                        </div>
                                        <div className="s4-div d-flex flex-column align-items-center">
                                            <img className="drag" src={dragIcon}/>
                                                <p className="title higher">Explore the debate</p>
                                                <p className="higher2">Swiftly make your away around the debate, without
                                                    having to switch pages or scroll up and down.
                                                </p>
                                        </div>
                                        <div className="s4-div d-flex flex-column align-items-center">
                                            <img className="browseDots" src={browseDotsIcon}/>
                                                <p className="title lower">Browse debates</p>
                                                <p className="lower">Find new debates on the browse page. Subscribe to
                                                    your favorite debates so
                                                    you don't miss out on any new arguments.
                                                </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </>

    return (
        <Body loaded={props.loadedCount === 4 || (props.loaded && props.swargProfile===null)} defaultHome={props.swargProfile===null} content={content}/>
    )
}

const mapStateToProps = state => ({
    newest: state.debates.newest,
    subscribed: state.debates.subscribed,
    partIn: state.debates.partIn,
    myDebates: state.debates.myDebates,
    loadedCount: state.debates.loadedCount,
    loaded: state.google.loaded,
    googleResponse: state.google.googleObj,
    swargProfile: state.google.swargProfile,
})

export default connect(mapStateToProps, null)(Home)
