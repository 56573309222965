import
{applyMiddleware, compose, createStore} from "redux";
import rootReducer from "./reducers"
import thunk from "redux-thunk";
import {isDev} from "./functions"

const initialState = {};

const middleware = [thunk];

const store = isDev ? createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )) : createStore(
            rootReducer,
            initialState,
            compose(
                applyMiddleware(...middleware))
)


export default store;

