import React, {useRef, useState} from "react"
import {Image, Overlay, OverlayTrigger, Popover} from "react-bootstrap";
import {useGoogleLogout} from "react-google-login";
import {googleOAuth2Success} from "../actions/googleActions";
import {useDispatch} from "react-redux";
import {isTokenValid} from "../functions";

const ProfilePic = ( {src, clientId} ) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false)
    const [target, setTarget] = useState(null)
    const ref = useRef(null)

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    const onLogoutSuccess = (response) => {
        console.log("success logout dispatching")
        dispatch(googleOAuth2Success(response))
    }

    const {signOut, loaded} = useGoogleLogout({
        onLogoutSuccess,
        clientId,
        accessType: 'online'
    })

    return (
            <OverlayTrigger
                trigger="click"
                delay={{ show: 75, hide: 100 }}
                placement="bottom"
                rootClose
                overlay={
                    <div className="popupBox rounded py-2 px-4 mt-2" onClick={signOut}>
                        <div>Sign out</div>
                    </div>
                }
            >
                <Image src={src} className="profilePic" onClick={handleClick} alt="fig" roundedCircle/>
            </OverlayTrigger>

    )
}

export default ProfilePic