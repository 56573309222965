import React, {useEffect} from "react"
import FilteredDebatesContent from "./FilteredDebatesContent";
import Body from "./Body";
import {fetchMyDebates, fetchParticipatedIn, resetItems, resetLoaded} from "../actions/debateActions"
import { connect, useDispatch } from "react-redux";
import {resetFilters} from "../actions/filterActions";
import {handleSignInClick, isTokenValid, PartInIcon} from "../functions";
import {googleOAuth2Success} from "../actions/googleActions";
import {faComments} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ParticipatedIn = (props) => {
    const dispatch = useDispatch();

   const fetchData = ( {skip, limit} ) => {
       if(props.swargProfile && props.swargProfile.token) {
           if(isTokenValid(props.swargProfile.date)) {
               dispatch(fetchParticipatedIn({filters: props.filters, token: props.swargProfile.token, limit: limit, skip: skip}))
           }
           else {
               dispatch(googleOAuth2Success(props.googleResponse))
           }
       }
   }

    useEffect(() => {
        dispatch(resetItems())
        dispatch(resetFilters())
    },[])

    const content =
        <>
            {props.loaded && (
                <>
                    {props.swargProfile !== null ? (
                        <FilteredDebatesContent debates={props.debates} fetchData={fetchData}/>
                    ) : (
                        <div className="w-100 d-flex align-items-center flex-column h-75 justify-content-center">
                            <PartInIcon width={"50px"} height={"50px"}/>
                            <h4 className="mt-4">Manage your activity</h4>
                            <span> <span className="signInClickable" onClick={handleSignInClick}>Sign in</span> to participate in debates</span>
                        </div>
                    )}
                </>
            )}
        </>

    return (
        <Body loaded={props.loadedDebates}  defaultHome={props.swargProfile===null} content={content}/>
    )
}

const mapStateToProps = state => ({
    debates: state.debates.items,
    filters: state.filters,
    loadedDebates: state.debates.loaded,
    loaded: state.google.loaded,
    googleResponse: state.google.googleObj,
    swargProfile: state.google.swargProfile
})

export default connect(mapStateToProps, null)(ParticipatedIn)