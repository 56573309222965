import React from "react"
import {XSquare} from "react-bootstrap-icons";

const Empty = () => {
    return (
        <div className="w-100 d-flex align-items-center flex-column h-100 mt-5 justify-content-center">
            <XSquare color="black" size={40}/>
            <h4 className="mt-4">No debates found!</h4>
        </div>
    )
}

export default Empty;