import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import * as filterActions from "../actions/filterActions";
import TagsInput from "./TagsInput";
import {changeTags, loadPopularTags} from "../actions/filterActions";
import Tags from "@yaireo/tagify/dist/react.tagify"
import {Form, FormControl, InputGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {FilterSquareFill} from "react-bootstrap-icons"

const FilterBox = (props) => {
    const tagifyRef = useRef();
    const dispatch = useDispatch();
    const [showFilters, setShowFilters] = useState(false);
    const [clickedAccessibility] = useState(false);
    const handleClickPopular = (e) => {
        tagifyRef.current && tagifyRef.current.addTags(e.detail.data.value)
    }

    const popularTagsSettings = {
        editTags: false,
        duplicates: true,
        callbacks: {
            "click": handleClickPopular
        }
    }

    const handleTypeChange = (e) => {
        dispatch(filterActions.changeType( {value: e.target.value} ))
    };

    const handleDebateTypeChange = (e) => {
        dispatch(filterActions.changeDebateType( {type: e.target.value} ))
    };

    const handleDateChange = (e) => {
        dispatch(filterActions.changeDate( {value: e.target.value} ))
    };

    const handleSortChange = (e) => {
        dispatch(filterActions.changeSort( {value: e.target.value} ))
    };

    const handleInputChange = (e) => {
        dispatch(filterActions.changeInput( {input: e.target.value} ))
    };

    const handleModeChange = (e) => {
        dispatch(filterActions.changeMode( {value: e.target.value} ))
    };

    const onTagsChange = (e) => {
        if(e.detail.value) {
            const tags = JSON.parse(e.detail.value)
            const list = tags.map((tag) => {
                return tag.value
            })
            dispatch(changeTags({tags: list}))
        }
        else {
            dispatch(changeTags({tags: []}))
        }
    }

    const clearAll = () => {
        tagifyRef.current && tagifyRef.current.removeAllTags()
    }

    useEffect( () => {
        if(!props.popularTags.loaded)
            dispatch(loadPopularTags())
    }, [dispatch])

    return (
        <>
            <div className="filterContainer p-3 rounded mt-4 d-none d-lg-block">
               <div className="filterByName">
                   <InputGroup className="w-100">
                       <FormControl placeholder="Search debates" value={props.input} onChange={handleInputChange}/>
                       <InputGroup.Append>
                           <Button variant="dark" style={ {backgroundColor: "#3e4b56"}}> <i className="fa fa-search"/></Button>
                       </InputGroup.Append>
                   </InputGroup>
               </div>
                <div className="typeField d-flex mt-4 pl-1 flex-column">
                    <div className="d-flex justify-content-between flex-column">
                        <span className="mb-1">Accessibility</span>
                        <select className="filterSelect w-75 border border-secondary rounded" onChange={handleTypeChange} value={props.type}>
                            <option value="0">All</option>
                            <option value="1">Private</option>
                            <option value="2">Open</option>
                        </select>
                    </div>
                    <div className="filtersRow d-flex mt-3 justify-content-between flex-column">
                        <span className="mb-1">Type</span>
                        <select className="filterSelect w-75 border border-secondary rounded" onChange={handleDebateTypeChange} value={props.debateType}>
                            <option value="">All</option>
                            <option value="free">Free</option>
                            <option value="alternatives">Alternatives</option>
                            <option value="tree">Tree</option>
                        </select>
                    </div>
                    <div className="filtersRow mt-3 d-flex justify-content-between flex-column">
                        <span className="mb-1">Upload date</span>
                        <select className="filterSelect w-75 border border-secondary rounded" onChange={handleDateChange} value={props.date}>
                            <option value="0">All</option>
                            <option value="1">Last hour</option>
                            <option value="2">Today</option>
                            <option value="3">This week</option>
                            <option value="4">This month</option>
                            <option value="5">This year</option>
                        </select>
                    </div>
                    <div className="sortByRow mt-3 d-flex justify-content-between flex-column">
                        <span className="mb-1">Sort by</span>
                        <select className="filterSelect w-75 border border-secondary rounded" onChange={handleSortChange} value={props.sort}>
                            <option value="titleA">Title (A-Z)</option>
                            <option value="titleZ">Title (Z-A)</option>
                            <option value="newest">Date (newest first)</option>
                            <option value="oldest">Date (oldest first)</option>
                            <option value="sizeDesc">Size (descending)</option>
                            <option value="sizeAsc">Size (ascending)</option>
                        </select>
                    </div>
                </div>
                <div className="tagsField mt-4">
                    <TagsInput onChange={onTagsChange} tagifyRef={tagifyRef}/>
                </div>
                {props.tags.length > 0 && (
                    <div className="d-flex justify-content-between mt-2 pl-1">
                        <div className="d-flex">
                            <div className="form-check">
                                <input type="radio" className="form-check-input" name="tagsMode" id="tagsMode0" value="0"
                                       checked={props.mode === "0"} onChange={handleModeChange}/>
                                <label className="form-check-label" htmlFor="tagsMode0">Some</label>
                            </div>
                            <div className="form-check ml-3">
                                <input type="radio" className="form-check-input" name="tagsMode" id="tagsMode1" value="1"
                                       checked={props.mode === "1"} onChange={handleModeChange}/>
                                <label className="form-check-label" htmlFor="tagsMode1">All</label>
                            </div>
                        </div>
                        <button className="clearAllBtn border-0 bg-white text-right" onClick={clearAll}>
                            Clear All
                        </button>
                    </div>
                )}
                <p className="mt-2 mb-2 pl-1">Popular Tags</p>
                <Tags className="popularTags border-0" value={props.popularTags.popularTags} settings={popularTagsSettings}/>
            </div>
            <div className="d-lg-none d-block mb-3 mt-3 filtersButton" onClick={() => setShowFilters(!showFilters)}>
                <FilterSquareFill className="mr-2" size={30}/>
                FILTERS
            </div>
            {showFilters && (<div className="filterContainerSmall p-3 rounded d-lg-none d-flex flex-column">
                <div className="filterByName">
                    <InputGroup>
                        <FormControl placeholder="Search debates" value={props.input} onChange={handleInputChange}/>
                        <InputGroup.Append>
                            <Button variant="dark" style={ {backgroundColor: "#3e4b56"}}> <i className="fa fa-search"/></Button>
                        </InputGroup.Append>
                    </InputGroup>
                </div>
                <div className="d-flex mt-4" style={{"flex-wrap": "wrap", gap: "16px"}}>
                    <div className="filtersRow d-flex justify-content-between" style={{gap: "5px"}}>
                        <span>Type</span>
                        <select className="filterSelect border-0" onChange={handleTypeChange} value={props.type}>
                            <option value="0">All</option>
                            <option value="1">Private</option>
                            <option value="2">Open</option>
                        </select>
                    </div>
                    <div className="filtersRow d-flex justify-content-between" style={{gap: "5px"}}>
                        <span>Type</span>
                        <select className="filterSelect border-0" onChange={handleDebateTypeChange} value={props.debateType}>
                            <option value="free">Free</option>
                            <option value="alternatives">Alternatives</option>
                            <option value="tree">Tree</option>
                        </select>
                    </div>
                    <div className="filtersRow d-flex justify-content-between" style={{gap: "5px"}}>
                        <span>Upload date</span>
                        <select className="filterSelect border-0" onChange={handleDateChange} value={props.date}>
                            <option value="0">All</option>
                            <option value="1">Last hour</option>
                            <option value="2">Today</option>
                            <option value="3">This week</option>
                            <option value="4">This month</option>
                            <option value="5">This year</option>
                        </select>
                    </div>
                    <div className="sortByRow d-flex justify-content-between" style={{gap: "5px"}}>
                        <span>Sort by</span>
                        <select className="filterSelect border-0" onChange={handleSortChange} value={props.sort}>
                            <option value="titleA">Title (A-Z)</option>
                            <option value="titleZ">Title (Z-A)</option>
                            <option value="newest">Date (newest first)</option>
                            <option value="oldest">Date (oldest first)</option>
                            <option value="sizeDesc">Size (descending)</option>
                            <option value="sizeAsc">Size (ascending)</option>
                        </select>
                    </div>
                </div>
                <div className="tagsField mt-4">
                    <TagsInput onChange={onTagsChange} tagifyRef={tagifyRef}/>
                </div>
                {props.tags.length > 0 && (
                    <div className="d-flex justify-content-between mt-2 pl-1">
                            <div className="d-flex">
                                <div className="form-check">
                                    <input type="radio" className="form-check-input" name="tagsMode" id="tagsMode0" value="0"
                                           checked={props.mode === "0"} onChange={handleModeChange}/>
                                    <label className="form-check-label" htmlFor="tagsMode0">Some</label>
                            </div>
                            <div className="form-check ml-3">
                                <input type="radio" className="form-check-input" name="tagsMode" id="tagsMode1" value="1"
                                       checked={props.mode === "1"} onChange={handleModeChange}/>
                                <label className="form-check-label" htmlFor="tagsMode1">All</label>
                            </div>
                        </div>
                        <button className="clearAllBtn border-0 bg-white text-right" onClick={clearAll}>
                            Clear All
                        </button>
                    </div>
                )}
                <p className="mt-2 mb-2 pl-1">Popular Tags</p>
                <Tags className="popularTags border-0" value={props.popularTags.popularTags} settings={popularTagsSettings}/>
            </div>)}
        </>
    )
}

const mapStateToProps = state => ({
    limit: state.filters.limit,
    skip: state.filters.skip,
    type: state.filters.type,
    date: state.filters.date,
    sort: state.filters.sort,
    input: state.filters.input,
    mode: state.filters.mode,
    tags: state.filters.tags,
    debateType: state.filters.debateType,
    popularTags: state.tags
})


export default connect(mapStateToProps, {})(FilterBox)