import React, {useEffect, useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BigBlueButton from "./BigBlueButton";
import {connect, useDispatch} from "react-redux";
import {createAlternativeDebate, createDebate, createTreeDebate} from "../actions/debateActions";
import {hideSuccess} from "../actions/successActions";
import TagsInput from "./TagsInput";
import {changeTags} from "../actions/filterActions";
import {ExclamationCircle, PlusSquareFill, Check, ArrowLeftShort} from "react-bootstrap-icons";
import { CSSTransitionGroup } from 'react-transition-group'
import HoverTooltip from "./HoverTooltip";
import {Form, OverlayTrigger} from "react-bootstrap";
import {googleAuthReset} from "../actions/googleActions";
import {useHistory} from 'react-router-dom';
import diagramIconTree from '../images/diagram_tree.svg'
import diagramIconFree from '../images/diagram_free.svg'
import treeIconType from "../images/tree_icon_type.svg"
import treeIconTypeLightColor from "../images/treeIconType_lightBorder_color.svg"
import treeIconTypeLightGray from "../images/tree_icon_type_lightBorder_gray.svg"
import treeIconTypeLightGray2 from "../images/tree_icon_type_lightBorder_gray_2.svg"
import treeIconTypeBorderGray from "../images/tree_icon_type_border_gray.svg"
import treeIconTypeBorderColor from "../images/treeIconType_color.svg"
import freeIconLightColor from "../images/freeIcon_lightBorder_color.svg"
import freeIconLightGray from "../images/freeIcon_lightBorder_gray.svg"
import freeIconLightGray2 from "../images/freeIcon_lightBorder_gray_2.svg"
import freeIconBorderColor from "../images/freeIconColor.svg"
import freeIconBorderGray from "../images/freeIcon_border_gray.svg"
import alternativesIconLightGray from "../images/alternativesIcon_lightBorder_gray.svg"
import imgPlaceholder from "../images/imgPlaceholder.png";
import {
    baseUrl,
    DEBATE_COLOR_0,
    DEBATE_COLOR_1,
    DEBATE_COLOR_2,
    DEBATE_COLOR_3,
    DEBATE_COLOR_4,
    MAX_FILES
} from "../functions";
import $ from "jquery";
import ArgumentPreview from "./ArgumentPreview";
import classNames from 'classnames'
import debateThumb0 from "../images/defaultDebateThumbnail.png"
import debateThumb1 from "../images/argument.png"
import debateThumb2 from "../images/argue.png"
import debateThumb3 from "../images/qa.png"
import debateThumb4 from "../images/debate1.png"

const MAX_TITLE_LENGTH = 100;

const AddDebateModal = (props) => {
    let file_num = 0;
    let file_id = 0;
    let data;
    const trunk8_settings_file =  {
        lines: 1
    }

    function appendFilePreview(file_id, name, size, argIndex, isUploaded) {
        $('.append-div').append($('<div>')
            .attr("class", "file-preview")
            .attr("id", "file" + file_id)
            .attr("file_name", name)
            .attr("argIndex", argIndex)
            .append($('<div>')
                .attr("class", "file-name")
                .append($('<div>')
                    .attr("class", function() {
                        return isUploaded ? "file-name2 uploaded" : "file-name2"
                    })
                    .append($('<span>')
                        .attr("class", "file-title")
                        .html(name))
                )
                .append($('<span>')
                    .attr("class", "file-size")
                    .html(function () {
                        return " (" + size.toFixed(2) + "MB)";
                    })))
            .append($('<div>')
                .css("visibility", function () {
                    return isUploaded ? "hidden" : "initial"
                })
                .attr("class", "progressbar")
                .append($('<div>')))
            .append($('<button>')
                .attr("type", "button")
                .attr("class", function() {
                    return isUploaded ? "close removeFile finished" : "close removeFile"
                })
                .html("&times;"))
        )

        $(".file-title").trunk8(trunk8_settings_file);
    }

    function fileInputChange(e) {
        console.log("file input change")
        const files = e.currentTarget.files;
        data = new FormData()
        if (files.length) {
            for (let i = 0; i < files.length; i++) {
                if(files[i].size/1024/1024 > 10) {   // 10MB
                    alert("Your file must not exceed 10MB");
                }
                else if(file_num === MAX_FILES) {
                    alert("You can only upload " + MAX_FILES + " files per argument")
                    return;
                }
                else {
                    const name = files[i].name;
                    if(fileName.current.includes(name)) {
                        alert("Duplicate file!")
                    }
                    else {
                        let size = files[i].size / 1024 / 1024;
                        appendFilePreview(file_id, name, size, argIndex.current, false)

                        let progressDiv = $("#file" + file_id).find(".progressbar").children();
                        progressDiv.css("width", "20%");

                        data.append("files", files[i], name);

                        fileName.current.push(name);
                        fileSizes.current.push(size)

                        preUploadFile(progressDiv, argIndex.current);
                        file_num++;
                        file_id++;
                    }
                }

            }
            $('#file-input-create').val('');
        }
    }


    function preUploadFile(progressDiv, argIndex) {
        progressDiv.css("width", "75%");
        let xhr = new XMLHttpRequest();

        xhr.open("post", baseUrl + "/uploadFiles/" + 0 + "/" + props.swargProfile.token + "/" + argIndex, true);

        xhr.onload = function () {
            progressDiv.css("width", "100%");
            progressDiv.parent().next().off("click")
            progressDiv.parent().next().addClass("finished")
            setTimeout(function () {
                progressDiv.parent().css("visibility", "hidden");
                progressDiv.parent().parent().find(".file-name2").addClass("uploaded");
            }, 1500);
        };

        progressDiv.parent().next().on("click", function () {
            xhr.abort();
            progressDiv.parent().parent().remove();
            file_num--;
        })

        xhr.send(data);
    }

    function removeFile(argIndex, file) {
        console.log("removing temp file")

        let file_name = file.attr("file_name");
        let addedArgsCopy = [...addedArguments];
        const index = fileName.current.indexOf(file_name);
        if (index > -1) {
            fileName.current.splice(index, 1);
            fileSizes.current.splice(index, 1);
        }

        $.ajax({
            'type' : "DELETE",
            'async': true,
            'global': true,
            'url': baseUrl + "/deleteFile/" + 0 + "/" + props.swargProfile.token + "/" + file_name + "/" + argIndex + "/false",
            'dataType': "json",
            'success' : function(data) {
                file.remove();
                file_num--;
            },
            'error' :function(xhr, status, error) {
                let errorMessage = xhr.status + ': ' + xhr.statusText;
                alert('Error - ' + errorMessage);
            }
        })
    }


    function removeArgumentFiles(index) {
        $.ajax({
            'type' : "DELETE",
            'async': true,
            'global': true,
            'url': baseUrl + "/deleteFile/" + 0 + "/" + props.swargProfile.token + "/name/" + index + "/true",
            'dataType': "json",
            'success' : function(data) {

            },
            'error' :function(xhr, status, error) {
                let errorMessage = xhr.status + ': ' + xhr.statusText;
                alert('Error - ' + errorMessage);
            }
        })
    }

    function deleteTempFiles() {
        $.ajax({
            'async': true,
            'type': "POST",
            'global': false,
            'url': baseUrl + "/deleteTempFiles2/" + props.swargProfile.token,
            'contentType': "application/json",
            'success': function (data) {
                console.log(data);
            }
        });
    }

    function getFile() {
        const file_name = $(this).parent().parent().attr("file_name");
        let index = $(this).parent().parent().attr("argIndex")

        window.open(baseUrl + "/getTempFile/" + 0 + "/" + props.swargProfile.token + "/" + file_name + "/" + index);
    }

    function removeFileHandler() {
        let argIndex = $(this).parent().attr("argindex")
        removeFile(argIndex, $(this).parent())
    }


    const dispatch = useDispatch();
    const history = useHistory()


    useEffect( () => {
        if(props.success) {
            setShow(false)
            window.onbeforeunload = null;
            window.removeEventListener("unload", onUnloadRef.current)
            handleShowSuccess();
        }
    }, [props.success, props.id])

    /* FORM STATE */
    const [title, setTitle] = useState('')
    const [open, setOpen] = useState(true)
    const [tags, setTags] = useState([])
    const [type, setType] = useState('free')
    const [count, setCount] = useState(MAX_TITLE_LENGTH)
    const [errorTitle, setErrorTitle] = useState("")
    const [showErrorTitle, setShowErrorTitle] = useState(false)
    const [page, setPage] = useState(0)
    const [treeHovered, setTreeHovered] = useState(false)
    const [alternativeHovered, setAlternativeHovered] = useState(false)
    const [freeHovered, setFreeHovered] = useState(false)
    const [treeClicked, setTreeClicked] = useState(false)
    const [alternativeClicked, setAlternativeClicked] = useState(false)
    const [freeClicked, setFreeClicked] = useState(false)
    const [argumentText, setArgumentText] = useState("")
    const [showErrorEmpty, setShowErrorEmpty] = useState(false)
    const [showAddArgument, setShowAddArgument] = useState(false)
    const [addedArguments, setAddedArguments] = useState([])
    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(-1)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showConfirmBack, setShowConfirmBack] = useState(false)
    const [activeColor, setActiveColor] = useState(0)
    const [activeThumb, setActiveThumb] = useState(debateThumb0)
    const [activeThumbNumber, setActiveThumbNumber] = useState(0)
    const [showThumbSelect, setShowThumbSelect] = useState(false)

    const argIndex = useRef(0)
    const tempArgIndex = useRef(0);
    const fileName = useRef([])
    const fileSizes = useRef([])
    const tagifyRef = useRef()
    const onUnloadRef = useRef()

    const beforeUnload = () => {
        return "Are you sure you want to leave? The changes you made might not have been saved."
    }

    const onUnload = () => {
        navigator.sendBeacon(baseUrl + "/deleteTempFiles2/" + props.swargProfile.token);
    }

    const handleAddArgument = () => {
        if(argumentText == "") {
            setShowErrorEmpty(true)
            const timer = setTimeout(() => {
                setShowErrorEmpty(false)
            }, 3000);
            return () => clearTimeout(timer);
        }
        //check if any image is still being uploaded - all .file-name2 must be .uploaded
        else if($('.file-name2:not(.uploaded)').length > 0) {

            if ($('#alertUploading').data("active")) {
            }
            else {
                $('#alertUploading').show().data("active", true);
                setTimeout(function() {
                    $('#alertUploading').hide().data("active", false);
                }, 3000);
            }

            return;
        }
        else {
            let newArg = {
                ownerId: props.swargProfile.userId,
                text: argumentText,
                upload: [...fileName.current], //TODO: save files names
                fileSizes: [...fileSizes.current],
                index: argIndex.current
            }

            console.log("new arg")
            console.log(newArg)
            setAddedArguments([
                newArg,
                ...addedArguments
            ])
            setShowAddArgument(false)
            setArgumentText("")
            argIndex.current = argIndex.current + 1;
            fileName.current = [];
            fileSizes.current = []
            $('.append-div').empty();
        }
    }

    const handleCancelEdit = () => {
        setArgumentText("")
        setIsEditing(false)
        $('.append-div').empty();
    }

    const handleSaveChanges = () => {
        let added = [...addedArguments];
        let arg = {...added[editIndex]};
        arg.text = argumentText;
        arg.upload = fileName.current;
        arg.fileSizes = fileSizes.current;
        added[editIndex] = arg;

        setAddedArguments(added);
        setArgumentText("");
        setShowAddArgument(false);
        setIsEditing(false);
        setEditIndex(-1);
        argIndex.current = tempArgIndex.current;
        fileName.current = [];
        fileSizes.current = []
        $('.append-div').empty();
    }

    const deleteArgument = ( {index} ) => {
        let added = [...addedArguments];
        added.splice(index, 1);
        setAddedArguments(added)
        setIsEditing(false)
        setEditIndex(-1)
        argIndex.current = tempArgIndex.current;
        setArgumentText("")
        removeArgumentFiles(addedArguments.length -1 - index)

        $('.append-div').empty();
    }

    const editArgument = ( {index} ) => {
        setIsEditing(true);
        setEditIndex(index)
        tempArgIndex.current = argIndex.current;
        argIndex.current = addedArguments.length -1 - index;

        let arg = addedArguments[index]

        fileName.current = arg.upload;
        fileSizes.current = arg.fileSizes;

        setArgumentText(arg.text)
        setShowAddArgument(true)

        $('.append-div').empty();
        arg.upload.forEach(function(d, idx) {
            appendFilePreview(d.index, d, arg.fileSizes[idx], arg.index,true)
        })
    }

    const handleGoToDebate = () => {
        dispatch(hideSuccess())
        history.push('/debate/' + props.id);
    }

    const onInputTitleChange = (e) => {
        const title = e.target.value;
        setTitle(title);
        setCount(MAX_TITLE_LENGTH-title.length);
    }

    const onTagsChange = (e) => {
        console.log("tags tags")
        if(e.detail.value) {
            const tagsNew = JSON.parse(e.detail.value)
            console.log(tagsNew)
            const list = tagsNew.map((tag) => {
                return tag.value
            })
            setTags(list)
        }
        else {
            setTags([])
        }
    }

    useEffect( () => {
        if(page === 4) {
            tagifyRef.current.addTags(tags)
        }
    }, [page])

    const handleCreateTreeDebate = () => {
        dispatch(createTreeDebate({
            open: open,
            title: title,
            previewColor: activeColor,
            thumbnail: activeThumbNumber,
            tags: tags,
            ownerId: props.swargProfile.userId,
            text: addedArguments[0].text,
            upload: addedArguments[0].upload, //TODO: save files names
            userToken: props.swargProfile.token,
        }))
    }

    const handleCreateAlternativeDebate = () => {
        dispatch(createAlternativeDebate({
            open: open,
            title: title,
            previewColor: activeColor,
            thumbnail: activeThumbNumber,
            tags: tags,
            ownerId: props.swargProfile.userId,
            alternativeArguments: [...addedArguments].reverse(),
            userToken: props.swargProfile.token
        }))
    }

    const handleCreateFreeDebate = () => {
        dispatch(createDebate( {
            open: open,
            title: title,
            previewColor: activeColor,
            thumbnail: activeThumbNumber,
            tags: tags,
            ownerId: props.swargProfile.userId
        }))
    }

    const handleAddTitle = () => {
        if(title.length === 0) {
            console.log("NO TITLE")
            setErrorTitle("Please enter a title for your debate")
            setShowErrorTitle(true)
            const timer = setTimeout(() => {
                setShowErrorTitle(false)
            }, 3200);
            return () => clearTimeout(timer);
        }
        else if(count < 0) {
            setErrorTitle("This title is too long!")
            setShowErrorTitle(true)
            const timer = setTimeout(() => {
                setShowErrorTitle(false)
            }, 3200);
            return () => clearTimeout(timer);
        }
        else {
            setPage(page+1)
        }
    }

    /* MODALS STATE */
    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const handleClose = () => {
        setShowConfirm(true);
    }

    const handleCloseAll = () => {
        setShowConfirm(false)
        setShow(false)

        window.onbeforeunload = null;
        window.removeEventListener("unload", onUnloadRef.current)

        deleteTempFiles()
    }

    const handleBack = () => {
        if(page === 1 && (
            addedArguments.length > 0 ||
            argumentText != "" ||
            fileName.current.length > 0)
        ){
            setShowConfirmBack(true)
        }
        else if(page === 4 || page === 2) {
            if(freeClicked) {
                setPage((page) => page-2)
            }
            else {
                setPage((page) => page-1)
            }
        }
        else {
            setPage((page) => page-1)
        }
    }

    const handleGoBack = () => {
        setShowConfirmBack(false)
        setPage((page) => page-1);
        setAddedArguments([])
        argIndex.current = 0;
        tempArgIndex.current = 0;
        fileName.current = [];
        fileSizes.current = [];
        deleteTempFiles();
    }

    const handleShow = () => {
        setPage(0)
        setTitle('')
        setOpen(true);
        setTags([])
        setShow(true);
        setCount(MAX_TITLE_LENGTH);
        setShowErrorTitle(false);
        setTreeClicked(false);
        setAlternativeClicked(false);
        setArgumentText("");
        setIsEditing(false);
        setEditIndex(-1);
        setAddedArguments([])
        argIndex.current = 0;
        tempArgIndex.current = 0;
        fileName.current = [];
        fileSizes.current = [];
        deleteTempFiles()

        onUnloadRef.current = onUnload;
        window.onbeforeunload = beforeUnload
        window.addEventListener("unload", onUnloadRef.current)

        //$('body').off("change", "#file-input-create", fileInputChange)
        $("body").off("click", ".file-name2.uploaded");
        $("body").off("click", ".removeFile.finished");


        //$('body').on("change", "#file-input-create", fileInputChange)
        $("body").on("click", ".file-name2.uploaded", getFile);
        $("body").on("click", ".removeFile.finished", removeFileHandler);
    }

    const handleCloseSuccess = () => {
        dispatch(hideSuccess())
        setShowSuccess(false);
    }
    const handleShowSuccess = () => setShowSuccess(true);

    const handleClickThumb = (clickedThumb, thumbNumber) => {
        setActiveThumb(clickedThumb);
        setActiveThumbNumber(thumbNumber);
        setShowThumbSelect(false)
    }

    return (
        <>
            <Button className="d-none d-md-block" variant="primary" onClick={handleShow}>
                New Debate
            </Button>
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 75, hide: 100 }}
                overlay={
                    <HoverTooltip text="New debate"/>
                }
            >
                <PlusSquareFill size={35} className="d-md-none small_add_debate" onClick={handleShow}/>
            </OverlayTrigger>

            <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName={`addDebateModal ${page === 7 ? "" : "fixedHeight"} ${showConfirm || showConfirmBack ? "faded" : ""}`}
            >
                <Modal.Header className={"pb-0 border-0 mt-1"} closeButton>
                    {page === 0 && (<span className={"h3"}>Select the type of debate</span>)}
                    {page === 1 && treeClicked && (<span className={"h3"}>Add a root argument</span>)}
                    {page === 1 && alternativeClicked && (<span className={"h3"}>Add initial alternatives</span>)}
                </Modal.Header>
                {page == 0 && (
                    <Modal.Body className={"w-100 d-flex align-items-center pt-0"}>
                        <Form className="debate_form w-100 d-flex flex-column">
                            <div className={"d-flex justify-content-center"} style={{gap: "25px"}}>
                                <div>
                                    <p className={"mb-2 font-weight-bold"} style={{textAlign: "center"}}>Tree</p>
                                    <div className={`debateType_div py-2 pl-2 pr-3 tree d-flex justify-content-center ${treeHovered ? "hovered" : ""}
                                        ${treeClicked ? "clicked" : ""}`}
                                         onMouseEnter={ () => setTreeHovered(true)}
                                         onMouseLeave={ () => setTreeHovered(false)}
                                         onClick={ () => {
                                             setArgumentText("")
                                             setTreeClicked(true)
                                             setAlternativeClicked(false)
                                             setFreeClicked(false)
                                         }}>
                                        <img src={treeIconTypeLightGray2}/>
                                        <div className={"hiddenText d-flex flex-column justify-content-center align-items-center"} style={{gap: "15px"}}>
                                            <div className={"hiddenText_row d-flex align-items-center"} style={{gap: "5px"}}>
                                                <Check size={30}/>
                                                <p className={"m-0"}>Debate structured as navigable tree</p>
                                            </div>
                                            <div className={"hiddenText_row d-flex align-items-center"} style={{gap: "5px"}}>
                                                <Check size={30}/>
                                                <p className={"m-0"}>Root of tree is the main topic of the debate</p>
                                            </div>
                                            <div className={"hiddenText_row d-flex align-items-center"} style={{gap: "5px"}}>
                                                <Check size={30}/>
                                                <p className={"m-0"}>Arguments may only attack or support one argument</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className={"mb-2 font-weight-bold"} style={{textAlign: "center"}}>Alternatives</p>
                                    <div className={`debateType_div py-2 pl-2 pr-3 free d-flex align-items-center justify-content-center
                                        ${alternativeHovered ? "hovered" : ""} ${alternativeClicked ? "clicked" : ""}`}
                                         onMouseEnter={ () => setAlternativeHovered(true)}
                                         onMouseLeave={ () => setAlternativeHovered(false)}
                                         onClick={ () => {
                                             setArgumentText("")
                                             setAlternativeClicked(true)
                                             setTreeClicked(false)
                                             setFreeClicked(false)
                                         }}>
                                        <img src={alternativesIconLightGray}/>
                                        <div className={"hiddenText d-flex flex-column justify-content-center align-items-center"} style={{gap: "15px"}}>
                                            <div className={"hiddenText_row d-flex align-items-center"} style={{gap: "5px"}}>
                                                <Check size={20}/>
                                                <p className={"m-0"}>Debate structured as a navigable graph</p>
                                            </div>
                                            <div className={"hiddenText_row d-flex align-items-center"} style={{gap: "5px"}}>
                                                <Check size={20}/>
                                                <p className={"m-0"}>Initial alternative arguments define the goal of the debate</p>
                                            </div>
                                            <div className={"hiddenText_row d-flex align-items-center"} style={{gap: "5px"}}>
                                                <Check size={20}/>
                                                <p className={"m-0"}>Unrestricted creation of arguments, attacks and supports</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className={"mb-2 font-weight-bold"} style={{textAlign: "center"}}>Free</p>
                                    <div className={`debateType_div py-2 pl-2 pr-3 free d-flex align-items-center justify-content-center
                                        ${freeHovered ? "hovered" : ""} ${freeClicked ? "clicked" : ""}`}
                                         onMouseEnter={ () => setFreeHovered(true)}
                                         onMouseLeave={ () => setFreeHovered(false)}
                                         onClick={ () => {
                                             setArgumentText("")
                                             setFreeClicked(true)
                                             setTreeClicked(false)
                                             setAlternativeClicked(false)
                                         }}>
                                        <img src={freeIconLightGray2}/>
                                        <div className={"hiddenText d-flex flex-column justify-content-center align-items-center"} style={{gap: "15px"}}>
                                            <div className={"hiddenText_row d-flex align-items-center"} style={{gap: "5px"}}>
                                                <Check size={30}/>
                                                <p className={"m-0"}>Debate structured as a navigable graph</p>
                                            </div>
                                            <div className={"hiddenText_row d-flex align-items-center"} style={{gap: "5px"}}>
                                                <Check size={30}/>
                                                <p className={"m-0"}>Debate is initially empty</p>
                                            </div>
                                            <div className={"hiddenText_row d-flex align-items-center"} style={{gap: "5px"}}>
                                                <Check size={30}/>
                                                <p className={"m-0"}>Unrestricted creation of arguments, attacks and supports</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>

                )}
                {page == 1 && (
                    <Modal.Body className={"w-100 mt-1 d-flex align-items-center flex-column"} style={ showAddArgument ? {} : {overflowY: "auto"} }>
                        <div className={"d-flex flex-column w-75"} style={ showAddArgument ? {} : {overflowY: "auto", overflowX: "hidden"} }>
                            <div className="debate_form mb-3">
                                    <textarea
                                        id="divTextarea"
                                        name="Text1"
                                        rows="20"
                                        value={argumentText}
                                        onChange={ (e) => {setArgumentText(e.target.value)}}
                                        className={ showErrorEmpty ? 'error' : '' }
                                        style={{resize: "none"}}
                                        placeholder={"Enter argument"}
                                    />
                                <CSSTransitionGroup
                                    transitionName="input_error"
                                    transitionEnterTimeout={300}
                                    transitionLeaveTimeout={300}
                                >
                                    {showErrorEmpty &&
                                    (<div
                                        key="input_error"
                                        className="input_error_alert my-2"
                                    >
                                        <ExclamationCircle color="red" size={20}/>
                                        <span className="ml-2 mt-1">Empty argument!</span>
                                    </div>)}
                                </CSSTransitionGroup>
                                <div className="image-upload change pt-2">
                                    <div className={"d-flex justify-content-between align-items-center py-1 pr-1"}>
                                        {treeClicked && addedArguments.length >= 1 ? (
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{ show: 0, hide: 100 }}
                                                overlay={
                                                    <HoverTooltip text="Tree arguments may only have one root argument"/>
                                                }
                                            >
                                                <label htmlFor="file-input-create" style={ {opacity: "0.5", cursor: "default"} }>
                                                    <img src={imgPlaceholder}/>
                                                    <span className="ml-2">Upload Image/Video</span>
                                                </label>
                                            </OverlayTrigger>
                                        ):(
                                            <label htmlFor="file-input-create" style={ {cursor: "pointer"} }>
                                                <img src={imgPlaceholder}/>
                                                <span className="ml-2">Upload Image/Video</span>
                                            </label>
                                        )}
                                        {isEditing ? (
                                            <div className={"d-flex align-items-center"} style={ {gap: "8px"} }>
                                                {/*<p className={"m-0 cancel"} style={ {fontSize: "13px", cursor: "pointer"} } onClick={handleCancelEdit}>Cancel edit</p>*/}
                                                <Button variant="primary" size={"sm"} onClick={handleSaveChanges}>Save changes</Button>
                                            </div>
                                        ):(
                                            <>
                                                {treeClicked && addedArguments.length >= 1 ? (
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        delay={{ show: 0, hide: 100 }}
                                                        overlay={
                                                            <HoverTooltip text="Tree arguments may only have one root argument"/>
                                                        }
                                                    >
                                                        <div style={ {pointerEvents: "all"}}>
                                                            <Button variant="primary" className={"disabledButton"} size={"sm"} disabled={true}>Add argument</Button>
                                                        </div>
                                                    </OverlayTrigger>
                                                ):(
                                                    <Button variant="primary" size={"sm"} onClick={handleAddArgument}>Add argument</Button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <form encType="multipart/form-data" method="post" name="fileinfo">
                                        <input id="file-input-create" type='file' accept=".mp4,.jpeg,.jpg,.png,.pdf" onChange={fileInputChange} multiple disabled={treeClicked && addedArguments.length >= 1}/>
                                    </form>
                                    <div className={"append-div pr-1"} style={ {maxHeight: `${treeClicked ? "120px" : "70px"}`, overflowY: "auto"} }>

                                    </div>
                                    <div id="alertUploading" className="input_error_alert2 mt-2">
                                        <ExclamationCircle color="red" size={20}/>
                                        <span id="alertUploadingSpan" className="ml-1">Please wait for all files to finish uploading</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"border-top"} style={ {overflowY: "auto", minHeight: "100px"} }>
                                <div className={"d-flex px-1 py-2 flex-column"} style={ {gap: "5px", overflowY: "auto", flexShrink: "0"} }>
                                    {addedArguments.length > 0 ? (
                                        <>
                                            {addedArguments.map(function(d, idx) {
                                                return (<ArgumentPreview text={d.text} onClickEdit={editArgument} onClickDelete={deleteArgument} index={idx}/>)
                                            })}
                                        </>
                                    ):(
                                        <>
                                            {treeClicked ? (
                                                <p className={"light-bold"}>(No root argument added)</p>
                                            ):(
                                                <p className={"light-bold"}>(No alternatives added)</p>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                )}
                {page === 2 && (
                    <Modal.Body className={"w-50 d-flex align-items-center"}>
                        <Form className="debate_form w-100">
                            <div className="debate_form_control">
                                <div className="d-flex justify-content-between">
                                    <label className="mb-2">Enter the title for your debate</label>
                                    <p
                                        className="font-weight-bold mb-0 ml-2"
                                        style={count >= 0 ? {color: "#9f9f9f"} : {color: "#bd2200"}}
                                    >{count < 100 && count}</p>
                                </div>
                                <input
                                    type='text'
                                    placeholder="Title"
                                    value={title}
                                    onChange={onInputTitleChange}
                                    className={`form-control title_input ${showErrorTitle ? 'border-danger' : ''}`}
                                />
                                <CSSTransitionGroup
                                    transitionName="input_error"
                                    transitionEnterTimeout={300}
                                    transitionLeaveTimeout={300}
                                >
                                    {showErrorTitle &&
                                    (<div
                                        key="input_error"
                                        className="input_error_alert mt-2 font-weight-normal"
                                        style={{color: "#dc3545"}}
                                    >
                                        <ExclamationCircle color="red" size={20}/>
                                        <span className="ml-2">{errorTitle}</span>
                                    </div>)}
                                </CSSTransitionGroup>
                            </div>
                            <div className="debate_form_control_checkbox">
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Private debate"
                                />
                            </div>
                        </Form>
                    </Modal.Body>
                )}
                {page === 3 && (
                    <Modal.Body className={"w-50 d-flex align-items-center"}>
                        <Form className="debate_form w-100">
                            <div className="debate_form_control">
                                <label className="mb-3" style={{fontSize: "17px"}}>Choose your debate preview</label>
                                <div className="d-flex flex-column">
                                    <div className="d-flex mb-4 justify-content-between" style={{gap: "10px"}}>
                                        <span style={{fontWeight: 500, color: "#4e4e4e"}}>Color</span>
                                        <div className={ classNames({
                                            "color-picker-debate" : true,
                                            "selected": activeColor === 0
                                        })} style={{backgroundColor: DEBATE_COLOR_0}} onClick={ () => setActiveColor(0)}/>
                                        <div className={ classNames({
                                            "color-picker-debate" : true,
                                            "selected": activeColor === 1
                                        })} style={{backgroundColor: DEBATE_COLOR_1}} onClick={ () => setActiveColor(1)}/>
                                        <div className={ classNames({
                                            "color-picker-debate" : true,
                                            "selected": activeColor === 2
                                        })} style={{backgroundColor: DEBATE_COLOR_2}} onClick={ () => setActiveColor(2)}/>
                                        <div className={ classNames({
                                            "color-picker-debate" : true,
                                            "selected": activeColor === 3
                                        })} style={{backgroundColor: DEBATE_COLOR_3}} onClick={ () => setActiveColor(3)}/>
                                        <div className={ classNames({
                                            "color-picker-debate" : true,
                                            "selected": activeColor === 4
                                        })} style={{backgroundColor: DEBATE_COLOR_4}} onClick={ () => setActiveColor(4)}/>
                                    </div>
                                    <div className="d-flex mb-1 align-items-center" style={{gap: "15px"}}>
                                        <span style={{fontWeight: 500, color: "#4e4e4e"}}>Thumbnail</span>
                                        <div className={"thumb-picker-debate d-flex align-items-center"} style={ {gap: "7px"} } onClick={ () => setShowThumbSelect(!showThumbSelect)}>
                                            <img src={activeThumb} alt="thumbnail"/>
                                            <i className="fas fa-caret-down" style={ {fontSize: "25px"} }/>
                                        </div>
                                        {showThumbSelect && (<div className={"d-flex flex-column bg-white align-items-center p-2"} style={ {
                                            gap: "8px",
                                            position: "absolute",
                                            right: "156px",
                                            top: "256px",
                                            width: "75px",
                                            border: "solid 1px #b9b9b9",
                                            borderRadius: "8px"
                                        } }>
                                            <div className={"thumb-picker-debate2"} onClick={() => handleClickThumb(debateThumb0, 0)}>
                                                <img src={debateThumb0} alt="thumbnail0"/>
                                            </div>
                                            <div className={"thumb-picker-debate2"} onClick={() => handleClickThumb(debateThumb1, 1)}>
                                                <img src={debateThumb1} alt="thumbnail1"/>
                                            </div>
                                            <div className={"thumb-picker-debate2"} onClick={() => handleClickThumb(debateThumb2, 2)}>
                                                <img src={debateThumb2} alt="thumbnail2"/>
                                            </div>
                                            <div className={"thumb-picker-debate2"} onClick={() => handleClickThumb(debateThumb3, 3)}>
                                                <img src={debateThumb3} alt="thumbnail3"/>
                                            </div>
                                            <div className={"thumb-picker-debate2"} onClick={() => handleClickThumb(debateThumb4, 4)}>
                                                <img src={debateThumb4} alt="thumbnail4"/>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                )}
                {page === 4 && (
                    <Modal.Body className={"w-50 d-flex align-items-center"}>
                        <Form className="debate_form w-100">
                            <div className="debate_form_control">
                                <label>Add some tags to your debate</label>
                                <TagsInput tagifyRef={tagifyRef} onChange={onTagsChange}/>
                            </div>
                        </Form>
                    </Modal.Body>
                )}
                {page === 5 && (
                    <Modal.Body className={"w-50 d-flex align-items-center justify-content-center"}>
                        <span className={"success_debate_span"}>You're all set!</span>
                    </Modal.Body>
                )}
                <Modal.Footer className={"border-0"}>
                    {page > 0 && (
                        <Button className={"previousButton"} variant="secondary" onClick={handleBack}>Back</Button>
                    )}
                    {page === 1 && treeClicked && (
                        <>
                        {addedArguments.length === 0 ? (
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 0, hide: 100 }}
                                overlay={
                                    <HoverTooltip text="Tree debates must have a root argument"/>
                                }
                            >
                                <div style={ {display: "inline-block", position: "relative"} }>
                                    <Button className={"nextButton"} variant="primary" disabled={true}>Next</Button>
                                    <div style={ {position: "absolute", left: "0", right: "0", top: "0", bottom: "0"} }/>
                                </div>
                            </OverlayTrigger>
                            ):(
                                <Button className={"nextButton"} variant="primary" onClick={() => setPage((page) => page+1)}>Next</Button>
                            )}
                        </>
                    )}
                    {page === 1 && alternativeClicked && (
                        <>
                            {addedArguments.length < 2 ? (
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 0, hide: 100 }}
                                    overlay={
                                        <HoverTooltip text="Alternative debates must have at least two alternative arguments"/>
                                    }
                                >
                                    <div style={ {display: "inline-block", position: "relative"} }>
                                        <Button className={"nextButton"} variant="primary" disabled={true}>Next</Button>
                                        <div style={ {position: "absolute", left: "0", right: "0", top: "0", bottom: "0"} }/>
                                    </div>
                                </OverlayTrigger>
                            ):(
                                <Button className={"nextButton"} variant="primary" onClick={() => setPage((page) => page+1)}>Next</Button>
                            )}
                        </>
                    )}
                    {/*{page === 3 && freeClicked && (
                        <>
                            <Button className={"nextButtonWide"} variant="info" onClick={ () => setPage(page+1) }>No, create empty debate</Button>
                            <Button className={"nextButtonWide"} variant="primary" onClick={ () => setPage(page+1) }>Yes, add alternatives</Button>
                        </>
                    )}*/}
                    {page === 2 && (
                        <Button className={"nextButton"} variant="primary" onClick={handleAddTitle}>Next</Button>
                    )}
                    {page === 0 && (
                        <Button className={"nextButton"} variant="primary" onClick={ () => {
                            if(freeClicked) {
                                setPage((page) => 2)
                            }
                            else {
                                setPage((page) => page+1);
                            }
                            setArgumentText("")
                            setIsEditing(false)
                        }} disabled={!(treeClicked || alternativeClicked || freeClicked)}>Next</Button>
                    )}
                    {page === 3 && (
                        <Button className={"nextButton"} variant="primary" onClick={() => {setPage(page+1)}}>Next</Button>
                    )}
                    {page === 4 && (
                        <Button className={"nextButton"} variant="primary" onClick={() => {
                            if(treeClicked) {
                                handleCreateTreeDebate()
                            }
                            else if(alternativeClicked) {
                                handleCreateAlternativeDebate()
                            }
                            else {
                                handleCreateFreeDebate()
                            }
                        }}>Create debate</Button>
                    )}
                   {/* {page === 3 && (
                        <span className={"skip_text mr-2"} onClick={ () => {
                            setPage((page) => page + 1);
                            setTags([])
                        }}>Skip</span>
                    )}*/}
                </Modal.Footer>
    </Modal>
                </>
            <Modal
                show={showSuccess}
                onHide={handleCloseSuccess}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header className="border-0" closeButton/>
                <span className="success_debate_span mb-2">Debate created successfully!</span>
                <div className="d-flex justify-content-center mb-3">
                    <a onClick={handleGoToDebate} className="button0">
                        Go to Debate
                    </a>
                </div>
            </Modal>
            <Modal
                show={showConfirm}
                onHide={ () => setShowConfirm(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName={"confirmModal"}
            >
                <Modal.Header className="border-0" closeButton/>
                <span className={"confirm_span mb-3 p-2"}>Are you sure you want to cancel this debate? All changes will be lost.</span>
                <div className={"d-flex justify-content-center mb-3"} style={ {gap: "10px"} }>
                    <Button variant="primary" onClick={ () => setShowConfirm(false) }>Return to debate</Button>
                    <Button variant="danger" onClick={handleCloseAll}>Cancel debate</Button>
                </div>
            </Modal>
            <Modal
                show={showConfirmBack}
                onHide={ () => setShowConfirmBack(false)}
                backdrop="static"
                keyboard={false}
                dialogClassName={"confirmModal"}
            >
                <Modal.Header className="border-0" closeButton/>
                <span className={"confirm_span mb-3 p-2"}>Are you sure you want to go back? All changes will be lost.</span>
                <div className={"d-flex justify-content-center mb-3"} style={ {gap: "10px"} }>
                    <Button variant="primary" onClick={ () => setShowConfirmBack(false) }>Stay on this page</Button>
                    <Button variant="danger" onClick={handleGoBack}>Go back</Button>
                </div>
            </Modal>
        </>
    )
}
const mapStateToProps = state => ({
    success: state.success.success,
    id: state.success.id,
    swargProfile: state.google.swargProfile
})

export default connect(mapStateToProps, null)(AddDebateModal)