import {
    CHANGE_INPUT,
    CHANGE_TYPE,
    CHANGE_DATE,
    CHANGE_LIMIT,
    CHANGE_MODE,
    CHANGE_SKIP,
    CHANGE_SORT,
    CHANGE_TAGS, RESET_FILTERS, LOAD_POPULAR_TAGS, SET_ONLY_SORT, SET_ONLY_INPUT, INCREASE_SKIP, CHANGE_DEBATE_TYPE
} from "../actions/types";
import {DEBATE_LIMIT} from "../functions";


const initialState = {
    type: "0",
    date: "0",
    sort: "sizeDesc",
    input: "",
    mode: "0",
    tags: [],
    popularTags: [],
    debateType: "",
    reset: true
}

export default function filterReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_DATE:
            return {
                ...state,
                reset: false,
                date: action.date
            }
        case CHANGE_TYPE:
            return {
                ...state,
                reset: false,
                type: action.value
            }
        case CHANGE_DEBATE_TYPE:
            return {
                ...state,
                reset: false,
                debateType: action.value
            }
        case CHANGE_SORT:
            return {
                ...state,
                reset: false,
                sort: action.sort
            }
        case CHANGE_INPUT:
            return {
                ...state,
                reset: false,
                input: action.input
            }
        case CHANGE_MODE:
            return {
                ...state,
                reset: false,
                mode: action.mode
            }
        case CHANGE_TAGS:
            return {
                ...state,
                reset: false,
                tags: action.tags
            }
        case SET_ONLY_SORT:
            return {
                ...initialState,
                reset: true,
                sort: action.sort
            }
        case SET_ONLY_INPUT:
            return {
                ...initialState,
                reset: true,
                input: action.input
            }
        case RESET_FILTERS:
            return initialState
        default:
            return state;
    }
}