import React, {useEffect, useRef} from "react"
import FilteredDebatesContent from "./FilteredDebatesContent";
import Body from "./Body";
import {fetchAllDebates, fetchParticipatedIn, resetItems, resetLoaded} from "../actions/debateActions"
import { connect, useDispatch } from "react-redux";
import {resetFilters, setOnlyInput, setOnlySort} from "../actions/filterActions";
import {isTokenValid} from "../functions";
import {googleOAuth2Success} from "../actions/googleActions";
import {useLocation} from "react-router-dom"
import {XSquare} from "react-bootstrap-icons";
import {resetSkip} from "../actions/skipActions";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Browse = (props) => {
    const dispatch = useDispatch();
    let query = useQuery();
    let sort = query.get("sort");
    let input = query.get("input")

    const fetchData = ( {skip, limit} ) => {
        if(props.swargProfile && props.swargProfile.token) {
            if(isTokenValid(props.swargProfile.date)) {
                console.log("skip: " + skip)
                dispatch(fetchAllDebates({filters: props.filters, token: props.swargProfile.token, skip: skip, limit: limit}))
            }
            else {
                dispatch(googleOAuth2Success(props.googleResponse))
            }
        }
        else {
            dispatch(fetchAllDebates({filters: props.filters, token: undefined, skip: skip, limit: limit}))
        }
    }

    useEffect(() => {
        dispatch(resetItems())
        if(sort) {
            dispatch(setOnlySort({sort: sort}))
        }
        else if(input) {
            dispatch(setOnlyInput({input: input}))
        }
        else {
            dispatch(resetFilters())
        }
    },[])

    return (
        <Body loaded={!props.isResetDebates} content={<FilteredDebatesContent debates={props.debates} fetchData={fetchData}/>}/>
    )
}

const mapStateToProps = state => ({
    debates: state.debates.items,
    filters: state.filters,
    limit: state.skip.limit,
    skip: state.skip.skip,
    loaded: state.google.loaded,
    isResetDebates: state.debates.reset,
    googleResponse: state.google.googleObj,
    swargProfile: state.google.swargProfile
})


export default connect(mapStateToProps, null)(Browse)