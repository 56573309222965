import React, {useState} from "react"
import {PencilSquare, Trash, TrashFill} from "react-bootstrap-icons"
import {OverlayTrigger} from "react-bootstrap";
import HoverTooltip from "./HoverTooltip";
import Trunk8 from "react-trunk8";

const ArgumentPreview = ( {text, onClickEdit, onClickDelete, index} ) => {


    const [deleteHovered, setDeleteHovered] = useState(false)

    return (
        <div className={"argumentPrevDiv w-100 d-flex justify-content-between align-items-center py-1 px-2"}>
            <Trunk8 lines={1}>
                <span title={text} className={"argPrevText"}>{text}</span>
            </Trunk8>

            <div className={"d-flex"} style={ {gap: "10px"} }>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 0, hide: 100 }}
                    overlay={
                        <HoverTooltip text="Edit"/>
                    }
                >
                    <PencilSquare size={20} className={"onHoverOpacity"} onClick={ () => onClickEdit( {index: index} )}/>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 0, hide: 100 }}
                    overlay={
                        <HoverTooltip text="Delete"/>
                    }
                >
                    <div className={"d-flex"} onMouseOver={ () => setDeleteHovered(true) } onMouseLeave={ () => setDeleteHovered(false) }  onClick={ () => onClickDelete( {index: index} )}>
                        {deleteHovered ? (
                            <TrashFill size={20} className={"onHoverOpacity"}/>
                        ):(
                            <Trash size={20} className={"onHoverOpacity"}/>
                        )}
                    </div>
                </OverlayTrigger>
            </div>
        </div>
    )
}

export default ArgumentPreview;
