import {
    CHANGE_USER_DEBATE_SETTINGS,
    CHANGE_USER_SETTINGS,
    GOOGLE_OAUTH2_FAIL,
    GOOGLE_OAUTH2_LOADED,
    GOOGLE_OAUTH2_RESET,
    GOOGLE_OAUTH2_SUCCESS
} from "../actions/types";

const initialState = {
    loaded: false,
    googleObj: null,
    swargProfile: null
};

export const googleReducer = (state = initialState, action) => {
    switch (action.type) {
        case GOOGLE_OAUTH2_RESET: {
            console.log("RESETTING GOOGLE")
            return {
                loaded: false,
                googleObj: null,
                swargProfile: null
            }
        }
        case GOOGLE_OAUTH2_LOADED: {
            return {
                ...state,
                loaded: true
            }
        }
        case GOOGLE_OAUTH2_SUCCESS: {
            console.log("google success")
            return {
                loaded: true,
                googleObj: action.googleResponse,
                swargProfile: action.swargObj
            }
        }
        case GOOGLE_OAUTH2_FAIL: {
            console.log("google fail")
            return {
                loaded: true,
                googleObj: action.googleResponse,
                swargProfile: null
            };
        }
        case CHANGE_USER_SETTINGS: {
            return {
                ...state,
                swargProfile: action.swargProfile
            }
        }
        case CHANGE_USER_DEBATE_SETTINGS: {
            return {
                ...state,
                swargProfile: {
                    ...state.swargProfile,
                    color_pattern: action.color_pattern,
                    attack_color: action.attack_color,
                    support_color: action.support_color,
                    attack_head: action.attack_head,
                    support_head: action.support_head,
                    semantic: action.semantic
                }
            }
        }
        default:
            return state;
    }
};