import {
    CHANGE_LIMIT,
    CHANGE_SKIP,
    INCREASE_SKIP
} from "../actions/types";
import {DEBATE_LIMIT} from "../functions";

const initialState = {
    limit: DEBATE_LIMIT,
    skip: 0
}

export default function skipReducer(state = initialState, action) {
    switch(action.type) {
        case CHANGE_LIMIT:
            return {
                ...state,
                limit: action.limit
            }
        case CHANGE_SKIP:
            return {
                ...state,
                skip: action.skip
            }
        case INCREASE_SKIP:
            return {
                ...state,
                skip: state.skip + state.limit
            }
        default:
            return state;
    }
}