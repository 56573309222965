import React, {useRef, useState} from "react";
import Card from "react-bootstrap/Card";
import debateThumb0 from "../images/defaultDebateThumbnail.png"
import debateThumb1 from "../images/argument.png"
import debateThumb2 from "../images/argue.png"
import debateThumb3 from "../images/qa.png"
import debateThumb4 from "../images/debate1.png"
import lockIcon from "../images/lockIcon.png"
import userIcon from "../images/userIcon.png"
import unlockIcon from "../images/unlockIcon.png"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import calendarIcon from "../images/calendar.png";
import likeIcon from "../images/like.png";
import clockIcon from "../images/clock.png";
import relationsIcon from "../images/relations.png";
import {useDispatch, connect} from "react-redux";
import {changeActiveDebate, subscribeDebate, unsubscribeDebate} from "../actions/debateActions";
import {FormControl, InputGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {CSSTransitionGroup} from "react-transition-group";
import {ExclamationCircle, Search} from "react-bootstrap-icons";
import HoverTooltip from "./HoverTooltip";
import Moment from 'moment'
import {
    abbreviateNumber,
    DEBATE_COLOR_0,
    DEBATE_COLOR_1,
    DEBATE_COLOR_2,
    DEBATE_COLOR_3, DEBATE_COLOR_4,
    isTokenValid
} from "../functions";
import {googleAuthReset, googleOAuth2Success} from "../actions/googleActions";
import {useHistory} from 'react-router-dom';
import Dotdotdot from 'react-dotdotdot'
import Trunk8 from "react-trunk8"


const DebateCard = ( {debate, swargProfile, googleResponse} ) => {
    const dispatch = useDispatch();
    const history = useHistory()

    const subscribe_overlay = useRef();

    const [show, setShow] = useState(false);
    const [subscribeText, setSubscribeText] = useState(() => {return debate.isSubscribed ? 'Subscribed' : 'Subscribe'})
    const [key, setKey] = useState("");
    const [errorTitle, setErrorTitle] = useState("")
    const [showErrorTitle, setShowErrorTitle] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setKey("")
        setShow(true);
        setShowErrorTitle(false)
    }

    const handleSubscribe = () => {
        if(swargProfile === null) {
            subscribe_overlay.current.show();
        }
        else if(isTokenValid(swargProfile.date)) {
            if(debate.isSubscribed)
                dispatch(unsubscribeDebate({graphId: debate.graphId, token: swargProfile.token}))
            else {
                dispatch(subscribeDebate({graphId: debate.graphId, token: swargProfile.token}))
            }
        }
        else {
            dispatch(googleOAuth2Success(googleResponse)).then( () => {
                if(debate.isSubscribed)
                    dispatch(unsubscribeDebate({graphId: debate.graphId, token: swargProfile.token}))
                else {
                    dispatch(subscribeDebate({graphId: debate.graphId, token: swargProfile.token}))
                }
            })
        }
    }

    const mouseOverSubscribe = () => {
        if(debate.isSubscribed)
            setSubscribeText('Unsubscribe')
    }

    const mouseOutSubscribe = () => {
        setSubscribeText(() => {return debate.isSubscribed ? 'Subscribed' : 'Subscribe'})
    }

    const handleGoTo = () => {
        if(!debate.open && !debate.hasAccess) {
            if(key.length === 0) {
                setErrorTitle("Please enter the debate key")
                setShowErrorTitle(true)
                const timer = setTimeout(() => {
                    setShowErrorTitle(false)
                }, 3200);
                return () => clearTimeout(timer);
            }
            else if(key !== debate.password) {
                setErrorTitle("Wrong debate key!")
                setShowErrorTitle(true)
                const timer = setTimeout(() => {
                    setShowErrorTitle(false)
                }, 3200);
                return () => clearTimeout(timer);
            }
            else {
                dispatch(changeActiveDebate({debate: debate }))
                history.push('/debate/' + debate.graphId);
            }
        }
        else {
            dispatch(changeActiveDebate({debate: debate }))
            history.push('/debate/' + debate.graphId);
        }
    }

    const copyKey = () => {
        navigator.clipboard.writeText(debate.password).then(r => {return null})
    }

    const getThumb = (thumbnail) => {
        switch(thumbnail) {
            case 0: return debateThumb0;
            case 1: return debateThumb1;
            case 2: return debateThumb2;
            case 3: return debateThumb3;
            case 4: return debateThumb4;
            default: return debateThumb0;
        }
    }

    const getBackgroundColor = (previewColor) => {
        switch(previewColor) {
            case 0: return DEBATE_COLOR_0;
            case 1: return DEBATE_COLOR_1;
            case 2: return DEBATE_COLOR_2;
            case 3: return DEBATE_COLOR_3;
            case 4: return DEBATE_COLOR_4;
            default: return DEBATE_COLOR_0;
        }
    }
    return (
        <>
            <Card className="border-0 debate_card my-2 p-3 ml-2" onClick={handleShow}>
                <Card.Img variant="top" src={getThumb(debate.thumbnail)} alt="thumbnail" className="p-4 thumbnail"
                style={ {backgroundColor: getBackgroundColor(debate.previewColor)} }/>
                <div className="thumbnail_bottom d-flex flex-column h-100 mt-2">
                    <div style={ {height: "48px"} }>
                        <Trunk8 lines={2}>
                            <span title={debate.title} className="debate_title">{debate.title}</span>
                        </Trunk8>
                    </div>
                    <div className="d-flex flex-column debate_info mt-4">
                        <div className="d-flex flex-row justify-content-between">
                            <span>{abbreviateNumber(debate.size)} arguments - {
                                <>
                                    {debate.type === "tree" && "Tree"}
                                    {debate.type === "free" && debate.hasInitialArguments && "Alternatives"}
                                    {debate.type === "free" && !debate.hasInitialArguments && "Free"}
                                    {debate.type !== "tree" && debate.type !== "free" && "Free"}
                                </>
                            }</span>
                            { !debate.open && (<img src={debate.hasAccess ? unlockIcon : lockIcon}
                                                    title={debate.hasAccess ? "You have access to this private debate" :
                                                    "This debate needs a key to enter"} alt="locked"/>) }
                        </div>
                        <div className="d-flex">
                            <img src={userIcon} alt="user" className="mr-2"/>
                            <span>{debate.ownerId}</span>
                        </div>
                    </div>
                </div>
            </Card>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <div className="w-100">
                        <Modal.Title>{debate.title}</Modal.Title>
                        <div className="debate_modal_bottom_header d-flex w-100 justify-content-between align-items-center">
                            <div className="info_debate">
                                {abbreviateNumber(debate.size)} arguments - {
                                <>
                                    {debate.type === "tree" && "Tree"}
                                    {debate.type === "free" && debate.hasInitialArguments && "Alternatives"}
                                    {debate.type === "free" && !debate.hasInitialArguments && "Free"}
                                    {debate.type !== "tree" && debate.type !== "free" && "Free"}
                                </>
                            }
                                <div>
                                    <img src={userIcon} alt="user"/>
                                    <span>{debate.ownerId}</span>
                                </div>
                            </div>
                            {swargProfile === null ? (
                                <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 0, hide: 100 }}
                                overlay={
                                    <HoverTooltip text="You must sign in to subscribe"/>
                                }
                                >
                                    <div className="subscribeDiv">
                                        <div className={`subscribeDiv_text ${debate.isSubscribed ? 'subscribed' : 'unsubscribed'}`}
                                             onMouseEnter={mouseOverSubscribe} onMouseOut={mouseOutSubscribe}>
                                            <p>{subscribeText}</p>
                                        </div>
                                    </div>
                                </OverlayTrigger> ) : (
                                    <div className="subscribeDiv">
                                        <div className={`subscribeDiv_text ${debate.isSubscribed ? 'subscribed' : 'unsubscribed'}`}
                                             onClick={handleSubscribe} onMouseEnter={mouseOverSubscribe} onMouseOut={mouseOutSubscribe}>
                                            <p>{subscribeText}</p>
                                        </div>
                                    </div>
                            )}
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-bottom">
                    <div className="debate_detail_info">
                        <div className="info_column">
                            <div>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 0, hide: 100 }}
                                    overlay={
                                        <HoverTooltip text="Upload date"/>
                                    }
                                >
                                    <img src={calendarIcon} alt="calendar"/>
                                </OverlayTrigger>
                                <span>{Moment(debate.timeStamp).fromNow()}</span>
                            </div>
                            <div>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 0, hide: 100 }}
                                    overlay={
                                        <HoverTooltip text="Number of votes"/>
                                    }
                                >
                                    <img src={likeIcon} alt="votes"/>
                                </OverlayTrigger>
                                <span>{abbreviateNumber(debate.votes)}</span>
                            </div>
                        </div>
                        <div className="info_column">
                            <div>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 0, hide: 100 }}
                                    overlay={
                                        <HoverTooltip text="Last argument date"/>
                                    }
                                >
                                    <img src={clockIcon} alt="clock"/>
                                </OverlayTrigger>
                                <span>{debate.lastArgDate === "null" ? "No arguments yet" : Moment(debate.lastArgDate).fromNow()}</span>
                            </div>
                            <div>
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 0, hide: 100 }}
                                    overlay={
                                        <HoverTooltip text="Number of relations"/>
                                    }
                                >
                                    <img src={relationsIcon} alt="relations"/>
                                </OverlayTrigger>
                                <span>{abbreviateNumber(debate.relations)}</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {!debate.open && ( debate.hasAccess ? (
                    <Modal.Body>
                        <div className="d-flex flex-column">
                            <label style={{fontWeight: "500"}}>You already have access to this debate</label>
                            <InputGroup>
                                <FormControl
                                    value={debate.password}
                                    readOnly={true}
                                />
                                <InputGroup.Append>
                                    <OverlayTrigger
                                        trigger="focus"
                                        placement="right"
                                        delay={{ show: 0, hide: 100 }}
                                        overlay={
                                            <HoverTooltip text="Copied key!"/>
                                        }
                                    >
                                        <Button onClick={copyKey} variant="dark" style={ {backgroundColor: "#3e4b56"} }>Copy key</Button>
                                    </OverlayTrigger>
                                </InputGroup.Append>
                            </InputGroup>

                        </div>
                    </Modal.Body>
                ) : (
                    <Modal.Body>
                        <div className="d-flex flex-column">
                            <label style={{fontWeight: "500"}}>You need a key to enter this debate</label>
                            <input
                                type='text'
                                placeholder='Enter key'
                                value={key}
                                onChange={ (e) => {setKey(e.target.value)} }
                                className={`form-control title_input ${showErrorTitle ? 'border-danger' : ''}`}
                            />
                            <CSSTransitionGroup
                                transitionName="input_error"
                                transitionEnterTimeout={300}
                                transitionLeaveTimeout={300}
                            >
                                {showErrorTitle &&
                                (<div
                                    key="input_error"
                                    className="input_error_alert mt-2"
                                    style={{color: "#dc3545"}}
                                >
                                    <ExclamationCircle color="red" size={20}/>
                                    <span className="ml-2">{errorTitle}</span>
                                </div>)}
                            </CSSTransitionGroup>
                        </div>
                    </Modal.Body>
                ))}
                <Modal.Footer className="border-0">
                    <Button onClick={handleGoTo} variant="primary">Go to debate</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    googleResponse: state.google.googleObj,
    swargProfile: state.google.swargProfile
})

export default connect(mapStateToProps, null)(DebateCard)