import React from "react"
import {Tooltip} from "react-bootstrap";

const HoverTooltip = React.forwardRef((props, ref) => (
    <Tooltip id={props.id ? props.id : "hover-tooltip"} ref={ref} {...props}>
        {props.text}
    </Tooltip>
))

export default HoverTooltip;
